import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/modle.css';
import axios from 'axios';
import lib from '../../config/lib';
import Swal from 'sweetalert2';
import Loading from '../../common/Loading';

const EmplyeeChangepassView = ({ isOpen, closeModal, empid }) => {
    const [newpass, setNewpass] = useState('');
    const [confirm, setConfirm] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChangepass = async () => {
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('newpass', newpass);
        formData.append('confirm', confirm);
        formData.append('id', empid);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.empchangepass, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Employee Password Changed Successfully!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>

            <div className="modal-content ">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4> Change Password</h4>
                <hr />

                <div className="row">
                    <div className='col-md-12 mt-2'>
                        <div className='d-flex'><label>New Password</label></div>
                        <input type="text" id="input-name" placeholder="Enter password" className='mt-2' onChange={(e) => setNewpass(e.target.value)} />
                    </div>
                    <div className='col-md-12 mt-2'>
                        <div className='d-flex'><label>Confirm Password</label></div>
                        <input type="text" id="input-name" placeholder="Enter confurm password" className='mt-2' onChange={(e) => setConfirm(e.target.value)} />
                    </div>
                </div>

                <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleChangepass} >Change Password</button></div>
            </div>
        </div>
    );
};

export default EmplyeeChangepassView;
