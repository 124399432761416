import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import lib from '../../config/lib';
import Loading from '../../common/Loading';

const AdditionalDetailsView = ({ isOpen, closeModal, handlesendJobNumberView }) => {
    const [jobno, setJobno] = useState('');
    const [paymentmode, setPaymentmode] = useState('');
    const [incometype, setIncometype] = useState('');
    const [useassigned, setUseassigned] = useState('');
    const [priority, setPriority] = useState('');
    const [republishreason, setRepublishreason] = useState('');
    const [description, setDescription] = useState('');
    const [marketingimg, setMarketingimg] = useState('');
    const [delevertoperson, setDelevertoperson] = useState('');
    const [designerdescription, setDesignerdescription] = useState('');
    const [customermsg, setCustomerMsg] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleViewDetails();
        handleImportantDetails();
    }, []);

    const handleViewDetails = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handlesendJobNumberView);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.additionaldetails, formData);
            if (response.data.status === "success") {
                setJobno(response.data.jobno);
                setPaymentmode(response.data.paymentmode);
                setIncometype(response.data.incometype);
                setUseassigned(response.data.userassigned);
                setPriority(response.data.priority);
                setRepublishreason(response.data.republishreason);
                setDescription(response.data.description);
                setMarketingimg(response.data.marketingimg);
                setDelevertoperson(response.data.delevertoperson);
                setDesignerdescription(response.data.designerdescription);
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleImportantDetails = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handlesendJobNumberView);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.importantdetails, formData);
            if (response.data.status === "success") {
                setCustomerMsg(response.data.description);
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-contents">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>View job</h4>
                <hr />
                <div className='tableview'>
                    <Table striped="columns">
                        <thead>
                            <tr>
                                <td colspan="4" className='text-center invoce-tittle'>View Additional Detail</td>
                            </tr>
                        </thead>
                        <tbody>

                            <tr>
                                <td colSpan={2}>Job no.</td>
                                <td colSpan={2}>{jobno}</td>
                            </tr>

                            <tr>
                                <td colSpan={2}>Payment Mode</td>
                                <td colSpan={2}>{paymentmode}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Income Type</td>
                                <td colSpan={2}>{incometype}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>User Assigned</td>
                                <td colSpan={2}>{useassigned}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Priority</td>
                                <td colSpan={2}>{priority}</td>
                            </tr>

                            <tr>
                                <td colSpan={2}>Republish Reason</td>
                                <td colSpan={2} >{republishreason}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Admin Description</td>
                                <td colSpan={2} >{description}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Customer Message</td>
                                <td colSpan={2} >{customermsg}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Designer Description</td>
                                <td colSpan={2} >{designerdescription}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Delevery To Person Name</td>
                                <td colSpan={2} >{delevertoperson}</td>
                            </tr>

                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
};

export default AdditionalDetailsView;
