import { React, useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import clasess from './common/css/Content.module.css';
import Header from './common/header';
import Sidemenu from './common/sidemenu';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
// import PaginationSection from './PaginationSection';
import Button from 'react-bootstrap/Button';
import { RiDeleteBin4Fill } from "react-icons/ri"
// import { BiSolidEdit } from 'react-icons/bi'
import { Tab } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import ReactPaginate from "react-paginate";
import Addemployer from './components/employerModal/Addemployer'
import AddRole from './components/employerModal/AddRole'
import AddStep from './components/employerModal/AddStep'
import { FcCheckmark, FcCancel } from 'react-icons/fc'
import axios from 'axios';
import lib from './config/lib';
import Swal from 'sweetalert2';
import { BiSolidEdit } from 'react-icons/bi'
import Modal from './components/employerModal/ViewemploerMessage';
import { useDispatch } from 'react-redux';
import { add, remove } from './store/Cartslice';
import EmployeeChangepass from './components/employerModal/EmployeeChangepass'
import Loading from './common/Loading';

const RollDetails = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleRole();
    }, []);

    const handleRole = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.displayrole, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }


    // Calculate the total number of pages
    // const pageCount = Math.ceil(data.length / itemsPerPage);
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData([]);

    const handleFilter = async (e) => {
        var filter = e.target.value;
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', filter);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.sampleordertable, formData);
            setData(response.data);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
        }
    }


    const [type, setType] = useState('');

    const handleSearch = async () => {
        const token = localStorage.getItem('token');
        setLoading(true);
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'rolldetails');
        formData.append('search', type);


        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.searchonclick, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div>
            <div className='row'>
                <Loading loading={loading} />
                <div className="col-lg-3 d-flex">
                    <input type="text" class="form-control" placeholder="Type to search..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setType(e.target.value)} />
                    <Button variant="success" style={{ margin: '10px' }} onClick={handleSearch} >Search</Button>
                </div>
                <div className='col-lg-1 ms-auto'>
                    <AddRole />
                </div>
            </div>
            <OverlayScrollbarsComponent>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Role</th>
                        </tr>
                    </thead>
                    <tbody>

                        {currentPageData && currentPageData.map((data) => (
                            <tr>
                                <td>{data.rolename}</td>
                            </tr>
                        ))}

                    </tbody>
                </Table>
            </OverlayScrollbarsComponent>

            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
            />
        </div>
    )
}


const StepDetails = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleRole();
    }, []);

    const handleRole = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.displaystep, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }


    // Calculate the total number of pages
    // const pageCount = Math.ceil(data.length / itemsPerPage);
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData([]);

    const [type, setType] = useState('');

    const handleSearch = async () => {
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'stepdata');
        formData.append('search', type);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.searchonclick, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div>
            <div className='row'>
                <Loading loading={loading} />
                <div className="col-lg-3 d-flex">
                    <input type="text" class="form-control" placeholder="Type to search..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setType(e.target.value)} />
                    <Button variant="success" style={{ margin: '10px' }} onClick={handleSearch} >Search</Button>
                </div>
                <div className='col-lg-1 ms-auto me-2'>
                    <AddStep />
                </div>
            </div>
            <OverlayScrollbarsComponent>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Steps</th>
                            <th>Role</th>
                        </tr>
                    </thead>
                    <tbody>

                        {currentPageData && currentPageData.map((data) => (
                            <tr>
                                <td>{data.step}</td>
                                <td>{data.role}</td>
                            </tr>
                        ))}

                    </tbody>
                </Table>
            </OverlayScrollbarsComponent>

            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
            />
        </div>
    )
}


const Employersdetails = () => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 15;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleOrder();
    }, []);

    const handleOrder = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.employeruser, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    // Calculate the total number of pages
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData([]);

    const handleApprove = async (id) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', id);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.employerapprove, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Employer Approved Successfully!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleReject = async (id) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', id);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.employerreject, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Employer Rejected successfully!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleDelete = async (id) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', id);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.employerdeleted, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Employer Deleted successfully!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (id) => {
        dispatch(add(id));
        setIsModalOpen(true);
    };

    const closeModal = (id) => {
        dispatch(remove(id));
        setIsModalOpen(false);
    };

    const [type, setType] = useState('');

    const handleSearch = async () => {
        const token = localStorage.getItem('token');
        setLoading(true);
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'employers');
        formData.append('search', type);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.searchonclick, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div>
            <div className={clasess.container1}>
                <Header />
                <Sidemenu />
                <div className={clasess.container}>
                    <Loading loading={loading} />
                    <div className='row mt-3'>
                        <div className='col-lg-10 col-md-9 ms-2'>
                            <h3>Employers Details</h3>
                        </div>
                        <div className='col-lg-1 col-md-2'>
                            <Addemployer />
                        </div>
                    </div>

                    <hr />

                    <Tabs
                        defaultActiveKey="Employers details"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3"
                        style={{ fontSize: '20px' }}
                    >
                        <Tab eventKey="Employers details" title="Employers details">
                            <div className='row'>
                                <div className="col-lg-3 d-flex">
                                    <input type="text" class="form-control" placeholder="Type to search..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setType(e.target.value)} />
                                    <Button variant="success" style={{ margin: '10px' }} onClick={handleSearch} >Search</Button>
                                </div>
                                {/* <div className='col-lg-1 ms-auto'>
                                    <AddRole />
                                </div>
                                <div className='col-lg-1'>
                                    <AddStep />
                                </div> */}
                            </div>

                            <OverlayScrollbarsComponent>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Employ Id</th>
                                            <th>Name</th>
                                            <th>user</th>
                                            <th>Mobile number </th>
                                            <th>Role </th>
                                            <th>Aadhar number</th>
                                            <th>Pan number</th>
                                            {/* <th>Joining date</th>
                                            <th>Leaving date</th> */}
                                            <th>Address </th>
                                            <th>Status</th>
                                            <th>Action </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {currentPageData && currentPageData.map((data) => (
                                            <tr>
                                                <td>{data.id}</td>
                                                <td>{data.name}</td>
                                                <td>{data.email}</td>
                                                <td>{data.mobilenumber}</td>
                                                <td>{data.subrole}</td>
                                                <td>{data.aadharnumber}</td>
                                                <td>{data.pannumber}</td>
                                                <td>{data.address}</td>
                                                <td>{data.status}</td>
                                                <td className="d-flex">
                                                    <FcCheckmark size={20} onClick={() => handleApprove(data.id)} />
                                                    <span className='ms-2'> <FcCancel size={20} onClick={() => handleReject(data.id)} /></span>
                                                    <span className='ms-2'>
                                                        <BiSolidEdit size={20} variant="success" onClick={() => openModal(data.id)} />
                                                        <Modal isOpen={isModalOpen} closeModal={() => closeModal(data.id)} />
                                                    </span>
                                                    <span className='ms-2'> <RiDeleteBin4Fill size={20} onClick={() => handleDelete(data.id)} /></span>
                                                    <span className='ms-2' style={{ cursor: 'pointer' }}><EmployeeChangepass empid={data.id} /></span>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </Table>
                            </OverlayScrollbarsComponent>

                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                            />
                        </Tab>
                        <Tab eventKey="Custom Order" title="Step details">
                            <StepDetails />
                        </Tab>
                        <Tab eventKey="printmachine" title="Roll details">
                            <RollDetails />
                        </Tab>

                    </Tabs>


                    {/* <PaginationSection currentPage={currentPage} totalPages={totalPages} paginate={paginate} pageNumbers={pageNumbers}/> */}
                </div>
            </div>
        </div>
    )
}

export default Employersdetails;