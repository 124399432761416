import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Invoice from '../Invoice';


const BillingModalView = ({ isOpen, closeModal, jobno, jobform}) => {
    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Billing</h4>
                <hr />

                <div className='col-lg-12 mt-2'>
                    <Invoice jobno={jobno} jobform={jobform}/>
                </div>

            </div>

        </div>
    );
};

export default BillingModalView;
