import React, { useState, useEffect } from 'react';
import clasess from './css/LeftBar.module.css';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import lib from '../config/lib';
import Loading from './Loading'

const Sidemenu = () => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [consumable, setConsumable] = useState('');
	const [standerds, setStanderds] = useState('');
	const [stock, setStock] = useState('');
	const [paymenthistory, setPaymenthistory] = useState('');
	const [jobcard, setJobcard] = useState('');
	const [userdetails, setUserdetails] = useState('');
	const [adminuserdetails, setAdminuserdetails] = useState('');
	const [employersdetails, setEmployersdetails] = useState('');
	const [employerreport, setEmployerreport] = useState('');
	const [jobreport, setJobreport] = useState('');
	const [support, setSupport] = useState('');
	const [setting, setSetting] = useState('');

	useEffect(() => {
		handleFethdetails();
	}, []);

	const handleFethdetails = async () => {
		setLoading(true);
		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('apikey', token);

		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.accessfeth, formData);
			if (response.data.status === "success") {
				setConsumable(response.data.consumable);
				setStanderds(response.data.standerds);
				setStock(response.data.stock);
				setPaymenthistory(response.data.paymenthistory);
				setJobcard(response.data.jobcard);
				setUserdetails(response.data.userdetails);
				setAdminuserdetails(response.data.adminuserdetails);
				setEmployersdetails(response.data.employerreport);
				setEmployerreport(response.data.employerreport);
				setJobreport(response.data.jobreport);
				setSupport(response.data.support);
				setSetting(response.data.settings);
				setLoading(false);
			}
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			setLoading(false);
		}
	}

	return (
		<div className={clasess.left}>
			<div className={clasess.sidebar}>
				<Loading loading={loading} />
				<div className={clasess.secondary_nav}>
					<ul className={clasess.sidenav}>
						<li>
							<Link to="/dashboard">
								<span className={`${clasess.nav_icon} ${clasess.computer_imag}`}>

								</span>
								Dashboard
							</Link>

						</li>

						<li>
							{consumable === "true" ? (
								<Link to="/consumable">
									<span className={`${clasess.nav_icon} ${clasess.frames}`}>

									</span>
									Consumable
								</Link>
							) : (
								<></>
							)}
						</li>

						<li>
							{standerds === "true" ? (
								<Link to="/standerd">
									<span className={`${clasess.nav_icon} ${clasess.frames}`}>

									</span>
									Standerds
								</Link>
							) : (
								<></>
							)}
						</li>

						{/* <li>
							<Link to="/Jobs">
								<span className={`${clasess.nav_icon} ${clasess.frames}`}>

								</span>
								Job cards
							</Link>
						</li> */}

						<li>
							{stock === "true" ? (
								<Link to="/stock">
									<span className={`${clasess.nav_icon} ${clasess.blocks_images}`}>

									</span>
									Stock
								</Link>
							) : (
								<></>
							)}
						</li>

						<li>
							{paymenthistory === "true" ? (
								<Link to="/payment-history">
									<span className={`${clasess.nav_icon} ${clasess.blocks_images}`}>

									</span>
									Payment History
								</Link>
							) : (
								<></>
							)}
						</li>
						<li>
							{jobcard === "true" ? (
								<Link to="/myorder">
									<span className={`${clasess.nav_icon} ${clasess.blocks_images}`}>

									</span>
									Job card
								</Link>
							) : (
								<></>
							)}
						</li>
						{/* <li>
							<Link to="/OrderTracking">
								<span className={`${clasess.nav_icon} ${clasess.blocks_images}`}>

								</span>
								My Order
							</Link>
						</li> */}

						<li>
							{userdetails === "true" ? (
								<Link to="/userdetails">
									<span className={`${clasess.nav_icon} ${clasess.list_images}`}>

									</span>
									Users Details
								</Link>
							) : (
								<></>
							)}
						</li>

						<li>
							{adminuserdetails === "true" ? (
								<Link to="/adminuserdetails">
									<span className={`${clasess.nav_icon} ${clasess.list_images}`}>

									</span>
									Admin User Details
								</Link>
							) : (
								<></>
							)}
						</li>

						<li>
							{employersdetails === "true" ? (
								<Link to="/employersdetails">
									<span className={`${clasess.nav_icon} ${clasess.coverflow}`}>

									</span>
									Employee Details
								</Link>
							) : (
								<></>
							)}
						</li>

						<li>
							{employerreport === "true" ? (
								<Link to="/employeereport">
									<span className={`${clasess.nav_icon} ${clasess.coverflow}`}>

									</span>
									Employee report
								</Link>
							) : (
								<></>
							)}
						</li>

						<li>
							{jobreport === "true" ? (
								<Link to="/jobreport">
									<span className={`${clasess.nav_icon} ${clasess.coverflow}`}>

									</span>
									Job report
								</Link>
							) : (
								<></>
							)}
						</li>

						<li>
							{support === "true" ? (
								<Link to="/support">
									<span className={`${clasess.nav_icon} ${clasess.bulls_eye}`}>

									</span>
									Support
								</Link>
							) : (
								<></>
							)}
						</li>
						{/* <li>
							<Link to="">
								<span className={`${clasess.nav_icon} ${clasess.bulls_eye}`}>

								</span>
								Report Chart
							</Link>
						</li> */}

						<li>
							{setting === "true" ? (
								<Link to="/changepass">
									<span className={`${clasess.nav_icon} ${clasess.list_image}`}>

									</span>
									Setting
								</Link>
							) : (
								<></>
							)}
						</li>
						<li>
							{/* <Link to="/changepass">
								<span className={`${clasess.nav_icon} ${clasess.list_image}`}>

								</span>
								Logout
							</Link> */}
							<span className={`${clasess.nav_icon} ${clasess.list_image}`}>

							</span>
							<a onClick={() => {
								localStorage.removeItem('token');
								localStorage.removeItem('role');
								navigate('/login');
							}}>Logout</a>
						</li>
					</ul>
				</div>

			</div>
		</div>
	)
}

export default Sidemenu;