const lib = {
    apiURL: 'https://sharpgraphs.com/api',
    imgUrl: 'https://sharpgraphs.com/api/img',


    apis: {
        "login": "admin/auth/login",
        "consumable": "admin/consumable/consumbaleupload",
        "stcat": "admin/standerds/catgouary",
        "subcat": "admin/standerds/subcat",
        "subcatdisplay": "admin/standerds/subcatdisplay",
        "catdisplay": "admin/standerds/catdisplay",
        "addvender": "admin/stock/addvender",
        "standerdcardsave": "admin/standerds/standerds",
        "venderdisplay": "admin/stock/venderdetailsfetch",
        "addproduct": "admin/stock/addproducts",
        "parameter": "admin/stock/addperi",
        "addquality": "admin/stock/addquality",
        "productdetails": "admin/stock/productcatdetails",
        "parameterdisplay": "admin/stock/parameterdetails",
        "qualitydisplay": "admin/stock/quality",
        "addstock": "admin/stock/addstock",
        "changepassword": "admin/adminuser/adminpasswordchange",
        "adduserbyadmin": "admin/userdata/usercreate",
        "userdetails": "admin/userdata/userdetails",
        "userapprove": "admin/userdata/userapprove",
        "userreject": "admin/userdata/userreject",
        "userdelete": "admin/userdata/userdelete",
        "userpasswordchange": "admin/userdata/customerpasschange",
        "entermachinedata": "admin/userdata/entermachinedata",
        "entersheetdata": "admin/userdata/entersheetdata",
        "printmachinedata": "admin/userdata/printmachinedisplay",
        "diemachinedata": "admin/userdata/diemachinedisplay",
        "sheetmachinedata": "admin/userdata/sheetdatadisplay",
        "adminuser": "admin/adminuser/adminuserdisplay",
        "admincreate": "admin/adminuser/adminregistration",
        "adminapprove": "admin/adminuser/adminapprove",
        "adminreject": "admin/adminuser/adminreject",
        "admindelete": "admin/adminuser/deleteadminuser",
        "employeruser": "admin/employers/employerreeport",
        "createemployer": "admin/employers/createemployer",
        "employerapprove": "admin/employers/employerapprove",
        "employerreject": "admin/employers/employerreject",
        "employerdeleted": "admin/employers/employerdelete",
        "paymentdetails": "admin/paymenthistory/paymentdetails",
        "paymentapprove": "admin/paymenthistory/paymentapprove",
        "paymentreject": "admin/paymenthistory/paymentreject",
        "walletbalance": "admin/paymenthistory/walletbalance",
        "stockdisplay": "admin/stock/stockdatafetch",
        "support": "admin/support/ticketfetch",
        "ticketreply": "admin/support/ticketreply",
        "userdetailsidfeth": "admin/userdata/usereditdisplay",
        "edituserdetails": "admin/userdata/useredit",
        "editadmin": "admin/adminuser/editadminuser",
        "editdisplay": "admin/adminuser/editdisplay",
        "header": "admin/header/header",
        "addpayment": "admin/paymenthistory/paymentcrdr",
        "deletstock": "admin/stock/deletestock",
        "editemployer": "admin/employers/employeredit",
        "fetchemployerdata": "admin/employers/fetchemployerdata",
        "fetchemployerdata": "admin/employers/fethemployerdata",
        "deleteprintmachinedata": "admin/userdata/deleteprintdata",
        "deletediemachinedata": "admin/userdata/deletediemachinedata",
        "deletesheetdata": "admin/userdata/deletesheetdata",
        "addrole": "admin/employers/addrole",
        "fetchrole": "admin/employers/fethroledata",
        "employerheader": "employ/header",
        "sampleordertable": "admin/job/sampleorder",
        "keylineorder": "admin/job/keylineorder",
        "quationtable": "admin/job/quationtable",
        "consumableorder": "admin/job/consumableorder",
        "rulechangeorder": "admin/job/rulechange",
        "customorderstable": "admin/job/customorderstable",
        "addsteps": "admin/employers/addstep",
        "startorder": "admin/job/startjob",
        "designeridfeth": "admin/job/designeridfeth",
        "samplejobdisplay": "admin/jobdisplay/samplejobdisplay",
        "keylinejobdisplay": "admin/jobdisplay/keylinejobdisplay",
        "quationjobdisplay": "admin/jobdisplay/quationjobdisplay",
        "consumablejobdisplay": "admin/jobdisplay/consumablejobdisplay",
        "rulechangedisplay": "admin/jobdisplay/rulechangedisplay",
        "descriptionsubmit": "admin/job/descriptionsubmit",
        "sampleaddjob": "admin/jobadd/sample",
        "employdisplay": "employ/carddisplay/access",
        "employjobfeth": "employ/carddisplay/carddisplay",
        "employacceptjob": "employ/carddisplay/acceptjob",
        "employdisplay":"employ/carddisplay/access",
        "employjobfeth":"employ/carddisplay/carddisplay",
        "employacceptjob":"employ/carddisplay/acceptjob",
        "employerafteraccept":"employ/carddisplay/afteraccept",
        "employmarketdetails":"employ/accessfolder/market",
        "employjobcomplete":"employ/carddisplay/completejob",
        "republishjob":"employ/carddisplay/republish",
        "addjobsample":"admin/jobadd/sample",
        "addkeylinejob":"admin/jobadd/keyline",
        "addjoborder":"admin/jobadd/order",
        "addrulechangejob":"admin/jobadd/rulechange",
        "consumableaddjob":"admin/jobadd/consumable",
        "uploaddesigner":"employ/accessfolder/uploaddesigner",
        "employfethbyid":"employ/accessfolder/employerfeth",
        "assignejob":"employ/accessfolder/assignejob",
        "updatestock":"employ/accessfolder/updatestock",
        "additionaldetails":"employ/accessfolder/additionaldetails",
        "billing":"employ/accessfolder/billing",
        "designerimg":"admin/job/designerimg",
        "totalsample":"admin/dashbord/cards/totalsample",
        "totalorders":"admin/dashbord/cards/totalorders",
        "totalkeylines":"admin/dashbord/cards/totalkeylines",
        "totalconsumable":"admin/dashbord/cards/totalconsumables",
        "totalrulechange":"admin/dashbord/cards/totalrulechange",
        "totalquation":"admin/dashbord/cards/totalquation",
        "totaluser":"admin/dashbord/cards/totaluser",
        "totalemploy":"admin/dashbord/cards/totalemploy",
        "totalsupport":"admin/dashbord/cards/totalsupport",
        "alluserfeth":"admin/job/alluserfeth",
        "printmachinedata":"admin/job/printingmachinedata",
        "diemachinedata":"admin/job/diemachinedata",
        "displayrole":"admin/employers/displayrole",
        "displaystep":"admin/employers/displaystep",
        "supportupdate":"admin/support/updatesupport",
        "markitingaddjob":"admin/jobadd/markitingaddjob",
        "marketingdisplay":"admin/job/markitingdisplay",
        "employreport":"admin/employers/employreport",
        "employreporttable":"admin/employers/employreporttable",
        "standerdsreport":"admin/job/jobtracker/standerd",
        "quationreport":"admin/job/jobtracker/quation",
        "rulechangereport":"admin/job/jobtracker/rulechange",
        "orderreport":"admin/job/jobtracker/order",
        "keylinereport":"adminnm/job/jobtracker/keyline",
        "consumablereport":"admin/job/jobtracker/consumable",
        "standerdstabledisplay":"admin/jobdisplay/standerdsdisplay",
        "workdoneby":"admin/job/workby/workby",
        "standerddatadisplayfeth":"admin/job/standerddisplay",
        "holdjobcompleted":"employ/carddisplay/holdjob",
        "emergencycancle":"employ/carddisplay/emergancy",
        "searchonclick":"admin/search/search",
        "convertjob":"admin/convertorder/quationconvert",
        "userfethdata":"admin/userdata/userdetails",
        "caddress": "admin/userdata/caddress",
        "orderstatusdispach":"admin/search/orderstatusdispach",
        "converttoordertable":"admin/convertorder/keylinesampleconvert",
        "importantdetails":"admin/job/importantdetails",
        "deletevender":"admin/stock/deletevender",
        "deleteproduct":"admin/stock/deleteproduct",
        "deleteparimeter":"admin/stock/deleteperimeter",
        "deletequality":"admin/stock/deletequality",
        "displayvender":"admin/stock/displayvender",
        "displaydisplay":"admin/stock/displayproducts",
        "displayparameter":"admin/stock/displayperimeter",
        "displayquality":"admin/stock/displayquality",
        "additem":"admin/customerrates/additem",
        "addqualityrate":"admin/customerrates/addquality",
        "addumo":"admin/customerrates/addumo",
        "fethitem":"admin/customerrates/fethitem",
        "deleteitem":"admin/customerrates/deleteitem",
        "deletequality":"admin/customerrates/deletequality",
        "fethquality":"admin/customerrates/fethquality",
        "fethumo":"admin/customerrates/fethumo",
        "deleteumo":"admin/customerrates/deleteumo",
        "fethconsumbaletable":"user/comsumble/consumbale",
        "deleteconsumabledetails":"admin/consumable/deleteconsumabledetails",
        "standerdfethdisplay":"user/standerds/standerddisplay",
        "deletestanderdlist":"admin/standerds/deletestanderddisplay",
        "createrate":"admin/customerrate/setprise",
        "fethratedetails":"admin/customerrate/customerprisedisplay",
        "deleteRate":"admin/customerrate/deleterate",
        "editrate":"admin/customerrate/editrate",
        "updatestockrate":"employ/stock/insertupdatestock",
        "stockdisplayemploy":"employ/stock/stockdisplay",
        "paymentrecordsubmit":"admin/paymenthistory/paymentrecordsubmit",
        "empchangepass":"employ/changepass",
        "addvarient":"admin/stock/addvarient",
        "displayvarient":"admin/stock/displayvarient",
        "varientdelete":"admin/stock/varientdelete",
        "addstockpurchase":"admin/stock/addstockpurchase",
        "displaypurchase":"admin/stock/displaypurchase",
        "deletepurchase":"admin/stock/deletepurchase",
        "converttostock":"admin/stock/converttostock",
        "accessfeth":"admin/adminuser/accessfeth",
        "orderstatus":"admin/search/orderstatus",
        "imagefeth":"employ/imagefeth",
        "fethvendordata":"admin/stock/fethvendordata",
        "editvender":"admin/stock/editvender",
        "adminholdjob":"employ/carddisplay/adminholdjob",
        "deletejob":"admin/jobdisplay/deletejob",
        "keymachinedata":"user/userprofile/machinetype",
        "updatequantity": "employ/stock/updatequantity",
        "updaterate":"employ/stock/updaterate",
        "assignemarket":"employ/accessfolder/market",
    }
};

export default lib;
