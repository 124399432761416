import React, { useState } from "react";
import { RiDeleteBin4Fill } from "react-icons/ri"
import Loading from "../common/Loading";
import Swal from "sweetalert2";
import axios from 'axios';
import lib from "../config/lib";

const DeleteJob = ({handleJobnumber, handleJobfrom}) => {

    const [loading, setLoading] = useState('');

    const handleDelete = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handleJobnumber);
        formData.append('jobform', handleJobfrom);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.deletejob, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Job Deleted Successfully!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Job already in process',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return(
        <div>
            <Loading loading={loading} />
            <RiDeleteBin4Fill size={24} color='#525252' onClick={handleDelete} />
        </div>
    )
};

export default DeleteJob;