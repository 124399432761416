import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import lib from '../../config/lib';
import Swal from 'sweetalert2'
import axios from 'axios';
import Loading from '../../common/Loading';

const Addmodlemsg = ({ isOpen, closeModal }) => {
  const [cat, setCat] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCatSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("apikey", token);
    formData.append("cat", cat);

    try {
      const response = await axios.post(lib.apiURL + '/' + lib.apis.stcat, formData);
      if (response.data.status === "success") {
        Swal.fire('Success', 'Add Successfully!', 'success');
        setLoading(false);
      }
    } catch (error) {
      // Handle error if the request failed
      console.log("Some error has occoured kindly check the parameters and try again");
      Swal.fire({
        title: 'opps......',
        text: 'somthing Went Wrong',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Confirm',
      });
      setLoading(false);
    }
  }
  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <Loading loading={loading} />
        <span className="close" onClick={closeModal}>&times;</span>
        <h4>Add Category</h4>
        <hr />
        <Form>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label>Write Category Name</Form.Label>
            <Form.Control type="text" placeholder="Write Category" onChange={(e) => setCat(e.target.value)} />
            <Button onClick={handleCatSubmit}>Submit Category</Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

export default Addmodlemsg;
