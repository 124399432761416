import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assest/css/custom.css';
import './assest/css/gradient.css';
import Header from './common/header';
import Sidemenu from './common/sidemenu';
import clasess from './common/css/Content.module.css';
import lib from './config/lib';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import AddJobConsumable from './components/jobcardDisplay/AddJobConsumable'
import Loading from './common/Loading';

const Consumable = () => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState([]);
    const itemsPerPage = 10;
    const [data, setData] = useState([]);

    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {

        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData([]);

    useEffect(() => {
        handleSample();
    }, []);

    const handleSample = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.fethconsumbaletable, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div className={clasess.container1}>
            <Header />
            <Sidemenu />
            <div>
                <div className={clasess.container}>
                    <div style={{ width: '100%', height: 'auto', marginTop: '20px' }}>
                        <Loading loading={loading} />
                        <h3 style={{ marginLeft: '15px' }}>Consumable</h3>
                        <hr />
                        <Table striped bordered hover >
                            <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Product Prise</th>
                                    <th>Product Description</th>
                                    <th>View Image</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPageData && currentPageData.map((item) => (
                                    <tr>
                                        <td>{item.productname}</td>
                                        <td>{item.productprise}</td>
                                        <td>{item.productdescription}</td>
                                        <td><a href={lib.imgUrl + '/' + item.productImage} target="_blank" >View Image</a></td>
                                        <td><AddJobConsumable id={item.id} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Consumable;