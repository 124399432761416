import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2'
import axios from 'axios';
import lib from "./config/lib";
import Form from 'react-bootstrap/Form';
import Header from './common/header';
import Sidemenu from './common/sidemenu';
import clasess from './common/css/Content.module.css';
import { Link, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import Loading from "./common/Loading";

const AddJjobCustomOrderView = () => {

    // const location = useLocation();
    // const handleProptype = location.state && location.state.prop;
    const location = useLocation();

    const { handleProptype, handleOrderidsend } = location.state;
    const [loading, setLoading] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);
    const [cardimg, setCardImg] = useState({ preview: '', data: '' });
    const [machinedata, setMachinedata] = useState([]);
    const [sheetdata, setSheetdata] = useState([]);

    const [setmaincat, setMaincat] = useState('');
    const [quality, setQuality] = useState('');
    const [sheetthickness, setSheetthickness] = useState('');
    const [boxstyle, setBoxstyle] = useState('');
    const [woodquality, setWoodquality] = useState('');
    const [woodsize, setWoodSize] = useState('');
    const [punching, setPunching] = useState('');
    const [lenth, setLenth] = useState();
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [tuckflap, setTuckflap] = useState('');
    const [glueflap, setGlueflap] = useState('');
    const [lock, setLock] = useState('');
    const [dustflap, setDustflap] = useState('');
    const [numberofups, setNumberofups] = useState('');
    const [sheetsize, setSheetsize] = useState('');
    const [rouletoroule, setRouletoroule] = useState('');
    const [rubbing, setRubbing] = useState('');
    const [coro, setCoro] = useState('');
    const [matrix, setMatrix] = useState('');
    const [stripping, setStripping] = useState('');
    const [pertinax, setPertinax] = useState('N/A');
    const [embossing, setEmbossing] = useState('N/A');
    const [description, setDescription] = useState('N/A');
    const [fulde, setFulde] = useState('N/A');
    const [fuldetype, setFuldetype] = useState('N/A');
    const [thickness, setThickness] = useState('N/A');
    const [rulequality, setRulequality] = useState('N/A');
    const [diequility, setDiequility] = useState('N/A');
    const [punchingthickness, setPunchingthickness] = useState('N/A');
    const [machine, setMachine] = useState('N/A');
    const [machinesize, setMachinesize] = useState('N/A');
    const [material, setMaterial] = useState('N/A');
    const [blisterstyle, setBlisterstyle] = useState('N/A');
    const [cardsize, setCardsize] = useState('N/A');
    const [width, setWidth] = useState('N/A');
    const [bendingmargin, setBendingmargin] = useState('N/A');
    const [numberguide, setNumberguide] = useState('N/A');
    const [collersize, setCollersize] = useState('N/A');
    const [numberdies, setNumberdies] = useState('N/A');
    const [wood, setWood] = useState('N/A');
    const [materialtype, setMaterialtype] = useState('N/A');
    const [plactictype, setPlactictype] = useState('N/A');
    const [rule, setRule] = useState('N/A');
    const [ordertype, setOrdertype] = useState();
    const [customercode, setCustomercode] = useState('');

    // Rigid box hooks starts here
    const [cat, setCat] = useState('N/A');
    const [bottomHard, setBottomHard] = useState('N/A');
    const [bottomcap, setBottomCap] = useState('N/A');
    const [gsm, setGsm] = useState('N/A');
    const [bottomstyle, setBoStyle] = useState('N/A');
    const [upsHb, setUpsHb] = useState('N/A');
    const [upsCap, setUpsCap] = useState('N/A');
    const [fiRqr, setFiRqr] = useState('N/A');
    const [fihbd, setFihbd] = useState('N/A');
    const [fiCapRqr, setfiCapRqr] = useState('N/A');
    const [filecap, setFilecap] = useState('N/A');
    const [noupsCap, setNoupsCap] = useState('N/A');
    const [innerRqr, setInnerRqr] = useState('N/A');
    const [sheet, setSheet] = useState('N/A');
    const [noUps, setNoUps] = useState('N/A');
    const [trayRqr, setTrayRqr] = useState('N/A');
    const [trayStyle, setTrayStyle] = useState('N/A');
    const [trayWthik, setTrayWthik] = useState('N/A');
    const [machinetype, setMachinetype] = useState('N/A');
    const [sheetType, setSheetType] = useState('N/A');
    const [topRqr, setTopRqr] = useState('N/A');
    const [topHbdm, setTopHbdm] = useState('N/A');
    const [gellaCapH, setGelaCapH] = useState('N/A');

    const [sid, setSid] = useState('');
    const [status, setStatus] = useState('');
    const [date, setDate] = useState('');

    const [jobno, setJobno] = useState('');

    const [jobname, setJobname] = useState('');

    const [jobmode, setJobmode] = useState('');

    // Rigid box ends here

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setCardImg({ preview: URL.createObjectURL(file), data: file });
        if (file) {
            setSelectedFile(file);
        } else {
            setSelectedFile(null);
        }
    };

    const handleCustomeOrder = async (event) => {
        event.preventDefault();
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('setmaincat', setmaincat);
        formData.append('quality', quality);
        formData.append('sheetthickness', sheetthickness);
        formData.append('boxstyle', boxstyle);
        formData.append('woodquality', woodquality);
        formData.append('woodsize', woodsize);
        formData.append('punching', punching);
        formData.append('lenth', lenth);
        formData.append('weight', weight);
        formData.append('height', height);
        formData.append('tuckflap', tuckflap);
        formData.append('glueflap', glueflap);
        formData.append('lock', lock);
        formData.append('dustflap', dustflap);
        formData.append('numberofups', numberofups);
        formData.append('sheetsize', sheetsize);
        formData.append('rouletoroule', rouletoroule);
        formData.append('rubbing', rubbing);
        formData.append('coro', coro);
        formData.append('matrix', matrix);
        formData.append('stripping', stripping);
        formData.append('pertinax', pertinax);
        formData.append('embossing', embossing);
        formData.append('description', description);
        formData.append('fulde', fulde);
        formData.append('fuldetype', fuldetype);
        formData.append('thickness', thickness);
        formData.append('rulequality', rulequality);
        formData.append('diequility', diequility);
        formData.append('punchingthickness', punchingthickness);
        formData.append('machine', machine);
        formData.append('machinesize', machinesize);
        formData.append('material', material);
        formData.append('blisterstyle', blisterstyle);
        formData.append('cardsize', cardsize);
        formData.append('width', cardsize);
        formData.append('bendingmargin', bendingmargin);
        formData.append('numberguide', numberguide);
        formData.append('collersize', collersize);
        formData.append('numberdies', numberdies);
        formData.append('wood', wood);
        formData.append('materialtype', materialtype);
        formData.append('plactictype', plactictype);
        formData.append('rule', rule);
        formData.append('ordertype', handleProptype);
        formData.append('productimg', selectedFile);
        formData.append('sid', customercode);
        formData.append('cname', companyname);
        formData.append('cat', cat);
        formData.append('bottomHard', bottomHard);
        formData.append('bottomcap', bottomcap);
        formData.append('gsm', gsm);
        formData.append('bottomstyle', bottomstyle);
        formData.append('upsHb', upsHb);
        formData.append('upsCap', upsCap);
        formData.append('fiRqr', fiRqr);
        formData.append('fihbd', fihbd);
        formData.append('fiCapRqr', fiCapRqr);
        formData.append('filecap', filecap);
        formData.append('noupsCap', noupsCap);
        formData.append('innerRqr', innerRqr);
        formData.append('sheet', sheet);
        formData.append('noUps', noUps);
        formData.append('trayRqr', trayRqr);
        formData.append('trayStyle', trayStyle);
        formData.append('trayWthik', trayWthik);
        formData.append('machinetype', machinetype);
        formData.append('sheetType', sheetType);
        formData.append('topRqr', topRqr);
        formData.append('topHbdm', topHbdm);
        formData.append('gellaCapH', gellaCapH);
        formData.append('orderid', jobno);
        formData.append('jobname', jobname);
        formData.append('jobmode', jobmode);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.addjoborder, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            if (response.data.status === "success") {
                Swal.fire('Success', 'Order Created!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Something Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    function startform(e) {
        setMaincat(e.target.value);
        var firstselect = document.getElementById("firstselect");
        console.log(firstselect.value)
        var Duplex = document.getElementById("Duplex");
        var Corrugation = document.getElementById("Corrugation");
        var Tag = document.getElementById("Tag");
        var blst = document.getElementById("blst");
        var Interlining = document.getElementById("Interlining clothes");
        var Sticker = document.getElementById("Sticker");
        var Rigid = document.getElementById("Rigid");
        var Other = document.getElementById("Other");

        if (firstselect.value === "Duplex") {
            Duplex.style.display = "block";
            Corrugation.style.display = "none";
            Tag.style.display = "none";
            Interlining.style.display = "none";
            Sticker.style.display = "none";
            Rigid.style.display = "none";
            Other.style.display = "none";
            blst.style.display = "none";
        }
        else {
            if (firstselect.value === "Corrugation") {
                Duplex.style.display = "none";
                Corrugation.style.display = "block";
                Tag.style.display = "none";
                Interlining.style.display = "none";
                Sticker.style.display = "none";
                Rigid.style.display = "none";
                Other.style.display = "none";
                blst.style.display = "none";
            }
            else {
                if (firstselect.value === "Tag") {
                    Duplex.style.display = "none";
                    Corrugation.style.display = "none";
                    Tag.style.display = "block";
                    Interlining.style.display = "none";
                    Sticker.style.display = "none";
                    Rigid.style.display = "none";
                    Other.style.display = "none";
                    blst.style.display = "none";
                }
                else {
                    if (firstselect.value === "Interlining clothes") {
                        Duplex.style.display = "none";
                        Corrugation.style.display = "none";
                        Tag.style.display = "none";
                        Interlining.style.display = "block";
                        Sticker.style.display = "none";
                        Rigid.style.display = "none";
                        Other.style.display = "none";
                        blst.style.display = "none";
                    }
                    else {
                        if (firstselect.value === "Sticker") {
                            Duplex.style.display = "none";
                            Corrugation.style.display = "none";
                            Tag.style.display = "none";
                            Interlining.style.display = "none";
                            Sticker.style.display = "block";
                            Rigid.style.display = "none";
                            Other.style.display = "none";
                            blst.style.display = "none";
                        }
                        else {
                            if (firstselect.value === "Other") {
                                Duplex.style.display = "none";
                                Corrugation.style.display = "none";
                                Tag.style.display = "none";
                                Interlining.style.display = "none";
                                Sticker.style.display = "none";
                                Rigid.style.display = "none";
                                Other.style.display = "block";
                                blst.style.display = "none";
                            }
                            else {
                                if (firstselect.value === "Blister") {
                                    Duplex.style.display = "none";
                                    Corrugation.style.display = "none";
                                    Tag.style.display = "none";
                                    Interlining.style.display = "none";
                                    Sticker.style.display = "none";
                                    Rigid.style.display = "none";
                                    Other.style.display = "none";
                                    blst.style.display = "block";
                                }
                                else {
                                    if (firstselect.value === "Rigid box") {
                                        Duplex.style.display = "none";
                                        Corrugation.style.display = "none";
                                        Tag.style.display = "none";
                                        Interlining.style.display = "none";
                                        Sticker.style.display = "none";
                                        Rigid.style.display = "block";
                                        Other.style.display = "none";
                                        blst.style.display = "none";
                                    }

                                }
                            }
                        }
                    }
                }
            }
        }
    }

    useEffect(() => {
        handleMachineData();
        handleSheetData();
        handleCompanyName();
        handleSample();
    }, []);


    const handleMachineData = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.keymachinedata, formData)
            setMachinedata(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleSheetData = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.keysheetdata, formData)
            setSheetdata(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }


    function startformNew(f) {
        setCat(f.target.value);
        var Newselect = document.getElementById("Newselect");
        var Magnetbox = document.getElementById("Magnetbox");
        var topbottom = document.getElementById("topbottom");
        var topbottomtype = document.getElementById("topbottomtype");
        var toponly = document.getElementById("toponly");
        var bottom = document.getElementById("bottom");

        if (Newselect.value === "C Type box magnet box") {
            Magnetbox.style.display = "block";
            topbottom.style.display = "none";
            topbottomtype.style.display = "none";
            toponly.style.display = "none";
            bottom.style.display = "none";
        }
        else {
            if (Newselect.value === "L Shape ribbon box") {
                Magnetbox.style.display = "block";
                topbottom.style.display = "none";
                topbottomtype.style.display = "none";
                toponly.style.display = "none";
                bottom.style.display = "none";
            }
            else {
                if (Newselect.value === "File all side cover") {
                    Magnetbox.style.display = "block";
                    topbottom.style.display = "none";
                    topbottomtype.style.display = "none";
                    toponly.style.display = "none";
                    bottom.style.display = "none";
                }
                else {
                    if (Newselect.value == "Top bottom kappa box") {
                        Magnetbox.style.display = "none";
                        topbottom.style.display = "block";
                        topbottomtype.style.display = "none";
                        toponly.style.display = "none";
                        bottom.style.display = "none";
                    }
                    else {
                        if (Newselect.value == "Top bottom gella type") {
                            Magnetbox.style.display = "none";
                            topbottom.style.display = "none";
                            topbottomtype.style.display = "block";
                            toponly.style.display = "none";
                            bottom.style.display = "none";
                        }
                        else {
                            if (Newselect.value == "Top only") {
                                Magnetbox.style.display = "none";
                                topbottom.style.display = "none";
                                topbottomtype.style.display = "none";
                                toponly.style.display = "block";
                                bottom.style.display = "none";
                            }
                            else {
                                if (Newselect.value == "Bottom only") {
                                    Magnetbox.style.display = "none";
                                    topbottom.style.display = "none";
                                    topbottomtype.style.display = "none";
                                    toponly.style.display = "none";
                                    bottom.style.display = "block";
                                }
                            }
                        }
                    }
                }

            }

        }
    }


    const [data, setData] = useState([]);
    const [companyname, setCompanyName] = useState('N/A');

    const handleCompanyName = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.userfethdata, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }


    const [Jobnumberdisplay, setJobnumberdisplay] = useState([]);

    const handleSample = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'All');
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.customorderstable, formData);
            setJobnumberdisplay(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleConvertoOrder = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('setmaincat', setmaincat);
        formData.append('quality', quality);
        formData.append('sheetthickness', sheetthickness);
        formData.append('boxstyle', boxstyle);
        formData.append('woodquality', woodquality);
        formData.append('woodsize', woodsize);
        formData.append('punching', punching);
        formData.append('lenth', lenth);
        formData.append('weight', weight);
        formData.append('height', height);
        formData.append('tuckflap', tuckflap);
        formData.append('glueflap', glueflap);
        formData.append('lock', lock);
        formData.append('dustflap', dustflap);
        formData.append('numberofups', numberofups);
        formData.append('sheetsize', sheetsize);
        formData.append('rouletoroule', rouletoroule);
        formData.append('rubbing', rubbing);
        formData.append('coro', coro);
        formData.append('matrix', matrix);
        formData.append('stripping', stripping);
        formData.append('pertinax', pertinax);
        formData.append('embossing', embossing);
        formData.append('description', description);
        formData.append('fulde', fulde);
        formData.append('fuldetype', fuldetype);
        formData.append('thickness', thickness);
        formData.append('rulequality', rulequality);
        formData.append('diequility', diequility);
        formData.append('punchingthickness', punchingthickness);
        formData.append('machine', machine);
        formData.append('machinesize', machinesize);
        formData.append('material', material);
        formData.append('blisterstyle', blisterstyle);
        formData.append('cardsize', cardsize);
        formData.append('width', cardsize);
        formData.append('bendingmargin', bendingmargin);
        formData.append('numberguide', numberguide);
        formData.append('collersize', collersize);
        formData.append('numberdies', numberdies);
        formData.append('wood', wood);
        formData.append('materialtype', materialtype);
        formData.append('plactictype', plactictype);
        formData.append('rule', rule);
        formData.append('ordertype', handleProptype);
        formData.append('productimg', selectedFile);
        formData.append('sid', customercode);
        formData.append('cname', companyname);
        formData.append('cat', cat);
        formData.append('bottomHard', bottomHard);
        formData.append('bottomcap', bottomcap);
        formData.append('gsm', gsm);
        formData.append('bottomstyle', bottomstyle);
        formData.append('upsHb', upsHb);
        formData.append('upsCap', upsCap);
        formData.append('fiRqr', fiRqr);
        formData.append('fihbd', fihbd);
        formData.append('fiCapRqr', fiCapRqr);
        formData.append('filecap', filecap);
        formData.append('noupsCap', noupsCap);
        formData.append('innerRqr', innerRqr);
        formData.append('sheet', sheet);
        formData.append('noUps', noUps);
        formData.append('trayRqr', trayRqr);
        formData.append('trayStyle', trayStyle);
        formData.append('trayWthik', trayWthik);
        formData.append('machinetype', machinetype);
        formData.append('sheetType', sheetType);
        formData.append('topRqr', topRqr);
        formData.append('topHbdm', topHbdm);
        formData.append('gellaCapH', gellaCapH);
        formData.append('orderid', handleOrderidsend);
        setLoading(true);
        formData.append('jobname', jobname);
        formData.append('jobmode', jobmode);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.converttoordertable, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            if (response.data.status === "success") {
                Swal.fire('Success', 'Order Created!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Something Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return (
        <div className={clasess.container1}>
            <Header />
            <Sidemenu />
            <div className={clasess.container}>
                <Loading loading={loading} />
                <h4>Add job</h4>
                <hr className='mb-0' />
                <div className="m-2">
                    <Link to="/myorder"><Button variant="success">Back</Button></Link>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-8  col-md-10" style={{ background: '#F7F6FC' }}>
                        <div className="content">
                            <div className="d-flex justify-content-center">
                                <h1>Job</h1>
                            </div>
                            <div id="form-file-upload" className="beautiful-form mt-3">
                                <div className="upload-container">
                                    <input type="file" id="" multiple={false} onChange={handleFileChange} className="border w-100" />
                                    <div className="row">
                                        <div className='col-md-12 mt-2'>
                                            <div className='d-flex'><label>Job name</label></div>
                                            <input type="text" id="input-name" placeholder="Enter job name" className='mt-2' onChange={(e) => setJobname(e.target.value)} />
                                        </div>
                                        <div className='col-md-12 mt-2'>
                                            <div className='d-flex'><label>Job mode</label></div>
                                            <Form.Select aria-label="Select job mode" className='mt-2' onChange={(e) => setJobmode(e.target.value)}>
                                                <option selected disabled>Select Mode</option>
                                                <option value="Whattsapp">Whattsapp</option>
                                                <option value="Email">Email</option>
                                                <option value="By visit">By Visit</option>
                                                <option value="By Phone Call">By Phone Call</option>
                                            </Form.Select>
                                        </div>
                                    </div>
                                    {handleOrderidsend === '' ? (
                                        <>
                                            <div className='d-flex mt-3 mb-2'>
                                                <label>Assigne Master Job (It is for making the Master Job)</label>
                                            </div>
                                            <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setJobno(e.target.value)}>
                                                <option selected disabled>Select User</option>
                                                {Jobnumberdisplay && Jobnumberdisplay.map((item) => (
                                                    <option value={item.orderid}>{item.orderid}</option>
                                                ))}
                                            </Form.Select>
                                        </>
                                    ) : (
                                        <>
                                            <div className='d-flex mt-3 mb-2'>
                                                <label>JobNumber :- {handleOrderidsend} </label>
                                            </div>
                                        </>
                                    )}

                                    <div className='d-flex mt-3 mb-2'>
                                        <label>Company name</label>
                                    </div>
                                    <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setCompanyName(e.target.value)}>
                                        <option selected disabled>Select User</option>
                                        {data && data.map((item) => (
                                            <option value={item.cname}>{item.cname}</option>
                                        ))}
                                    </Form.Select>
                                    <div className='text-justify mt-2'><label>Category</label></div>
                                    <Form.Select aria-label="Default select example" id="firstselect" style={{ marginTop: '10px' }} onChange={startform}>
                                        <option selected disabled>Select Main Category</option>
                                        <option value="Duplex">Duplex</option>
                                        <option value="Corrugation">Corrugation</option>
                                        <option value="Tag">Tag</option>
                                        <option value="Blister">Blister</option>
                                        <option value="Interlining clothes">Interlining clothes</option>
                                        <option value="Sticker">Sticker</option>
                                        <option value="Rigid box">Rigid box</option>
                                        <option value="Other">Other</option>
                                    </Form.Select>
                                    <div id="Duplex" style={{ display: 'none' }}>
                                        <div>
                                            <div className='row mt-2'>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Quality</label></div>
                                                    <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setQuality(e.target.value)}>
                                                        <option selected disabled>Select Quality</option>
                                                        <option value="fbb">FBB</option>
                                                        <option value="sbs">SBS</option>
                                                        <option value="grayback">Gray Back</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Sheet Thickness</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Sheet Thickness" className='mt-2' onChange={(e) => setSheetthickness(e.target.value)} />
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Box Style</label></div>
                                                    <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setBoxstyle(e.target.value)} >
                                                        <option selected disabled>Select Box Style</option>
                                                        <option value="Reversetuck">Reverse tuck</option>
                                                        <option value="Crash Lock">Crash Lock</option>
                                                        <option value="Snap Lock">Snap Lock</option>
                                                        <option value="Top Bottom">Top Bottom</option>
                                                        <option value="Rollover">Rollover</option>
                                                        <option value="Hanging Box">Hanging Box</option>
                                                        <option value="Cake Box">Cake Box</option>
                                                        <option value="Big flap">Big flap</option>
                                                        <option value="Small flap">Small flap</option>
                                                        <option value="Folding Box">Folding Box</option>
                                                        <option value="Folding Sweet Box">Folding Sweet Box</option>
                                                        <option value="Tray">Tray</option>
                                                        <option value="Sleeve">Sleeve</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Die Cutting Machine</label></div>
                                                    <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setMachinetype(e.target.value)} >
                                                        <option selected disabled>Select machine type</option>
                                                        {machinedata && machinedata.map((item) => (
                                                            <option value={item.mn}>{item.mn}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>

                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Sheet type</label></div>
                                                    <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setSheetType(e.target.value)} >
                                                        <option selected disabled>Select sheet type</option>
                                                        {sheetdata && sheetdata.map((item) => (
                                                            <option value={item.unickname}>{item.unickname}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Wood Quality</label></div>
                                                    <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setWoodquality(e.target.value)} >
                                                        <option selected disabled>Select Wood Quality</option>
                                                        <option value="Normal">Normal</option>
                                                        <option value="Russian Burch">Russian Burch</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Wood Size</label></div>
                                                    <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setWoodSize(e.target.value)} >
                                                        <option selected disabled>Select Wood Size</option>
                                                        <option value="16mm">16mm</option>
                                                        <option value="18mm">18mm</option>
                                                        <option value="6mm">6mm</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Punching</label></div>
                                                    <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setPunching(e.target.value)} >
                                                        <option selected disabled>Select Punching</option>
                                                        <option value="Front Cut">Front Cut</option>
                                                        <option value="Back Cut">Back Cut</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Rule Quality</label></div>
                                                    <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setQuality(e.target.value)} >
                                                        <option selected disabled>Select rule quality</option>
                                                        <option value="Semi">Semi</option>
                                                        <option value="premium">premium</option>
                                                        <option value="premium">super premium</option>
                                                        <option value="advance premium">advance premium</option>
                                                    </Form.Select>
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>Lenth</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Lenth" onChange={(e) => setLenth(e.target.value)} />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>weight</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="weight" onChange={(e) => setWeight(e.target.value)} />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>height</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="height" onChange={(e) => setHeight(e.target.value)} />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>Tuck Flap</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Tuck Flap" onChange={(e) => setTuckflap(e.target.value)} />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>Glue Flap</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Glue Flap" onChange={(e) => setGlueflap(e.target.value)} />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>Lock</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Lock" onChange={(e) => setLock(e.target.value)} />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>Dust Flap</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Dust Flap" onChange={(e) => setDustflap(e.target.value)} />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>No of ups</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="No of ups" onChange={(e) => setNumberofups(e.target.value)} />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>Sheet Size</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Sheet Size" onChange={(e) => setSheetsize(e.target.value)} />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>Roule to Roule</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Roule to Roule" onChange={(e) => setRouletoroule(e.target.value)} />
                                                </div>
                                            </div>

                                            <div className='row mt-3'>
                                                <div className='col-md-2'>
                                                    <div><label className='mb-4 ms-0'>Rubbering</label></div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2 " type="radio" name="exampleRadios" id="exampleRadios1" value="Yes" onChange={(e) => setRubbing(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios1">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios" id="exampleRadios1" value="No" onChange={(e) => setRubbing(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios1">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div><label className='mb-4 ms-0'>Matrix</label></div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios5" id="exampleRadios5" value="Yes" onChange={(e) => setMatrix(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios5">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios5" id="exampleRadios5" value="No" onChange={(e) => setMatrix(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios5">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div><label className='mb-4 ms-0'>Stripping</label></div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios7" id="exampleRadios7" value="Yes" onChange={(e) => setStripping(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios7">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios7" id="exampleRadios7" value="No" onChange={(e) => setStripping(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios7">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div><label className='mb-4 ms-0'>Pertinax</label></div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios9" id="exampleRadios9" value="Yes" onChange={(e) => setPertinax(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios9">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios9" id="exampleRadios9" value="No" onChange={(e) => setPertinax(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios9">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div><label className='mb-4 ms-0'>Embossing</label></div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios11" id="exampleRadios11" value="Yes" onChange={(e) => setEmbossing(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios11">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios11" id="exampleRadios11" value="No" onChange={(e) => setEmbossing(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios11">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <textarea id="message-box" style={{ display: 'block' }} placeholder="Description" className='mt-3' onChange={(e) => setDescription(e.target.value)} ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="Corrugation" style={{ display: 'none' }}>
                                        <div>
                                            <div className='row'>
                                                <div className='com=l-md-6'>
                                                    <div className='d-flex mt-3'><label>fulde</label></div>
                                                    <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setFulde(e.target.value)} >
                                                        <option selected disabled>Select fulde</option>
                                                        <option value="3ply">3 ply</option>
                                                        <option value="5ply">5 ply</option>
                                                        <option value="7ply">7 ply</option>
                                                        <option value="9ply">9 ply</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>flude quelity</label></div>
                                                    <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setFuldetype(e.target.value)} >
                                                        <option selected disabled>Select flude quelity</option>
                                                        <option value="3ply">E Fulde</option>
                                                        <option value="5ply">b Flude</option>
                                                        <option value="7ply">c Flude</option>
                                                        <option value="9ply">Narrow Flude</option>
                                                        <option value="9ply">Broad Flude</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Sheet Thickness</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Sheet Thickness" onChange={(e) => setThickness(e.target.value)} />
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>sheet thickness after craching</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="sheet thickness after craching" onChange={(e) => setSheetthickness(e.target.value)} />
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Box Style</label></div>
                                                    <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setBoxstyle(e.target.value)} >
                                                        <option selected disabled>Select Box Style</option>
                                                        <option value="Reverse tuck">Reverse tuck</option>
                                                        <option value="Crash lock">Crash lock</option>
                                                        <option value="Snap lock">Snap lock</option>
                                                        <option value="Rollover">Rollover</option>
                                                        <option value="Hanging box">Hanging box</option>
                                                        <option value="trunk type">trunk type</option>
                                                        <option value="side Patti">side Patti</option>
                                                        <option value="Economical folding">Economical folding</option>
                                                        <option value="pizza box">pizza box</option>
                                                        <option value="Folding Wall">Folding Wall</option>
                                                        <option value="Half Carton single rule">Half Carton single rule</option>
                                                        <option value="full carton single rule">full carton single rule</option>
                                                        <option value="half carton double rule">half carton double rule</option>
                                                        <option value="full carton double rule">full carton double rule</option>
                                                        <option value="handle box booton crash lock">handle box booton crash lock</option>
                                                        <option value="handle box bottom push lock">handle box bottom push lock</option>
                                                        <option value="folding tray">folding tray</option>
                                                        <option value="snap lock half + half">snap lock half + half</option>
                                                        <option value="bottom nalidar">bottom nalidar</option>
                                                        <option value="top nalidar">top nalidar</option>
                                                        <option value="top + bootom">top + bootom</option>
                                                        <option value="crockery box">crockery box</option>
                                                        <option value="glass boxes">glass boxes</option>
                                                        <option value="reverse tuck with two side toungue">reverse tuck with two side toungue</option>
                                                        <option value="reverse tuck with one side toungue">reverse tuck with one side toungue</option>
                                                        <option value="crash lock with tongue">crash lock with tongue</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Die Cutting Machine</label></div>
                                                    <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setMachinetype(e.target.value)} >
                                                        <option selected disabled>Select machine type</option>
                                                        {machinedata && machinedata.map((item) => (
                                                            <option value={item.mn}>{item.mn}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>

                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Sheet type</label></div>
                                                    <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setSheetType(e.target.value)} >
                                                        <option selected disabled>Select sheet type</option>
                                                        {sheetdata && sheetdata.map((item) => (
                                                            <option value={item.unickname}>{item.unickname}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Wood Quality</label></div>
                                                    <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setWoodquality(e.target.value)} >
                                                        <option selected disabled>Select Wood Quality</option>
                                                        <option value="Normal">Normal</option>
                                                        <option value="Russian Burch">Russian Burch</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Wood Size</label></div>
                                                    <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setWoodSize(e.target.value)} >
                                                        <option selected disabled>Select Wood Size</option>
                                                        <option value="16mm">16mm</option>
                                                        <option value="18mm">18mm</option>
                                                        <option value="6mm">6mm</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Punching</label></div>
                                                    <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setPunching(e.target.value)} >
                                                        <option selected disabled>Select Punching</option>
                                                        <option value="Front Cut">Front Cut</option>
                                                        <option value="Back Cut">Back Cut</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Rule quality</label></div>
                                                    <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setRulequality(e.target.value)} >
                                                        <option selected disabled>Select rule quality</option>
                                                        <option value="Semi">Semi</option>
                                                        <option value="premium">premium</option>
                                                        <option value="premium">super premium</option>
                                                        <option value="advance premium">advance premium</option>
                                                    </Form.Select>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>Lenth</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Lenth" onChange={(e) => setLenth(e.target.value)} />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>weight</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="weight" onChange={(e) => setWeight(e.target.value)} />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>height</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="height" onChange={(e) => setHeight(e.target.value)} />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>Tuck Flap</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Tuck Flap" onChange={(e) => setTuckflap(e.target.value)} />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>Glue Flap</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Glue Flap" onChange={(e) => setGlueflap(e.target.value)} />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>Lock</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Lock" onChange={(e) => setLock(e.target.value)} />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>Dust Flap</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Dust Flap" onChange={(e) => setDustflap(e.target.value)} />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>No of ups</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="No of ups" onChange={(e) => setNumberofups(e.target.value)} />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>Sheet Size</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Sheet Size" onChange={(e) => setSheetsize(e.target.value)} />
                                                </div>
                                                <div className='col-md-2'>
                                                    <div className='d-flex'><label>Roule to Roule</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Roule to Roule" onChange={(e) => setRouletoroule(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-md-2'>
                                                    <div><label className='mb-4 ms-0'>Rubbering</label></div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios" id="exampleRadios1" value="Yes" onChange={(e) => setRubbing(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios1">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios" id="exampleRadios1" value="No" onChange={(e) => setRubbing(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios1">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div><label className='mb-4 ms-0'>Matrix</label></div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios5" id="exampleRadios5" value="Yes" onChange={(e) => setMatrix(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios5">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios5" id="exampleRadios5" value="No" onChange={(e) => setMatrix(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios5">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div><label className='mb-4 ms-0'>Stripping</label></div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios7" id="exampleRadios7" value="Yes" onChange={(e) => setStripping(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios7">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios7" id="exampleRadios7" value="No" onChange={(e) => setStripping(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios7">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div><label className='mb-4 ms-0'>Pertinax</label></div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios9" id="exampleRadios9" value="Yes" onChange={(e) => setPertinax(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios9">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios9" id="exampleRadios9" value="No" onChange={(e) => setPertinax(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios9">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div><label className='mb-4 ms-0'>Embossing</label></div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios11" id="exampleRadios11" value="Yes" onChange={(e) => setEmbossing(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios11">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios11" id="exampleRadios11" value="No" onChange={(e) => setEmbossing(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios11">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <textarea id="message-box" style={{ display: 'block' }} placeholder="Description" className='mt-3' onChange={(e) => setDescription(e.target.value)} ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="Tag" style={{ display: 'none' }}>
                                        <div>
                                            <div className='row'>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Sheet Thickness</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Sheet Thickness" onChange={(e) => setSheetthickness(e.target.value)} />
                                                </div>
                                                {/* <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Die quility</label></div>
                                                    <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setDiequility(e.target.value)} >
                                                        <option selected disabled>Select Die quility</option>
                                                        <option value="Automatic">abc</option>
                                                        <option value="manual">xyz</option>
                                                    </Form.Select>
                                                </div> */}
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Punch Thickness</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Punch Thickness" onChange={(e) => setPunchingthickness(e.target.value)} />
                                                </div>
                                                {/* <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Die Cutting Machine</label></div>
                                                    <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setMachine(e.target.value)} >
                                                        <option selected disabled>Select Machine Type</option>
                                                        <option value="Automatic">Automatic</option>
                                                        <option value="manual">manual</option>
                                                    </Form.Select>
                                                </div> */}
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Die Cutting Machine</label></div>
                                                    <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setMachinetype(e.target.value)} >
                                                        <option selected disabled>Select machine type</option>
                                                        {machinedata && machinedata.map((item) => (
                                                            <option value={item.mn}>{item.mn}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>

                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Sheet type</label></div>
                                                    <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setSheetType(e.target.value)} >
                                                        <option selected disabled>Select sheet type</option>
                                                        {sheetdata && sheetdata.map((item) => (
                                                            <option value={item.unickname}>{item.unickname}</option>
                                                        ))}
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Machine Size</label></div>
                                                    <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setMachinesize(e.target.value)} >
                                                        <option selected disabled>Select Machine Size</option>
                                                        <option value="Automatic">Automatic</option>
                                                        <option value="manual">manual</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Wood Quality</label></div>
                                                    <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setWoodquality(e.target.value)} >
                                                        <option selected disabled>Select Wood Quality</option>
                                                        <option value="Automatic">Normal</option>
                                                        <option value="manual">Russian Burch</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Wood Size</label></div>
                                                    <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setWoodSize(e.target.value)} >
                                                        <option selected disabled>Select Wood Size</option>
                                                        <option value="16mm">16mm</option>
                                                        <option value="18mm">18mm</option>
                                                        <option value="6mm">6mm</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Rule quality</label></div>
                                                    <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setRulequality(e.target.value)} >
                                                        <option selected disabled>Select rule quality</option>
                                                        <option value="Semi">Semi</option>
                                                        <option value="premium">premium</option>
                                                        <option value="super premium">super premium</option>
                                                        <option value="advance premium">advance premium</option>
                                                    </Form.Select>
                                                </div>
                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-md-2'>
                                                    <div><label className='mb-4 ms-0'>Rubbering</label></div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios" id="exampleRadios1" value="Yes" onChange={(e) => setRubbing(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios1">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios" id="exampleRadios1" value="No" onChange={(e) => setRubbing(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios1">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div><label className='mb-4 ms-0'>Matrix</label></div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios5" id="exampleRadios5" value="Yes" onChange={(e) => setMatrix(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios5">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios5" id="exampleRadios5" value="No" onChange={(e) => setMatrix(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios5">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div><label className='mb-4 ms-0'>Stripping</label></div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios7" id="exampleRadios7" value="Yes" onChange={(e) => setStripping(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios7">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios7" id="exampleRadios7" value="No" onChange={(e) => setStripping(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios7">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div><label className='mb-4 ms-0'>Pertinax</label></div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios9" id="exampleRadios9" value="Yes" onChange={(e) => setPertinax(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios9">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios9" id="exampleRadios9" value="No" onChange={(e) => setPertinax(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios9">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className='col-md-2'>
                                                    <div><label className='mb-4 ms-0'>Embossing</label></div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios11" id="exampleRadios11" value="Yes" onChange={(e) => setEmbossing(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios11">
                                                            Yes
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input class="form-check-input p-2" type="radio" name="exampleRadios11" id="exampleRadios11" value="No" onChange={(e) => setEmbossing(e.target.value)} />
                                                        <label class="form-check-label" for="exampleRadios11">
                                                            No
                                                        </label>
                                                    </div>
                                                </div>

                                                <div className='col-md-12'>
                                                    <textarea id="message-box" style={{ display: 'block' }} placeholder="Description" className='mt-3' onChange={(e) => setDescription(e.target.value)} ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="blst" style={{ display: 'none' }}>
                                        <div className='row'>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Material</label></div>
                                                <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setMaterial(e.target.value)} >
                                                    <option selected disabled>Select Material</option>
                                                    <option value="pet">PET</option>
                                                    <option value="pvc">PVC</option>
                                                </Form.Select>
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Blister Style</label></div>
                                                <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setBlisterstyle(e.target.value)} >
                                                    <option selected disabled>Select Blister Style</option>
                                                    <option value="three side round">Three Side Bend</option>
                                                    <option value="corner round">Corner Round</option>
                                                </Form.Select>
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Card Size</label></div>
                                                <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Card Size" onChange={(e) => setCardsize(e.target.value)} />
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Lenth</label></div>
                                                <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Lenth" onChange={(e) => setLenth(e.target.value)} />
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Width</label></div>
                                                <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Width" onChange={(e) => setWidth(e.target.value)} />
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Bending Margin</label></div>
                                                <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Bending Margin" onChange={(e) => setBendingmargin(e.target.value)} />
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Wood Quality</label></div>
                                                <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setWoodquality(e.target.value)} >
                                                    <option selected disabled>Select Wood Quility</option>
                                                    <option value="Normal">Normal</option>
                                                    <option value="Russian">Russian Burch</option>
                                                </Form.Select>
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Wood Size</label></div>
                                                <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setWoodSize(e.target.value)} >
                                                    <option selected disabled>Select Wood Size</option>
                                                    <option value="16mm">16mm</option>
                                                    <option value="18mm">18mm</option>
                                                    <option value="6mm">6mm</option>
                                                </Form.Select>
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Number Guide</label></div>
                                                <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Number Guide Required" onChange={(e) => setNumberguide(e.target.value)} />
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Thickness</label></div>
                                                <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Thickness" onChange={(e) => setThickness(e.target.value)} />
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Rule quality</label></div>
                                                <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setRulequality(e.target.value)} >
                                                    <option selected disabled>Select rule quality</option>
                                                    <option value="Semi">Semi</option>
                                                    <option value="premium">premium</option>
                                                    <option value="premium">super premium</option>
                                                    <option value="advance premium">advance premium</option>
                                                </Form.Select>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='d-flex'><label>Description</label></div>
                                                <textarea id="message-box" style={{ display: 'bock' }} placeholder="Description" onChange={(e) => setDescription(e.target.value)} ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="Interlining clothes" style={{ display: 'none' }}>
                                        <div>
                                            <div className='row'>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Material</label></div>
                                                    <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setMaterial(e.target.value)} >
                                                        <option selected disabled>Select Material</option>
                                                        <option value="coller">Coller</option>
                                                        <option value="ladies neck">Ladies Neck</option>
                                                        <option value="cuff round neck">Cuff Round Shape</option>
                                                        <option value="cuff cut shape">Cuff cut shape</option>
                                                        <option value="shoulder patti">Shoulder Patti</option>
                                                        <option value="design patti">Design Patti</option>
                                                        <option value="zig zag patti">Zig Zag Patti</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Coller Size</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Coller Size" onChange={(e) => setCollersize(e.target.value)} />
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Number Dies</label></div>
                                                    <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Number Dies" onChange={(e) => setNumberdies(e.target.value)} />
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Quality</label></div>
                                                    <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setQuality(e.target.value)} >
                                                        <option selected disabled>Select Quality</option>
                                                        <option value="pet">3 patti</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Wood</label></div>
                                                    <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setWood(e.target.value)} >
                                                        <option selected disabled>Select Wood</option>
                                                        <option value="pet">Normal</option>
                                                        <option value="pvc">16 mm</option>
                                                        <option value="pvc">Birch</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-6 mt-2'>
                                                    <div className='d-flex'><label>Rule quality</label></div>
                                                    <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setRulequality(e.target.value)}>
                                                        <option selected disabled>Select rule quality</option>
                                                        <option value="Semi">Semi</option>
                                                        <option value="premium">premium</option>
                                                        <option value="super premium">super premium</option>
                                                        <option value="advance premium">advance premium</option>
                                                    </Form.Select>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className='d-flex'><label>Description</label></div>
                                                    <textarea id="message-box" style={{ display: 'block' }} placeholder="Description" onChange={(e) => setDescription(e.target.value)} ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="Sticker" style={{ display: 'none' }}>
                                        <div className='row'>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Material Type</label></div>
                                                <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setMaterialtype(e.target.value)} >
                                                    <option selected disabled>Select Material Type</option>
                                                    <option value="fbb">Half Cut</option>
                                                    <option value="sbs">Full Cut</option>
                                                </Form.Select>
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Plactic Type</label></div>
                                                <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setPlactictype(e.target.value)}>
                                                    <option selected disabled>Select Plactic Type</option>
                                                    <option value="pvc">pvc</option>
                                                    <option value="venyl">venyl</option>
                                                    <option value="matt">matt</option>
                                                </Form.Select>
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Description</label></div>
                                                <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setDescription(e.target.value)} >
                                                    <option selected disabled>Select Machine Size</option>
                                                    <option value="fbb">Automatic</option>
                                                    <option value="sbs">manual</option>
                                                </Form.Select>
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Quality</label></div>
                                                <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setQuality(e.target.value)} >
                                                    <option selected disabled>Select Quality</option>
                                                    <option value="semipermium">Semi Permium</option>
                                                    <option value="superpremium">Super Premium</option>
                                                    <option value="advanced">Advanced</option>
                                                </Form.Select>
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Wood Quality</label></div>
                                                <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setWoodquality(e.target.value)} >
                                                    <option selected disabled>Select Wood Quality</option>
                                                    <option value="Automatic">Normal</option>
                                                    <option value="manual">Russian Burch</option>
                                                </Form.Select>
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Wood Size</label></div>
                                                <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setWoodSize(e.target.value)} >
                                                    <option selected disabled>Select Wood Size</option>
                                                    <option value="16mm">16mm</option>
                                                    <option value="18mm">18mm</option>
                                                    <option value="6mm">6mm</option>
                                                </Form.Select>
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Rule quality</label></div>
                                                <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setRulequality(e.target.value)} >
                                                    <option selected disabled>Select rule quality</option>
                                                    <option value="Semi">Semi</option>
                                                    <option value="premium">premium</option>
                                                    <option value="super premium">super premium</option>
                                                    <option value="advance premium">advance premium</option>
                                                </Form.Select>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='d-flex'><label>Description</label></div>
                                                <textarea id="message-box" style={{ display: 'block' }} placeholder="Description" onChange={(e) => setDescription(e.target.value)} ></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Rigid box start here  */}

                                    <div id="Rigid" style={{ display: 'none' }}>
                                        <div className='row'>
                                            <div className='col-md-12 mt-2'>
                                                <div className='d-flex'><label>Rigid style</label></div>
                                                <Form.Select aria-label="Default select example" id="Newselect" style={{ marginTop: '10px' }} onChange={startformNew}>
                                                    <option selected disabled>Select Rigid style </option>

                                                    <option value="C Type box magnet box">C Type box magnet box</option>
                                                    <option value="L Shape ribbon box">L Shape ribbon box</option>
                                                    <option value="File all side cover">File all side cover</option>

                                                    <option value="Top bottom kappa box">Top bottom kappa box</option>
                                                    <option value="Top bottom gella type">Top bottom gella type </option>
                                                    <option value="Top only">Top only</option>
                                                    <option value="Bottom only">Bottom only </option>
                                                </Form.Select>
                                            </div>

                                            {/* C Type box magnet box ,  L Shape ribbon box , File all side cover  */}

                                            <div id="Magnetbox" style={{ display: 'none' }} >
                                                <div className="row">
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Die Cutting Machine</label></div>
                                                        <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setMachinetype(e.target.value)} >
                                                            <option selected disabled>Select machine type</option>
                                                            {machinedata && machinedata.map((item) => (
                                                                <option value={item.mn}>{item.mn}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Sheet type</label></div>
                                                        <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setSheetType(e.target.value)} >
                                                            <option selected disabled>Select sheet type</option>
                                                            {sheetdata && sheetdata.map((item) => (
                                                                <option value={item.unickname}>{item.unickname}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-4 mt-2'>
                                                        <div className='d-flex'><label>Length</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Length" onChange={(e) => setLenth(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-4 mt-2'>
                                                        <div className='d-flex'><label>Width</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Width" onChange={(e) => setWidth(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-4 mt-2'>
                                                        <div className='d-flex'><label>Height</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Height" onChange={(e) => setHeight(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Bottom hard board material  </label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setBottomHard(e.target.value)}>
                                                            <option selected disabled>Select hard board </option>
                                                            <option value="Local hard board">Local hard board</option>
                                                            <option value="English kappa board">English kappa board</option>
                                                            <option value="Mdf">Mdf</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Bottom cap</label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setBottomCap(e.target.value)}>
                                                            <option selected disabled>Select bottom Type</option>
                                                            <option value="Sheet">Sheet</option>
                                                            <option value="Paper">Paper</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Gsm</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter gsm" onChange={(e) => setGsm(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Bottom cap style</label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setBoStyle(e.target.value)}>
                                                            <option selected disabled>Select bottom style</option>
                                                            <option value="Patti">Patti</option>
                                                            <option value="Full cap">Full cap</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups hard board</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter No of ups hard board" onChange={(e) => setUpsHb(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups cap</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter No of ups cap" onChange={(e) => setUpsCap(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>File Required</label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setFiRqr(e.target.value)}>
                                                            <option selected disabled>Select file required</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="venyl">No</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className="col-md-12 mt-2 mb-2 d-flex">
                                                        <label>file cap Size</label>
                                                    </div>
                                                    <div className='col-md-4 mt-2'>
                                                        <div className='d-flex'><label>Length</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Length" onChange={(e) => setLenth(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-4 mt-2'>
                                                        <div className='d-flex'><label>Width</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Width" onChange={(e) => setWidth(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-4 mt-2'>
                                                        <div className='d-flex'><label>Height</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Height" onChange={(e) => setHeight(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>File hard board</label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setFihbd(e.target.value)}>
                                                            <option selected disabled>Select hard board material </option>
                                                            <option value="Local hard board">Local hard board</option>
                                                            <option value="English kappa board">English kappa board</option>
                                                            <option value="Mdf">Mdf</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>File cap Required</label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setfiCapRqr(e.target.value)}>
                                                            <option selected disabled>Select file required</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>File cap </label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setFilecap(e.target.value)}>
                                                            <option selected disabled>Select file cap</option>
                                                            <option value="Sheet">Sheet</option>
                                                            <option value="Paper">Paper</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Gsm</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter gsm" onChange={(e) => setGsm(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups hard board</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter No of ups hard board" onChange={(e) => setUpsHb} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups cap</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter No of ups cap" onChange={(e) => setNoupsCap(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Inner Require </label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setInnerRqr(e.target.value)}>
                                                            <option selected disabled>Select Inner</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Sheet</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Paper /sheet with gsm" onChange={(e) => setSheet(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="No of ups" onChange={(e) => setNoUps(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Tray Require </label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setTrayRqr(e.target.value)}>
                                                            <option selected disabled>Select require</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Tray style</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter tray style" onChange={(e) => setTrayStyle(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Tray wall thickness</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter tray wall thickness" onChange={(e) => setTrayWthik(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="No of ups" onChange={(e) => setNoUps(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <div className='d-flex'><label>Description</label></div>
                                                        <textarea id="message-box" style={{ display: 'block' }} placeholder="Description" onChange={(e) => setDescription(e.target.value)}></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Top bottom kappa box */}

                                            <div id="topbottom" style={{ display: 'none' }}>

                                                <div className="row">
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Die Cutting Machine</label></div>
                                                        <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setMachinetype(e.target.value)} >
                                                            <option selected disabled>Select machine type</option>
                                                            {machinedata && machinedata.map((item) => (
                                                                <option value={item.mn}>{item.mn}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Sheet type</label></div>
                                                        <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setSheetType(e.target.value)} >
                                                            <option selected disabled>Select sheet type</option>
                                                            {sheetdata && sheetdata.map((item) => (
                                                                <option value={item.unickname}>{item.unickname}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-4 mt-2'>
                                                        <div className='d-flex'><label>Length</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Length" onChange={(e) => setLenth(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-4 mt-2'>
                                                        <div className='d-flex'><label>Width</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Width" onChange={(e) => setWidth(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-4 mt-2'>
                                                        <div className='d-flex'><label>Height</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Height" onChange={(e) => (e.target.height)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Bottom hard board material  </label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setBottomHard(e.target.value)}>
                                                            <option selected disabled>Select hard board </option>
                                                            <option value="Local hard board">Local hard board</option>
                                                            <option value="English kappa board">English kappa board</option>
                                                            <option value="Mdf">Mdf</option>
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Bottom cap</label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setBottomCap(e.target.value)}>
                                                            <option selected disabled>Select bottom Type</option>
                                                            <option value="Sheet">Sheet</option>
                                                            <option value="Paper">Paper</option>
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Gsm</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter gsm" onChange={(e) => setGsm(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Bottom cap style</label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setBoStyle(e.target.value)}>
                                                            <option selected disabled>Select bottom style</option>
                                                            <option value="Patti">Patti</option>
                                                            <option value="Full cap">Full cap</option>
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups hard board</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter No of ups hard board" onChange={(e) => setUpsHb(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups cap</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter No of ups cap" onChange={(e) => setNoupsCap(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Top Require </label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setTopRqr(e.target.value)}>
                                                            <option selected disabled>Select top require</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Top hard board material  </label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setTopHbdm(e.target.value)}>
                                                            <option selected disabled>Select top hard board </option>
                                                            <option value="Local hard board">Local hard board</option>
                                                            <option value="English kappa board">English kappa board</option>
                                                            <option value="Mdf">Mdf</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Bottom cap</label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setBottomCap(e.target.value)}>
                                                            <option selected disabled>Select bottom Type</option>
                                                            <option value="Sheet">Sheet</option>
                                                            <option value="Paper">Paper</option>
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Gsm</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter gsm" onChange={(e) => setGsm(e.target.value)} />
                                                    </div>

                                                    {/*  */}

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups hard board</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter No of ups hard board" onChange={(e) => setUpsHb(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups cap</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter No of ups cap" onChange={(e) => setNoupsCap(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Tray Require </label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setTrayRqr(e.target.value)}>
                                                            <option selected disabled>Select require</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Tray style</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter tray style" onChange={(e) => setTrayStyle(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Tray wall thickness</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter tray wall thickness" onChange={(e) => setTrayWthik(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="No of ups" onChange={(e) => setNoUps(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-12'>
                                                        <div className='d-flex'><label>Description</label></div>
                                                        <textarea id="message-box" style={{ display: 'block' }} placeholder="Description" onChange={(e) => setDescription(e.target.value)} ></textarea>
                                                    </div>
                                                </div>

                                            </div>

                                            {/* TOP BOTTOM GELLA TYPE */}

                                            <div id="topbottomtype" style={{ display: 'none' }}>
                                                <div className="row">
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Die Cutting Machine</label></div>
                                                        <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setMachinetype(e.target.value)} >
                                                            <option selected disabled>Select machine type</option>
                                                            {machinedata && machinedata.map((item) => (
                                                                <option value={item.mn}>{item.mn}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Sheet type</label></div>
                                                        <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setSheetType(e.target.value)} >
                                                            <option selected disabled>Select sheet type</option>
                                                            {sheetdata && sheetdata.map((item) => (
                                                                <option value={item.unickname}>{item.unickname}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>
                                                    <div className='col-md-4 mt-2'>
                                                        <div className='d-flex'><label>Length</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Length" onChange={(e) => setLenth(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-4 mt-2'>
                                                        <div className='d-flex'><label>Width</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Width" onChange={(e) => setWidth(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-4 mt-2'>
                                                        <div className='d-flex'><label>Height</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Height" onChange={(e) => setHeight(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Bottom hard board material  </label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setBottomHard(e.target.value)}>
                                                            <option selected disabled>Select hard board </option>
                                                            <option value="Local hard board">Local hard board</option>
                                                            <option value="English kappa board">English kappa board</option>
                                                            <option value="Mdf">Mdf</option>
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Bottom cap</label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setBottomCap(e.target.value)}>
                                                            <option selected disabled>Select bottom Type</option>
                                                            <option value="Sheet">Sheet</option>
                                                            <option value="Paper">Paper</option>
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Gsm</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter gsm" onChange={(e) => setGsm(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Bottom cap style</label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setBoStyle(e.target.value)}>
                                                            <option selected disabled>Select bottom style</option>
                                                            <option value="Patti">Patti</option>
                                                            <option value="Full cap">Full cap</option>
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups hard board</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter No of ups hard board" onChange={(e) => setUpsHb(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups cap</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter No of ups cap" onChange={(e) => setNoupsCap(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Top Require </label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setTopRqr(e.target.value)}>
                                                            <option selected disabled>Select top require</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Top hard board material  </label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setTopHbdm(e.target.value)}>
                                                            <option selected disabled>Select top hard board </option>
                                                            <option value="Local hard board">Local hard board</option>
                                                            <option value="English kappa board">English kappa board</option>
                                                            <option value="Mdf">Mdf</option>
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Bottom cap</label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setBottomCap(e.target.value)}>
                                                            <option selected disabled>Select bottom Type</option>
                                                            <option value="Sheet">Sheet</option>
                                                            <option value="Paper">Paper</option>
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Gsm</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter gsm" onChange={(e) => setGsm(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Gella cap height</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter Gella cap height" onChange={(e) => setGelaCapH(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups hard board</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter No of ups hard board" onChange={(e) => setUpsHb(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups cap</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter No of ups cap" onChange={(e) => setNoupsCap(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Tray Require </label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setTopRqr(e.target.value)}>
                                                            <option selected disabled>Select require</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Tray style</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter tray style" onChange={(e) => setTrayStyle(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Tray wall thickness</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter tray wall thickness" onChange={(e) => setTrayWthik(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="No of ups" onChange={(e) => setNoUps(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-12'>
                                                        <div className='d-flex'><label>Description</label></div>
                                                        <textarea id="message-box" style={{ display: 'block' }} placeholder="Description" onChange={(e) => setDescription(e.target.value)} ></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* TOP ONLY */}

                                            <div id="toponly" style={{ display: 'none' }} >

                                                <div className="row">
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Die Cutting Machine</label></div>
                                                        <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setMachinetype(e.target.value)} >
                                                            <option selected disabled>Select machine type</option>
                                                            {machinedata && machinedata.map((item) => (
                                                                <option value={item.mn}>{item.mn}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Sheet type</label></div>
                                                        <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setSheetType(e.target.value)} >
                                                            <option selected disabled>Select sheet type</option>
                                                            {sheetdata && sheetdata.map((item) => (
                                                                <option value={item.unickname}>{item.unickname}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Top Require </label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setTopRqr(e.target.value)}>
                                                            <option selected disabled>Select top require</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className="d-flex mt-2 mb-2">
                                                        <label>Top cap Size</label>
                                                    </div>
                                                    <div className='col-md-4 mt-2'>
                                                        <div className='d-flex'><label>Length</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Length" onChange={(e) => setLenth(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-4 mt-2'>
                                                        <div className='d-flex'><label>Width</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Width" onChange={(e) => setWeight(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-4 mt-2'>
                                                        <div className='d-flex'><label>Height</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Height" onChange={(e) => setHeight(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Top hard board material  </label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setTopHbdm(e.target.value)}>
                                                            <option selected disabled>Select top hard board </option>
                                                            <option value="Local hard board">Local hard board</option>
                                                            <option value="English kappa board">English kappa board</option>
                                                            <option value="Mdf">Mdf</option>
                                                        </Form.Select>
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Bottom cap</label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setBottomCap(e.target.value)}>
                                                            <option selected disabled>Select bottom Type</option>
                                                            <option value="Sheet">Sheet</option>
                                                            <option value="Paper">Paper</option>
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Gsm</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter gsm" onChange={(e) => setGsm(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups hard board</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter No of ups hard board" onChange={(e) => setUpsHb(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups cap</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter No of ups cap" onChange={(e) => setNoupsCap(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-12'>
                                                        <div className='d-flex'><label>Description</label></div>
                                                        <textarea id="message-box" style={{ display: 'block' }} placeholder="Description" onChange={(e) => setDescription(e.target.value)} ></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Bottom only   */}

                                            <div id="bottom" style={{ display: 'none' }}>

                                                <div className="row">
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Die Cutting Machine</label></div>
                                                        <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setMachinetype(e.target.value)} >
                                                            <option selected disabled>Select machine type</option>
                                                            {machinedata && machinedata.map((item) => (
                                                                <option value={item.mn}>{item.mn}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Sheet type</label></div>
                                                        <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setSheetType(e.target.value)} >
                                                            <option selected disabled>Select sheet type</option>
                                                            {sheetdata && sheetdata.map((item) => (
                                                                <option value={item.unickname}>{item.unickname}</option>
                                                            ))}
                                                        </Form.Select>
                                                    </div>

                                                    <div className="d-flex mt-2 mb-2">
                                                        <label>Bottom cap Size</label>
                                                    </div>
                                                    <div className='col-md-4 mt-2'>
                                                        <div className='d-flex'><label>Length</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Length" onChange={(e) => setLenth(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-4 mt-2'>
                                                        <div className='d-flex'><label>Width</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Width" onChange={(e) => setWidth(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-4 mt-2'>
                                                        <div className='d-flex'><label>Height</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Height" onChange={(e) => setHeight(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Bottom cap</label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setBottomCap(e.target.value)}>
                                                            <option selected disabled>Select bottom Type</option>
                                                            <option value="Sheet">Sheet</option>
                                                            <option value="Paper">Paper</option>
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Gsm</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter gsm" onChange={(e) => setGsm(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>Bottom cap style</label></div>
                                                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setBoStyle(e.target.value)}>
                                                            <option selected disabled>Select bottom style</option>
                                                            <option value="Patti">Patti</option>
                                                            <option value="Full cap">Full cap</option>
                                                        </Form.Select>
                                                    </div>

                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups hard board</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter No of ups hard board" onChange={(e) => setUpsHb(e.target.value)} />
                                                    </div>
                                                    <div className='col-md-6 mt-2'>
                                                        <div className='d-flex'><label>No of ups cap</label></div>
                                                        <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter No of ups cap" onChange={(e) => setNoupsCap(e.target.value)} />
                                                    </div>

                                                    <div className='col-md-12'>
                                                        <div className='d-flex'><label>Description</label></div>
                                                        <textarea id="message-box" style={{ display: 'block' }} placeholder="Description" onChange={(e) => setDescription(e.target.value)}></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    {/* Rigid box ends here */}

                                    <div id="Other" style={{ display: 'none' }}>
                                        <div className='row'>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Rule</label></div>
                                                <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setRule(e.target.value)} >
                                                    <option selected disabled>Select Rule</option>
                                                    <option value="fbb">2 Patti</option>
                                                    <option value="sbs">3 Patti</option>
                                                </Form.Select>
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Wood Size</label></div>
                                                <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setWoodSize(e.target.value)} >
                                                    <option selected disabled>Select Wood Size</option>
                                                    <option value="16mm">16mm</option>
                                                    <option value="18mm">18mm</option>
                                                    <option value="6mm">6mm</option>
                                                </Form.Select>
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Quality</label></div>
                                                <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setQuality(e.target.value)} >
                                                    <option selected disabled>Select Quality</option>
                                                    <option value="semipermium">Semi Permium</option>
                                                    <option value="superpremium">Super Premium</option>
                                                    <option value="advanced">Advanced</option>
                                                </Form.Select>
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Punching</label></div>
                                                <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setPunching(e.target.value)} >
                                                    <option selected disabled>Select Punching</option>
                                                    <option value="fbb">Front Cutting</option>
                                                    <option value="sbs">Back Cutting</option>
                                                </Form.Select>
                                            </div>
                                            <div className='col-md-6 mt-2'>
                                                <div className='d-flex'><label>Rule quality</label></div>
                                                <Form.Select aria-label="Default select example" className='mt-2' onChange={(e) => setRulequality(e.target.value)} >
                                                    <option selected disabled>Select rule quality</option>
                                                    <option value="Semi">Semi</option>
                                                    <option value="premium">premium</option>
                                                    <option value="super premium">super premium</option>
                                                    <option value="advance premium">advance premium</option>
                                                </Form.Select>
                                            </div>
                                            <div className='col-md-12'>
                                                <div className='d-flex'><label>Description</label></div>
                                                <textarea id="message-box" style={{ display: 'block' }} placeholder="Description" onChange={(e) => setDescription(e.target.value)} ></textarea>
                                            </div>
                                        </div>
                                    </div>

                                    {handleOrderidsend === '' ? (
                                        <input type="submit" style={{ marginTop: '15px', clear: 'both' }} value="Complete Your Order" className="upload-button" onClick={handleCustomeOrder} />
                                    ) : (
                                        <>
                                            <input type="submit" style={{ marginTop: '15px', clear: 'both' }} value="Conver The Order" className="upload-button" onClick={handleConvertoOrder} />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default AddJjobCustomOrderView;