import React, { useState } from 'react';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assest/css/custom.css';
import '../assest/css/reset.css';
import '../assest/css/layout.css';
import '../assest/css/themes.css';
import '../assest/css/typography.css';
import '../assest/css/styles.css';
import '../assest/css/shCore.css';
import '../assest/css/bootstrap.css';
import '../assest/css/jquery.jqplot.css';
import '../assest/css/jquery-ui-1.8.18.custom.css';
import '../assest/css/data-table.css';
import '../assest/css/form.css';
import '../assest/css/ui-elements.css';
import '../assest/css/wizard.css';
import '../assest/css/sprite.css';
import '../assest/css/gradient.css';
import lib from '../config/lib';
import Swal from 'sweetalert2'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Loading from '../common/Loading';

const Login = () => {
    $(function () {
        $(window).resize(function () {
            $('.login_container').css({
                position: 'absolute',
                left: ($(window).width() - $('.login_container').outerWidth()) / 2,
                top: ($(window).height() - $('.login_container').outerHeight()) / 2
            });
        });
        // To initially run the function:
        $(window).resize();
    });

    const navigate = useNavigate('');
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('');
    const [loading, setLoading] = useState(false);

    const handleLogin = async (event) => {
        event.preventDefault();
        setLoading(true);

        const formData = new FormData();
        formData.append("email", email);
        formData.append("pass", pass);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.login, formData);
            if (response.data.status === "success") {
                localStorage.setItem("token", response.data.apikey);
                localStorage.setItem("role", response.data.role);
                if (response.data.role === "admin") {
                    navigate('/Dashboard');
                    setLoading(false);
                }
                else {
                    navigate('/employdashbord');
                    setLoading(false);
                }

            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'User-Id or Password May Be Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return (
        <div id="theme-default" className="full_block">
            <div id="login_page">
                <div className="login_container">
                    <Loading loading={loading} />
                    <div className="block_container no_block">
                        <form action="#" method="get">
                            <div className="block_form">
                                <ul>
                                    <li className="login_user">
                                        <input type="text" placeholder='Enter Emial' onChange={(e) => setEmail(e.target.value)} />
                                    </li>
                                    <li className="login_pass">
                                        <input type="password" placeholder='Enter Password' onChange={(e) => setPass(e.target.value)} />
                                    </li>
                                </ul>
                                <input className="login_btn blue_lgel" value="Login" type="submit" onClick={handleLogin} />
                            </div>
                            <ul className="login_opt_link">
                                <li><a href="#">Forgot Password?</a></li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;