import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '../css/modle.css';
import axios from 'axios';
import lib from '../../config/lib';
import Swal from 'sweetalert2';

const Addsubmodlemsg = ({ isOpen, closeModal }) => {
  const [cat, setCat] = useState('');
  const [subcat, setSubcat] = useState('');
  const [data, setData] = useState([]);

  const handleSubcat = async (event) => {
      event.preventDefault();

      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("catid", cat);
      formData.append("subcat", subcat);
      formData.append("apikey", token);

      try {
          const response = await axios.post(lib.apiURL + '/' + lib.apis.subcat, formData);  
          if (response.data.status === "success") {
              Swal.fire('Success', 'Subcat Added Successfully!', 'success');
          }
      } catch (error) {
          // Handle error if the request failed
          console.log("Some error has occoured kindly check the parameters and try again");
          Swal.fire({
            title: 'opps......',
            text:  'somthing Went Wrong',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Confirm',
        });
      }
  }

    useEffect(() => {
      handlesubcatdisplay();
    }, []);

  const handlesubcatdisplay = async () => {

      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("apikey", token);

      try {
          const response = await axios.post(lib.apiURL + '/' + lib.apis.catdisplay, formData);
          setData(response.data);
      } catch (error) {
          // Handle error if the request failed
          console.log("Some error has occoured kindly check the parameters and try again");
      }
  }
  
  return (
    <div className={`modal ${isOpen ? 'open' : ''}`}>
      <div className="modal-content">
        <span className="close" onClick={closeModal}>&times;</span>
        <h4>Add Sub Category</h4>
        <hr />
        <div class="form1">
          <label for="category">Select Category:</label>
          <select id="category" name="category" onChange={(e) => setCat(e.target.value)}>
          <option disabled selected>Select Cat</option>
          {data && data.map((item) => (
            <option value={item.catid}>{item.cat}</option>
          ))}
          </select>
        </div>
        <Form>
          <Form.Group className="mb-3" controlId="formGroupEmail">
            <Form.Label>Write Sub Category Name</Form.Label>
            <Form.Control type="text" placeholder="Write Category" onChange={(e) => setSubcat(e.target.value)} />
          </Form.Group>
          <Button onClick={handleSubcat} >Submit Sub Category</Button>
        </Form>
      </div>
    </div>
  );
};

export default Addsubmodlemsg;
