import React, { useState } from 'react';
import Modal from './JobModalView';
import '../css/modle.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';


function JobModal({id, jobno, jobform}) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <span className="App">
            <Button variant="success" className="card-button" onClick={openModal} >update Details</Button>
            <Modal isOpen={isModalOpen} closeModal={closeModal} jobno={jobno} id={id} jobform={jobform} />
        </span>
    );
}

export default JobModal;
