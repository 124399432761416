import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Dies from '../../assest/images/dies.jpg'
import DuplexSelected from './DuplexSelected'
import DuplexSelect from './DuplexSelect'
import { useSelector } from 'react-redux';
import axios from 'axios';
import lib from '../../config/lib';
import HANDLEBOXSNAPLOCKTRIPLELOCK_Coro from '../../assest/images/HANDLEBOXSNAPLOCKTRIPLELOCK_Coro.jpg'
import Crashlockduplex from '../../assest/images/crashlockduplex.png'
import Reversetuckduplex from '../../assest/images/Reversetuckduplex.jpg'
import Snaplockduplex from '../../assest/images/Snaplockduplex.jpg'
import Topbottomrodashape from '../../assest/images/Topbottomrodashape.jpg'
import HangingBox from '../../assest/images/HangingBox.jpg'
import FoldingboxdoubleEdgeFolding from '../../assest/images/FoldingboxdoubleEdgeFolding.jpg'
import Foldingbox from '../../assest/images/Foldingbox.jpg'
import RolloverDuplex from '../../assest/images/RolloverDuplex.jpg'
import SLEEVE from '../../assest/images/SLEEVE.jpg'
import Smallflap from '../../assest/images/Smallflap.jpg'
import TRAY from '../../assest/images/TRAY.jpg'
import Bigflap from '../../assest/images/Bigflap.jpg'
import Cakebox from '../../assest/images/Cakebox.jpg'
import CRASH_LOCK_CORRUGATED_Coro from '../../assest/images/CRASH_LOCK_CORRUGATED_Coro.png'
import FULL_CARTONDOUBLERRULE_Coro from '../../assest/images/FULL_CARTONDOUBLERRULE_Coro.jpg'
import FULL_CARTON_SINGLE_RULE_Coro from '../../assest/images/FULL_CARTON_SINGLE_RULE_Coro.jpg'
import REVERSETUCKCORRUGATEDCoro from '../../assest/images/REVERSETUCKCORRUGATEDCoro.jpg'
import ECONOMICAL_FOLDING_CORRUGATION_Coro from '../../assest/images/ECONOMICAL_FOLDING_CORRUGATION_Coro.jpg'
import HANGINGBOXCoro from '../../assest/images/HANGINGBOXCoro.jpg'
import TruntypeCoro from '../../assest/images/TruntypeCoro.jpg'
import SNAPLOCKHALF_plus_HALFCoro from '../../assest/images/SNAPLOCKHALF_plus_HALFCoro.jpg'
import SNAPLOCKDOUBLEWITHTONGUECoro from '../../assest/images/SNAPLOCKDOUBLEWITHTONGUECoro.jpg'
import Loading from '../../common/Loading';
import Button from 'react-bootstrap/Button';

const ConsumableJobView = ({ isOpen, closeModal, handleOrderidsend, handleSendsid, machinetype }) => {
    const cartProducts = useSelector(state => state.cart);
    const [loading, setLoading] = useState(false);
    const [jobno, setjobno] = useState('');
    const [maincat, setMaincat] = useState('');
    const [quality, setQuality] = useState('');
    const [sheetthickness, setSheetthickness] = useState('');
    const [boxstyle, setBoxstyle] = useState('');
    const [woodquality, setWoodQuality] = useState('');
    const [woodsize, setWoodSize] = useState('');
    const [punching, setPunching] = useState('');
    const [length, setLenth] = useState('');
    const [weight, setWeight] = useState('');
    const [height, setHeight] = useState('');
    const [tuckflap, setTuckflap] = useState('');
    const [glueflap, setGlueflap] = useState('');
    const [lock, setLock] = useState('');
    const [dustflap, setDustflap] = useState('');
    const [numberofups, setNumberofups] = useState('');
    const [sheetsize, setSheetsize] = useState('');
    const [rouletoroule, setRouletoroule] = useState('');
    const [rubbing, setRubbing] = useState('');
    const [coro, setCoro] = useState('');
    const [matrix, setMatrix] = useState('');
    const [stripping, setStripping] = useState('');
    const [pertinax, setPertinax] = useState('');
    const [embossing, setEmbossing] = useState('');
    const [description, setDescription] = useState('');
    const [fulde, setFulde] = useState('');
    const [fuldetype, setFuldetype] = useState('');
    const [thickness, setThickness] = useState('');
    const [rulequality, setRulequality] = useState('');
    const [diequility, setDiequility] = useState('');
    const [punchingthickness, setPunchingthickness] = useState('');
    const [machine, setMachine] = useState('');
    const [machinesize, setMachinesize] = useState('');
    const [material, setMaterial] = useState('');
    const [blisterstyle, setBlisterstyle] = useState('');
    const [cardsize, setCardsize] = useState('');
    const [width, setWidth] = useState('');
    const [bendingmargin, setBendingmargin] = useState('');
    const [numberguide, setNumberguide] = useState('');
    const [collersize, setCollersize] = useState('');
    const [numberdies, setNumberdies] = useState('');
    const [wood, setWood] = useState('');
    const [materialtype, setMaterialtype] = useState('');
    const [plactictype, setPlactictype] = useState('');
    const [rule, setRule] = useState('');
    const [ordertype, setOrdertype] = useState('');
    const [sid, setSid] = useState('');
    const [productimg, setProductImg] = useState('');
    const [status, setStatus] = useState('');
    const [date, setDate] = useState('');

    const [designerimg, setDesignerImg] = useState('');

    const [timehk, setTime] = useState('');
    const [jobmode, setJobmode] = useState('');
    const [jobname, setJobname] = useState('');
    const [custname, setcustname] = useState('');

    const [jpgimg, setJpgimg] = useState('');

    // useEffect(() => {
    //     handleQuationDisplay();
    // }, []);

    const handleQuationDisplay = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handleOrderidsend);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.quationjobdisplay, formData);
            if (response.data.status === "success") {
                setjobno(response.data.jobno);
                setMaincat(response.data.setmaincat);
                setQuality(response.data.quality);
                setSheetthickness(response.data.sheetthickness);
                setBoxstyle(response.data.boxstyle);
                setWoodQuality(response.data.woodquality);
                setWoodSize(response.data.woodsize);
                setPunching(response.data.punching);
                setLenth(response.data.lenth);
                setWeight(response.data.weight);
                setHeight(response.data.height);
                setTuckflap(response.data.tuckflap);
                setGlueflap(response.data.glueflap);
                setLock(response.data.lock);
                setDustflap(response.data.dustflap);
                setNumberofups(response.data.numberofups);
                setSheetsize(response.data.sheetsize);
                setRouletoroule(response.data.rouletoroule);
                setRubbing(response.data.rubbing);
                setCoro(response.data.coro);
                setMatrix(response.data.matrix);
                setStripping(response.data.stripping);
                setPertinax(response.data.pertinax);
                setEmbossing(response.data.embossing);
                setDescription(response.data.description);
                setFulde(response.data.fulde);
                setFuldetype(response.data.fuldetype);
                setThickness(response.data.thickness);
                setRulequality(response.data.rulequality);
                setDiequility(response.data.diequility);
                setPunchingthickness(response.data.punchingthickness);
                setMachine(response.data.machine);
                setMachinesize(response.data.machinesize);
                setMaterial(response.data.material);
                setBlisterstyle(response.data.blisterstyle);
                setCardsize(response.data.cardsize);
                setWidth(response.data.width);
                setBendingmargin(response.data.bendingmargin);
                setNumberguide(response.data.numberguide);
                setCollersize(response.data.collersize);
                setNumberdies(response.data.numberdies);
                setWood(response.data.wood);
                setMaterialtype(response.data.materialtype);
                setPlactictype(response.data.plactictype);
                setRule(response.data.rule);
                setOrdertype(response.data.ordertype);
                setSid(response.data.sid);
                setProductImg(response.data.productimg);
                setStatus(response.data.tracker);
                setDate(response.data.date);
                setTime(response.data.time);
                setJobmode(response.data.jobmode);
                setJobname(response.data.jobname);
                setcustname(response.data.custname);
                setLoading(false);
                handleDesignerimg();
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleDesignerimg = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handleOrderidsend);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.designerimg, formData);
            if (response.data.status == "success") {
                setDesignerImg(response.data.designerimg);
                setJpgimg(response.data.jpgimg);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-contente">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>View job</h4>
                <hr />
                <div className='tableview newordertable'>
                    <Table striped="columns">
                        <thead>
                            <tr>
                                <td colspan="8" className='text-center invoce-tittle'>View Job Card</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={2}>Job no: <span className='ms-3'>{jobno}</span></td>
                                <td colSpan={2}>Customer name: <span className='ms-3'>{custname}</span></td>
                                <td colSpan={2}>Date: <span className='ms-3'>{date}</span></td>
                                <td colSpan={2}>Time: <span className='ms-3'>{timehk}</span></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Job mode: <span className='ms-3'>{jobmode}</span></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Job name: <span className='ms-3'>{jobname}</span></td>
                                <td colSpan={2}>Customer code: <span className='ms-3'>{sid}</span></td>
                                <td colSpan={2}>Customer Image: <span className='ms-3'><a href={lib.imgUrl + '/' + productimg} target="_blank" >View Image</a></span></td>
                                <td colSpan={2}>Designer Image: <span className='ms-3'><a href={lib.imgUrl + '/' + designerimg} target="_blank" >View Image</a></span></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Main Category:<span className='ms-3'>{maincat}</span></td>
                                <td colSpan={2}>Quality:<span className='ms-3'>{quality}</span> </td>
                                <td colSpan={2}>Length: <span className='ms-3'>{length}</span></td>
                                <td colSpan={2}>Weight:<span className='ms-3'>{weight}</span> </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Number guide:<span className='ms-3'>{numberguide}</span></td>
                                <td colSpan={2}>Coller size:<span className='ms-3'>{collersize}</span></td>
                                <td colSpan={2}>Height:<span className='ms-3'>{height}</span></td>
                                {stripping === 'Yes' ? (
                                    <td colSpan={2}>Stripping:<span className='ms-3'> <input class="form-check-input" type="checkbox" id="checkboxNoLabel" disabled checked /></span></td>
                                ) : (
                                    <td colSpan={2}>Stripping:<span className='ms-3'> <input class="form-check-input" type="checkbox" id="checkboxNoLabel" disabled /></span></td>
                                )}
                            </tr>
                            <tr>
                                <td colSpan={2}>Number dies:<span className='ms-3'>{numberdies}</span></td>
                                {/* <td colSpan={2}>Wood:<span className='ms-3'>{wood}</span></td> */}
                                <td colSpan={2}>Description:<span className='ms-3'>{description}</span></td>
                                {pertinax === 'Yes' ? (
                                    <td colSpan={2}>Pertinax:<span className='ms-3'><input class="form-check-input" type="checkbox" id="checkboxNoLabel" disabled checked /></span></td>
                                ) : (
                                    <td colSpan={2}>Pertinax:<span className='ms-3'><input class="form-check-input" type="checkbox" id="checkboxNoLabel" disabled /></span></td>
                                )}
                            </tr>
                            <tr>
                                <td colSpan={2}>Status:<span className='ms-3'>{status}</span></td>
                                <td colSpan={2}>Priting Machine Data:<span className='ms-3'><DuplexSelected handleSendsid={handleSendsid} machinetype={machinetype} /></span></td>
                                <td colSpan={2}>Width:<span className='ms-3'>{width}</span></td>
                                {embossing === 'Yes' ? (
                                    <td colSpan={2}>Embossing:<span className='ms-3'><input class="form-check-input" type="checkbox" id="checkboxNoLabel" disabled checked /></span></td>
                                ) : (
                                    <td colSpan={2}>Embossing:<span className='ms-3'><input class="form-check-input" type="checkbox" id="checkboxNoLabel" disabled /></span></td>
                                )}

                            </tr>
                            <tr>
                                <td colSpan={2}>Machine Data:<span className='ms-3'><DuplexSelect handleSendsid={handleSendsid} machinetype={machinetype} /></span></td>
                                <td colSpan={2}>Coro:<span className='ms-3'>{coro}</span></td>
                                <td colSpan={2}>Bending margin:<span className='ms-3'>{bendingmargin}</span></td>
                                {rubbing === 'Yes' ? (
                                    <td colSpan={2}>Rubbing:<span className='ms-3'> <input class="form-check-input" type="checkbox" id="checkboxNoLabel" disabled checked /></span></td>
                                ) : (
                                    <td colSpan={2}>Rubbing:<span className='ms-3'> <input class="form-check-input" type="checkbox" id="checkboxNoLabel" disabled /></span></td>
                                )}

                            </tr>
                            <tr>
                                <td colSpan={2}>Material type:<span className='ms-3'>{materialtype}</span></td>
                                <td colSpan={2}>Plactic type:<span className='ms-3'>{plactictype}</span></td>
                                <td colSpan={2}> Sheet thickness:<span className='ms-3'>{sheetthickness}</span></td>
                                {matrix === 'Yes' ? (
                                    <td colSpan={2}>Matrix:<span className='ms-3'> <input class="form-check-input" type="checkbox" id="checkboxNoLabel" disabled checked /></span></td>
                                ) : (
                                    <td colSpan={2}>Matrix:<span className='ms-3'> <input class="form-check-input" type="checkbox" id="checkboxNoLabel" disabled /></span></td>
                                )}
                            </tr>
                            <tr>
                                <td colSpan={2}>Wood size:<span className='ms-3'>{woodsize}</span></td>
                                <td colSpan={2}>Wood quality:<span className='ms-3'>{woodquality}</span></td>
                                {boxstyle === "/static/media/welcome.c6609d15759aa6dedadb.jpg" ? (
                                    <td colSpan={2}>Box style:<span className='ms-3'>{'N/A'}</span></td>
                                ) : (
                                    <td colSpan={2}>Box style:<span className='ms-3'>{boxstyle}</span></td>
                                )}
                                {/* <td colSpan={2}>Corrugration:<span className='ms-3'> <input class="form-check-input" type="checkbox" id="checkboxNoLabel" value="" aria-label="..." /></span></td> */}
                            </tr>

                        </tbody>
                    </Table>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='row border-cus p-2'>

                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h4 className='mt-0'>
                                        Flute:
                                    </h4>
                                </span>
                                <span> <h4 className='mt-0'>{fulde}</h4></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Flute type:
                                    </h3>
                                </span>
                                <span> <h4 className='mt-0'>{fuldetype}</h4></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Thickness:
                                    </h3>
                                </span>
                                <span> <h4 className='mt-0'>{thickness}</h4></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Rule quality:
                                    </h3>
                                </span>
                                <span> <h4 className='mt-0'>{rulequality}</h4></span>
                            </div>
                            {/* <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Die quility:
                                    </h3>
                                </span>
                                <span> <h4 className='mt-0'>{diequility}</h4></span>
                            </div> */}
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Punching thickness:
                                    </h3>
                                </span>
                                <span> <h4 className='mt-0'>{punchingthickness}</h4></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Machine:
                                    </h3>
                                </span>
                                <span> <h4 className='mt-0'>{machine}</h4></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Machine size:
                                    </h3>
                                </span>
                                <span> <h4 className='mt-0'>{machinesize}</h4></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Material:
                                    </h3>
                                </span>
                                <span> <h4 className='mt-0'>{material}</h4></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Blister style:
                                    </h3>
                                </span>
                                <span> <h4 className='mt-0'>{blisterstyle}</h4></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Card size:
                                    </h3>
                                </span>
                                <span> <h4 className='mt-0'>{cardsize}</h4></span>
                            </div>

                        </div>
                        <div className='row border-cus p-2'>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Punching:
                                    </h3>
                                </span>
                                <span> <h4 className='mt-0'>{punching}</h4></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Tuck flap:
                                    </h3>
                                </span>
                                <span> <h4 className='mt-0'>{tuckflap}</h4></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Glue flap:
                                    </h3>
                                </span>
                                <span> <h4 className='mt-0'>{glueflap}</h4></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Lock:
                                    </h3>
                                </span>
                                <span> <h4 className='mt-0'>{lock}</h4></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Dust flap:
                                    </h3>
                                </span>
                                <span> <h4 className='mt-0'>{dustflap}</h4></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Number of ups:
                                    </h3>
                                </span>
                                <span> <h4 className='mt-0'>{numberofups}</h4></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Sheet size:
                                    </h3>
                                </span>
                                <span> <h4 className='mt-0'>{sheetsize}</h4></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Rule to rule:
                                    </h3>
                                </span>
                                <span> <h4 className='mt-0'>{rouletoroule}</h4></span>
                            </div>
                        </div>
                        <div className='row border-cus p-2'>

                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Rule:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{rule}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Order type:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{ordertype}</h3></span>
                            </div>
                        </div>
                        <Button onClick={handleQuationDisplay}>Fetch Data</Button>
                    </div>
                    <div className='col-lg-8'>
                        <img src={lib.imgUrl + '/' + jpgimg} alt='product images' className='img-fluid' />

                        {/* {boxstyle === "Folding Box" ? (
                            <img src={Foldingbox} alt='product images' className='img-fluid' />
                        ) : boxstyle === "Reversetuck" ? (
                            <img src={Reversetuckduplex} alt='product images' className='img-fluid' />
                        ) : boxstyle === "Crash Lock" ? (
                            <img src={Crashlockduplex} alt='product images' className='img-fluid' />
                        ) : boxstyle === "Snap Lock" ? (
                            <img src={Snaplockduplex} alt='product images' className='img-fluid' />
                        ) : boxstyle === "Top Bottom" ? (
                            <img src={Topbottomrodashape} alt='product images' className='img-fluid' />
                        ) : boxstyle === "Rollover" ? (
                            <img src={RolloverDuplex} alt='product images' className='img-fluid' />
                        ) : boxstyle === "Hanging Box" ? (
                            <img src={HangingBox} alt='product images' className='img-fluid' />
                        ) : boxstyle === "Cake Box" ? (
                            <img src={Cakebox} alt='product images' className='img-fluid' />
                        ) : boxstyle === "Big flap" ? (
                            <img src={Bigflap} alt='product images' className='img-fluid' />
                        ) : boxstyle === "Small flap" ? (
                            <img src={Smallflap} alt='product images' className='img-fluid' />
                        ) : boxstyle === "Folding Sweet Box" ? (
                            <img src={''} alt='product images' className='img-fluid' />
                        ) : boxstyle === "Tray" ? (
                            <img src={TRAY} alt='product images' className='img-fluid' />
                        ) : boxstyle === "Sleeve" ? (
                            <img src={SLEEVE} alt='product images' className='img-fluid' />
                            ) : boxstyle === "/static/media/welcome.c6609d15759aa6dedadb.jpg" ? (
                                <h3>N/A</h3>
                        ) : (
                            <></>
                        )} */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConsumableJobView;
