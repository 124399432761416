import React, { useState } from 'react';
import Modal from './EditRateView';
import '../css/modle.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BiSolidEdit } from 'react-icons/bi'

function EditRate({ id, handleRate }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="App">
            <BiSolidEdit size={20} onClick={openModal} />
            <Modal isOpen={isModalOpen} closeModal={closeModal} id={id} handleRate={handleRate} />
        </div>
    );
}

export default EditRate;
