import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '../css/modle.css';
import axios from 'axios';
import lib from '../../config/lib';
import Swal from 'sweetalert2';
import Loading from '../../common/Loading';

const AssginEmployeeView = ({ isOpen, closeModal, handleEmployerjobno }) => {
    const [type, setType] = useState('');
    const [sid, setSid] = useState('');
    const [loading, setLoading] = useState(false);
    const [employ, setEmploy] = useState([]);
    const [data, setData] = useState([]);

    const handleAssign = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', type);
        formData.append('sid', sid);
        formData.append('jobno', handleEmployerjobno);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.assignejob, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Job Assigned to user!', 'success');
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    useEffect(() => {
        handlesubcatdisplay();
    }, []);

    const handlesubcatdisplay = async () => {

        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.fetchrole, formData);
            setData(response.data)
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleFethelploy = async (e) => {
        setSid(e.target.value);
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        formData.append('type', type);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.employfethbyid, formData);
            setEmploy(response.data)
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Assign job</h4>
                <hr />

                <div class=" mt-3">
                    <label>Select Role</label>
                    <select class="form-select" aria-label="Default select example" onChange={(e) => setType(e.target.value)}>
                        <option selected>Select Role</option>
                        {data && data.map((item) => (
                            <option value={item.rolename}>{item.rolename}</option>
                        ))}
                    </select>
                </div>

                <div class=" mt-3">
                    <label>Select Employee</label>
                    <select class="form-select" aria-label="Default select example" onClick={handleFethelploy} >
                        <option selected>Select Employee</option>
                        {employ && employ.map((item) => (
                            <option value={item.id}>{item.name}</option>
                        ))}
                    </select>
                </div>

                <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleAssign} >Assign</button></div>
            </div>

        </div>
    );
};

export default AssginEmployeeView;
