import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2'
import axios from 'axios';
import lib from "../../config/lib";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Loading from "../../common/Loading";

const MachineData = () => {
    function toggleInput(e) {
        setMt(e.target.value);
        var selectElement = document.getElementById("toggleSelect");
        var mn = document.getElementById("mn");
        var maxpa = document.getElementById("maxpa");
        var maxsh = document.getElementById("maxsh");
        var minsh = document.getElementById("minsh");
        var maxshsze = document.getElementById("maxshsze");

        // die cutting machine starts hear

        var machoption = document.getElementById("machoption");
        var meserment = document.getElementById("meserment");
        var machinename = document.getElementById("machinename");
        var innerchase = document.getElementById("innerchase");
        var outerchase = document.getElementById("outerchase");
        var woodsize = document.getElementById("woodsize");
        var gripersize = document.getElementById("gripersize");


        if (selectElement.value === "printing machine") {
            mn.style.display = "block";
            maxpa.style.display = "block";
            maxsh.style.display = "block";
            minsh.style.display = "block";
            maxshsze.style.display = "block";

            // die cutting starts hear

            machoption.style.display = "none";
            meserment.style.display = "block";
            machinename.style.display = "none";
            innerchase.style.display = "none";
            outerchase.style.display = "none";
            woodsize.style.display = "none";
            gripersize.style.display = "none";

        } else if (selectElement.value === "die cutting machine") {
            mn.style.display = "none";
            maxpa.style.display = "none";
            maxsh.style.display = "none";
            minsh.style.display = "none";
            maxshsze.style.display = "none";

            // die cutting starts hear

            machoption.style.display = "block";
            meserment.style.display = "none";
            machinename.style.display = "none";
            innerchase.style.display = "none";
            outerchase.style.display = "none";
            woodsize.style.display = "none";
            gripersize.style.display = "none";

        } else {
            mn.style.display = "none";
            maxpa.style.display = "none";
            maxsh.style.display = "none";
            minsh.style.display = "none";
            maxshsze.style.display = "none";

            // die cutting starts hear

            machoption.style.display = "none";
            meserment.style.display = "none";
            machinename.style.display = "none";
            innerchase.style.display = "none";
            outerchase.style.display = "none";
            woodsize.style.display = "none";
            gripersize.style.display = "none";

        }
    }

    function amp(e) {
        setMtd(e.target.value);
        var machinetype = document.getElementById("machinetype");
        var machoption = document.getElementById("machoption");
        var meserment = document.getElementById("meserment");
        var machinename = document.getElementById("machinename");
        var innerchase = document.getElementById("innerchase");
        var outerchase = document.getElementById("outerchase");
        var woodsize = document.getElementById("woodsize");
        var gripersize = document.getElementById("gripersize");

        if (machinetype.value === "Victoria | Manual Punching") {
            machoption.style.display = "block";
            meserment.style.display = "block";
            machinename.style.display = "block";
            innerchase.style.display = "block";
            outerchase.style.display = "block";

            woodsize.style.display = "none";
            gripersize.style.display = "none";

        } else if (machinetype.value === "Bobst | Automatic") {
            machoption.style.display = "block";
            meserment.style.display = "block";
            machinename.style.display = "block";
            innerchase.style.display = "block";
            outerchase.style.display = "block";

            woodsize.style.display = "block";
            gripersize.style.display = "block";

        } else {
            machoption.style.display = "none";
            meserment.style.display = "none";
            machinename.style.display = "none";
            innerchase.style.display = "none";
            outerchase.style.display = "none";
            woodsize.style.display = "none";
            gripersize.style.display = "none";

        }
    }

    const [mt, setMt] = useState('');
    const [mn, setMn] = useState('');
    const [mpsa, setMpsa] = useState('');
    const [ms, setMs] = useState('');
    const [mis, setMis] = useState('');
    const [mss, setMss] = useState('');
    const [mtd, setMtd] = useState('');
    const [messerment, setMesserment] = useState();
    const [nom, setNom] = useState('');
    const [innerchase, setInnerChase] = useState('');
    const [outterchase, setOutterchase] = useState('');
    const [woodsize, setWoodsize] = useState('');
    const [gripsize, setGripsize] = useState('');
    const [loading, setLoading] = useState(false);

    const [cname, setCname] = useState('');

    const handleMachineData = async (event) => {
        event.preventDefault();
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('mt', mt);
        formData.append('mn', mn);
        formData.append('mpsa', mpsa);
        formData.append('ms', ms);
        formData.append('mis', mis);
        formData.append('mss', mss);
        formData.append('mtd', mtd);
        formData.append('messerment', messerment);
        formData.append('nom', nom);
        formData.append('innerchase', innerchase);
        formData.append('outterchase', outterchase);
        formData.append('woodsize', woodsize);
        formData.append('gripsize', gripsize);
        formData.append("cname", cname);
        formData.append('apikey', token);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.entermachinedata, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Machine Data Saved successfully!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        handleCompanyName();
    }, []);

    const [data, setData] = useState([]);

    const handleCompanyName = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.userfethdata, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div id="theme-default" class="full_block">
            <div id="content">
                <Loading loading={loading} />
                <div class="fullbody">
                    <div class="widget_wrap">
                        <div class="widget_content">
                            <form class="form_container ">
                                <fieldset>
                                    <div className="row justify-content-center mt-3">

                                        <div className="col-md-4">
                                            <div class="col-md-12 mb-2">
                                                <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setCname(e.target.value)}>
                                                    <option selected disabled>Select User</option>
                                                    {data && data.map((item) => (
                                                        <option value={item.cname}>{item.cname}</option>
                                                    ))}
                                                </Form.Select>
                                            </div>
                                            <div class="col-md-12 mb-3">
                                                <label for="toggleSelect">Select Machine Type </label> <br />
                                                <select class="form-select" aria-label="Default select example" id="toggleSelect" name="mt" onChange={toggleInput} required>
                                                    <option selected disabled>Select Machine type....</option>
                                                    <option value="printing machine">Printing machine</option>
                                                    <option value="die cutting machine">Die cutting machine</option>
                                                </select>
                                            </div>
                                            <span class="clear"></span>
                                            <div id='mn' style={{ display: 'none' }}>
                                                <div class="col-md-12">
                                                    <label class="field_title">Machine Name</label>
                                                    <div class="form_input">
                                                        <div class=" alpha">
                                                            <input type="text" name="mn" onChange={(e) => setMn(e.target.value)} />
                                                        </div>
                                                        <span class="clear"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='maxpa' style={{ display: 'none' }}>
                                                <div class="col-md-12">
                                                    <label class="field_title">Max print sheet area</label>
                                                    <div class="form_input">
                                                        <div class=" alpha">
                                                            <input type="text" name="mpsa" onChange={(e) => setMpsa(e.target.value)} />
                                                        </div>
                                                        <span class="clear"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='maxsh' style={{ display: 'none' }}>
                                                <div class="col-md-12">
                                                    <label class="field_title">Max sheet</label>
                                                    <div class="form_input">
                                                        <div class=" alpha">
                                                            <input type="text" name="ms" onChange={(e) => setMs(e.target.value)} />
                                                        </div>
                                                        <span class="clear"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='minsh' style={{ display: 'none' }}>
                                                <div class="col-md-12">
                                                    <label class="field_title">Min Sheet</label>
                                                    <div class="form_input">
                                                        <div class=" alpha">
                                                            <input type="text" name="mis" onChange={(e) => setMis(e.target.value)} />
                                                        </div>
                                                        <span class="clear"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='maxshsze' style={{ display: 'none' }}>
                                                <div class="col-md-12">
                                                    <label class="field_title">Griper</label>
                                                    <div class="form_input">
                                                        <div class=" alpha">
                                                            <input type="text" name="mss" onChange={(e) => setMss(e.target.value)} />
                                                        </div>
                                                        <span class="clear"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='machoption' style={{ display: 'none' }}>
                                                <div class="col-md-12 mb-2">
                                                    <label class="field_title" for="amp">Select Die machine type</label>
                                                    <select class="form-select" aria-label="Default select example" id="machinetype" name="messerment" onChange={amp}>
                                                        <option selected disabled>Select Measerments</option>
                                                        <option value="Victoria | Manual Punching">Victoria | Manual Punching</option>
                                                        <option value="Bobst | Automatic">Bobst | Automatic</option>
                                                    </select>

                                                    <span class="clear"></span>
                                                </div>
                                            </div>
                                            <div id='meserment' style={{ display: 'none' }}>
                                                <div class="col-md-12 mb-2">
                                                    <label class="field_title">Select Measerments</label>
                                                    <select class="form-select" aria-label="Default select example" id="toggleSelect" name="messerment" onChange={(e) => setMesserment(e.target.value)}>
                                                        <option selected disabled>Select Measerments</option>
                                                        <option value="mm">mm</option>
                                                        <option value="inch">inch</option>
                                                    </select>
                                                    <span class="clear"></span>
                                                </div>
                                            </div>
                                            <div id='machinename' style={{ display: 'none' }}>
                                                <div class="col-md-12">
                                                    <label class="field_title">Name of machine</label>
                                                    <div class="form_input">
                                                        <div class=" alpha">
                                                            <input type="text" name="nom" onChange={(e) => setNom(e.target.value)} />
                                                        </div>
                                                        <span class="clear"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='innerchase' style={{ display: 'none' }}>
                                                <div class="col-md-12">
                                                    <label class="field_title">inner chase</label>
                                                    <div class="form_input">
                                                        <div class=" alpha">
                                                            <input type="text" name="innerchase" onChange={(e) => setInnerChase(e.target.value)} />
                                                        </div>
                                                        <span class="clear"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='outerchase' style={{ display: 'none' }}>
                                                <div class="col-md-12">
                                                    <label class="field_title">outer chase</label>
                                                    <div class="form_input">
                                                        <div class=" alpha">
                                                            <input type="text" name="outterchase" onChange={(e) => setOutterchase(e.target.value)} />
                                                        </div>
                                                        <span class="clear"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='woodsize' style={{ display: 'none' }}>
                                                <div class="col-md-12">
                                                    <label class="field_title">min wood size</label>
                                                    <div class="form_input">
                                                        <div class=" alpha">
                                                            <input type="text" name="woodsize" onChange={(e) => setWoodsize(e.target.value)} />
                                                        </div>
                                                        <span class="clear"></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id='gripersize' style={{ display: 'none' }}>
                                                <div class="col-md-12">
                                                    <label class="field_title">notch size</label>
                                                    <div class="form_input">
                                                        <div class=" alpha">
                                                            <input type="text" name="gripsize" onChange={(e) => setGripsize(e.target.value)} />
                                                        </div>
                                                        <span class="clear"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>
                                <ul>
                                    <li>
                                        <div class="form_grid_12">
                                            <div class="form_input">
                                                <input type="submit" class="btn_small btn_blue" value="save data" onClick={handleMachineData} />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MachineData;