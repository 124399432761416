import React, { Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assest/css/custom.css';
import './assest/css/gradient.css';
import Header from './common/header';
import Sidemenu from './common/sidemenu';
import clasess from './common/css/Content.module.css';
import './components/stockModal/stockmodal';
import './components/css/modle.css';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CustomOrderTable from './components/CustomOrderTable'
import QuatationTable from './components/QuatationTable'
import Keylinetable from './components/Keylinetable'
import StandardTable from './components/StandardTable'
import ConsumableTable from './components/ConsumableTable'
import SampleTable from './components/SampleTable'
import KeyLineNew from './components/KeylineTableNew';
import MarkettingTable from './components/MarkettingTable';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Myorder() {
    return (
        <Fragment>
            <div className={clasess.container1}>
                <Header />
                <Sidemenu />
                <div>
                    <div className={clasess.container}>
                        <div style={{ width: '100%', height: 'auto', marginTop: '20px' }}>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <h3 className="ms-2">Jobs</h3>
                                </div>
                              <div className='col-lg-2 ms-auto text-end'>
                              <Button><Link to ='/CustomerOrder' className='text-white'>Customer Order status </Link> </Button>
                              </div>
                            </div>
                            <hr />

                            <Tabs
                                defaultActiveKey="New Order"
                                transition={false}
                                id="noanim-tab-example"
                                className="mb-3"
                                style={{ fontSize: '20px' }}
                            >
                                
                                <Tab eventKey="New Order" title="New Order">
                                    <CustomOrderTable />
                                </Tab>
                                <Tab eventKey="Rule Change" title="Rule Change">
                                    <Keylinetable />
                                </Tab>
                                <Tab eventKey="Consumable" title="Consumable">
                                    <ConsumableTable />
                                </Tab>
                                <Tab eventKey="Quatation" title="Quotation">
                                    <QuatationTable />
                                </Tab>
                                <Tab eventKey="Keyline" title="Keyline">
                                    <KeyLineNew />
                                </Tab>
                                <Tab eventKey="Sample" title="Sample">
                                    <SampleTable />
                                </Tab>
                                <Tab eventKey="Marketting" title="Marketing">
                                    <MarkettingTable />
                                </Tab>
                                <Tab eventKey="Standard" title="Standard">
                                    <StandardTable />
                                </Tab>
                            </Tabs>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Myorder    