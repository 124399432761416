import React, { useState } from 'react';
import Modal from './DuplexSelectMessage';
import '../css/modle.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';

function DuplexSelect({handleSendsid, machinetype}) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="App">
            <Link variant="success" onClick={openModal} >View all</Link>
            <Modal isOpen={isModalOpen} closeModal={closeModal} handleSendsid={handleSendsid} machinetype={machinetype}/>
        </div>
    );
}

export default DuplexSelect;
