import React, { useState } from 'react';
import Modal from './AddtionalDetailMessage';
import '../css/modle.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';


function AddtionalDetails({ handleAdationaljobno, jobform }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <span className="App">
            <Button variant="success" className="card-button" onClick={openModal} >Update details</Button>
            <Modal isOpen={isModalOpen} closeModal={closeModal} handleAdationaljobno={handleAdationaljobno} jobform={jobform} />
        </span>
    );
}

export default AddtionalDetails;
