import React, { useEffect, useState } from "react";
import axios from 'axios';
import lib from "./config/lib";
import Swal from "sweetalert2";
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { RiDeleteBin4Fill } from "react-icons/ri"
import Loading from './common/Loading'

const Standerdstabledisplay = () => {

    const [data, setData] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState([]);
    const itemsPerPage = 10;
    // const [data, setData] = useState([]);
    // Calculate the total number of pages
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData([]);

    useEffect(() => {
        handleSample();
    }, []);

    const handleSample = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('subcat', 'All');

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.standerdfethdisplay, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }


    const handleDelete = async (id) => {
        const token = localStorage.getItem('token');
        setLoading(true);
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', id);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.deletestanderdlist, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Deleted Successfully!', 'success');
                handleSample();
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Something Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }


    return (
        <div>
            <Loading loading={loading} />
            <Table striped bordered hover >
                <thead>
                    <tr>
                        <th>Product Name</th>
                        <th>Product Description</th>
                        <th>Product Image</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPageData && currentPageData.map((data) => (
                        <tr>
                            <td>{data.productname}</td>
                            <td>{data.productdescription}</td>
                            <td><a href={lib.imgUrl + '/' + data.productimage} target="_blank" >View Image</a></td>
                            <td><RiDeleteBin4Fill size={24} onClick={() => handleDelete(data.id)} /></td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
            />
        </div>
    )
}

export default Standerdstabledisplay;