import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2'
import axios from 'axios';
import lib from '../../config/lib';
import Loading from '../../common/Loading';

const AddJomSampleView = ({ isOpen, closeModal, handleOrdertype }) => {
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [lenth, setLenth] = useState('');
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [description, setDescription] = useState('');
    const [cat, setCat] = useState('');
    // const [cardimg, setCardImg] = useState({ preview: '', data: '' });
    const [boxstyle, setBoxstyle] = useState('');
    // const [image, setImage] = useState('');
    const [sheet, setSheet] = useState('');
    const [customercode, setCustomercode] = useState('');

    const [jobname, setJobname] = useState('');
    const [jobmode, setJobmode] = useState('');

    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the first selected file
        // setImage(e.target.files[0]);
        // setCardImg({ preview: URL.createObjectURL(file), data: file });
        if (file) {
            setSelectedFile(file);
        } else {
            setSelectedFile(null);
        }
    };


    const handleKeyline = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('cat', cat);
        formData.append('boxstyle', boxstyle);
        formData.append('sheetsize', sheet);
        formData.append('lenth', lenth);
        formData.append('width', width);
        formData.append('height', height);
        formData.append('addfile', selectedFile);
        formData.append('cname', companyname);
        formData.append('ordertype', handleOrdertype);
        formData.append('jobname', jobname);
        formData.append('jobmode', jobmode);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.addkeylinejob, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.status === "success") {
                Swal.fire('Success', 'Order Created!', 'success');
                setLoading(false);
                closeModal();
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
                closeModal();
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
            closeModal();
        }
    };

    useEffect(() => {
        handleCompanyName();
    }, []);

    const [data, setData] = useState([]);
    const [companyname, setCompanyName] = useState('N/A');

    const handleCompanyName = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.userfethdata, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }


    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Add job</h4>
                <hr className='mb-0' />
                <div>
                    <div id="form-file-upload" className="beautiful-form mt-3">
                        <div className="upload-container">
                            <input type="file" id="" multiple={false} onChange={handleFileChange} className="border w-100" />
                            <div className='d-flex mt-3'><label>Category</label></div>
                            <Form.Select aria-label="Default select example" id="firstselect" style={{ marginTop: '10px' }} onChange={(e) => setCat(e.target.value)}>
                                <option selected disabled>Select Main Category</option>
                                <option value="Duplex">Duplex</option>
                                <option value="Corrugation">Corrugation</option>
                            </Form.Select>
                            <div className="row">
                                <div className='col-md-12 mt-2'>
                                    <div className='d-flex'><label>Job name</label></div>
                                    <input type="text" id="input-name" placeholder="Enter job name" className='mt-2' onChange={(e) => setJobname(e.target.value)} />
                                </div>
                            </div>
                            <div className='d-flex'><label>Job mode</label></div>
                            <Form.Select aria-label="Select job mode" className='mt-2' onChange={(e) => setJobmode(e.target.value)}>
                                <option selected disabled>Select mode</option>
                                <option value="Whattsapp">Whattsapp</option>
                                <option value="Email">Email</option>
                                <option value="By visit">By visit</option>
                                <option value="By Phone Call">By Phone Call</option>
                            </Form.Select>
                            <div className='d-flex mb-2'><label>Company name</label></div>
                            <Form.Select aria-label="Default select example" id="firstselect" style={{ marginTop: '10px' }} onChange={(e) => setCompanyName(e.target.value)}>
                                <option selected disabled>Select User</option>
                                {data && data.map((item) => (
                                    <option value={item.cname}>{item.cname}</option>
                                ))}
                            </Form.Select>
                            <div className='col-md-12 mt-2 p-0'>
                                <div className='d-flex'><label>Box style</label></div>
                                <input type="text" id="input-name" placeholder="box style" onChange={(e) => setBoxstyle(e.target.value)} />
                            </div>

                            <div className="row">
                                <div className='col-md-3 mt-2'>
                                    <div className='d-flex'><label>Sheet size</label></div>
                                    <input type="text" id="input-name" placeholder="sheet size" onChange={(e) => setSheet(e.target.value)} />
                                </div>
                                <div className='col-md-3 mt-2'>
                                    <div className='d-flex'><label>Lenth</label></div>
                                    <input type="text" id="input-name" placeholder="lenth" onChange={(e) => setLenth(e.target.value)} />
                                </div>
                                <div className='col-md-3 mt-2'>
                                    <div className='d-flex'><label>Width</label></div>
                                    <input type="text" id="input-name" placeholder="width" onChange={(e) => setWidth(e.target.value)} />
                                </div>
                                <div className='col-md-3 mt-2'>
                                    <div className='d-flex'><label>Height</label></div>
                                    <input type="text" id="input-name" placeholder="height" onChange={(e) => setHeight(e.target.value)} />
                                </div>
                            </div>
                            <input type="submit" style={{ marginTop: '15px', clear: 'both' }} value="Complete Your Order" className="upload-button" onClick={handleKeyline} />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default AddJomSampleView;
