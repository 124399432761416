import { React, useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import clasess from './common/css/Content.module.css';
import Header from './common/header';
import Sidemenu from './common/sidemenu';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
// import PaginationSection from './PaginationSection';
import AddAdmin from './components/adminmodal/addAdmin'
import { RiDeleteBin4Fill } from 'react-icons/ri'
// import ReactPaginate from "react-paginate";
import { FcCancel, FcCheckmark } from 'react-icons/fc'
import axios from 'axios';
import lib from './config/lib';
import Swal from 'sweetalert2';
import { BiSolidEdit } from "react-icons/bi";
import Modal from './components/adminmodal/viewAdminrmessage';
import { useDispatch } from 'react-redux';
import { add, remove } from './store/Cartslice';
import Loading from './common/Loading';

const Adminuser = () => {
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleOrder();
    }, []);

    const handleOrder = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.adminuser, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    // Calculate the total number of pages
    // const pageCount = Math.ceil(data.length / itemsPerPage);
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData([]);

    const handleApprove = async (id) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', id);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.adminapprove, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Admin User approved!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleReject = async (id) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', id);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.adminreject, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Admin User Rejected!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleDelete = async (id) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', id);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.admindelete, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'User Deleted Successfully!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (id) => {
        dispatch(add(id));
        setIsModalOpen(true);
    };

    const closeModal = (id) => {
        dispatch(remove(id));
        setIsModalOpen(false);
    };

    return (
        <div>
            <div className={clasess.container1}>
                <Header />
                <Sidemenu />
                <div className={clasess.container}>
                    <Loading loading={loading} />
                    <div className='row mt-3'>
                        <div className='col-lg-10 col-md-8 ms-2'>
                            <h3 >Admin User Details</h3>
                        </div>
                        <div className='col-lg-1 col-md-2'>
                            <AddAdmin />
                        </div>
                    </div>
                    <hr />
                    <OverlayScrollbarsComponent>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>

                                {currentPageData && currentPageData.map((data) => (
                                    <tr key={data.id}>
                                        <td>{data.name}</td>
                                        <td>{data.user}</td>
                                        <td>{data.status}</td>
                                        <td className="d-flex">
                                            <FcCheckmark size={20} onClick={() => handleApprove(data.id)} />
                                            <span className='ms-2'> <FcCancel size={20} onClick={() => handleReject(data.id)} /></span>
                                            <RiDeleteBin4Fill size={20} onClick={() => handleDelete(data.id)} />
                                            <span className='ms-2'>
                                                <BiSolidEdit size={20} variant="success" onClick={() => openModal(data.id)} />
                                                <Modal isOpen={isModalOpen} closeModal={() => closeModal(data.id)} />
                                            </span>
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </Table>
                    </OverlayScrollbarsComponent>

                    {/* <PaginationSection currentPage={currentPage} totalPages={totalPages} paginate={paginate} pageNumbers={pageNumbers}/> */}
                </div>
            </div>
        </div>
    )
}

export default Adminuser;