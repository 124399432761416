import React, { useState } from 'react';
import Modal from './ViewStockView';
import '../css/modle.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';


function ViewStock({ jobno }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <span className="App">
            <Link onClick={openModal}>View Stock</Link>
            <Modal isOpen={isModalOpen} closeModal={closeModal} jobno={jobno} />
        </span>
    );
}

export default ViewStock;
