import React, { useEffect, useRef, useState } from "react";
import Employerheader from "./Employerheader";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import ViewJob from '../components/EmployerJobModal/ViewJob'
import EmployeeViewjob from '../components/jobcardDisplay/EmployeeViewjob'
import dies from './dies.jpg'
import AddtionalDetails from '../components/employerModal/AddtionalDetails'
import Republish from '../components/employerModal/Republish'
import DescritopnWriting from '../components/employerModal/DescritopnWriting'
import Despatch from '../components/employerModal/Despatch'
import AdditionalDetails from '../components/jobcardDisplay/AdditionalDetails'
import ViewDetailsStock from '../components/jobcardDisplay/ViewStock'
import JobModal from '../components/EmployerJobModal/JobModal'
import AssginEmployee from '../components/EmployerJobModal/AssginEmployee'
import BillingModal from '../components/jobcardDisplay/BillingModal'
import axios from 'axios';
import lib from "../config/lib";
import Swal from "sweetalert2";
import Loading from "../common/Loading";
import { useNavigate } from "react-router-dom";


const Dashboard = () => {
    const [time, setTime] = useState(0);
    const [loading, setLoading] = useState(false);
    const [aditional, setAditional] = useState('');
    const [republish, setRepublish] = useState('');
    const [description, setDescription] = useState('');
    const [markiting, setMarkiting] = useState('');
    const [ViewDetails, setViewDetails] = useState('');
    const [billing, setBilling] = useState('');
    const [updatestock, setUpdatestock] = useState('');
    const [asignejob, setAsignejob] = useState('');

    const [priority, setPriority] = useState('');
    const [accept, setAccept] = useState(true);
    const [complete, setComplete] = useState('');
    const [resume, setResume] = useState('');
    const [emergencydrop, setEmergencydrop] = useState('');
    const [holdjob, setHoldjob] = useState('');
    const [onhold, setOnhold] = useState('');
    const [joblenth, setJobLenth] = useState('0');

    const [data, setData] = useState([]);

    const navigate = useNavigate('');

    useEffect(() => {
        handleAccessfeth();
        handleJobFeth();
        return () => clearInterval(id.current);
    }, []);

    let id = useRef();

    function handleTime() {
        id.current = setInterval(() => {
            setTime((prev) => prev + 1);
        }, 1000);
    }

    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    }

    const handleJobFeth = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', false);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.employjobfeth, formData);
            setData(response.data);
            setJobLenth(response.data.length);
            setLoading(false);
            // setPriority(response.data.priority);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleImagedisplay = async (jobnumber, jobfrom) => {
        setLoading(true);
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', jobnumber);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.imagefeth, formData);
            window.open(lib.imgUrl + '/' + response.data.img);
            setLoading(false);
            // setPriority(response.data.priority);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    // AllReady accepted

    const handleJobFethaccept = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', true);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.employjobfeth, formData);
            setData(response.data);
            setAccept('');
            setComplete(true);
            setResume(true);
            setHoldjob(true);
            setEmergencydrop(true);
            setLoading(false);
            // setPriority(response.data.priority);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }


    // hold job

    const handleJobHold = async (jobno) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', jobno);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.holdjobcompleted, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Job Holded Successfully!', 'success');
                setAccept(true);
                setComplete('');
                setResume('');
                setHoldjob('');
                setEmergencydrop('');
                clearInterval(id.current);
                handleJobFeth();
                handleJobFethaccept();
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    // hold job display

    const handleJobholdFeth = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'hold');
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.employjobfeth, formData);
            setData(response.data);
            setLoading(false);
            // setPriority(response.data.priority);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    // employ cancle and republich

    const handleCanclerepublish = async (jobno) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', jobno);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.emergencycancle, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Job Cancled Successfully And Passed To Team!', 'success');
                clearInterval(id.current);
                handleJobFeth();
                setAccept(true);
                setTime(0);
                setComplete('');
                setResume('');
                setHoldjob('');
                setEmergencydrop('');
                setLoading(false);
                // handleJobFethaccept();
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again"); Swal.fire({
                title: 'opps......',
                text: 'somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }



    const handleAccessfeth = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.employdisplay, formData);
            setAditional(response.data.additionaldetails);
            setRepublish(response.data.republishorder);
            setDescription(response.data.descriptionwriting);
            setMarkiting(response.data.marketingaccess);
            setBilling(response.data.billing);
            setViewDetails(response.data.viewdetails);
            setUpdatestock(response.data.updatestock);
            setAsignejob(response.data.asignejob);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleAcceptjob = async (jobno) => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', jobno);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.employacceptjob, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Job Accepted!', 'success');
                handleTime();
                setAccept('');
                setComplete(true);
                setResume(true);
                setHoldjob(true);
                setEmergencydrop(true);
                handleJobFethaccept();
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleComplete = async (jobno) => {
        const token = localStorage.getItem('token');
        var timetaken = formatTime(time);
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', jobno);
        formData.append('time', timetaken);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.employjobcomplete, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Job completed Successfully!', 'success');
                setAccept(true);
                setComplete('');
                handleJobFeth();
                clearInterval(id.current);
                setTime(0);
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }


    return (
        <div id="theme-default" className="full_block">
            <Employerheader />
            <div id="content">
                <Loading loading={loading} />
                <div className="fullbody">
                    <div className="row justify-content-between mt-2">
                        <div className="col-md-5">
                            {markiting ? (
                                <span>
                                    <Despatch />
                                    <span style={{ marginLeft: '5px', marginTop: '10px', fontSize: '20px' }}> Total Jobs:- {joblenth}</span>
                                </span>
                            ) : (
                                <>
                                    <span style={{ marginTop: '10px', fontSize: '20px' }}> Total Jobs:- {joblenth}</span>
                                </>
                            )}
                        </div>
                        <div className="col-md-3">
                            <div style={{ marginTop: '10px', fontSize: '30px' }}>
                                {formatTime(time)}
                            </div>
                        </div>
                        <div className="col-md-3 d-flex">
                            <Button variant="success" onClick={handleJobFethaccept}>Already Accepted jobs</Button>
                            <div className="col-md-3 d-flex">
                                <Button variant="success" onClick={handleJobholdFeth}>Jobs OnHold</Button>
                                <div className="col-md-3 d-flex">
                                    <Button variant="success" onClick={handleJobFeth}>All Job</Button>
                                </div>
                            </div>
                        </div>

                    </div>

                    {data && data.map((item) => (
                        <div className="card custom-card mt-3" key={item.key}>
                            <div className="card-body p-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-sm-2">
                                                <div className="mb-2">Job No.</div>
                                                <div>{item.jobnumber}</div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="mb-2">Posted On</div>
                                                <div>{item.postdate}</div>
                                            </div>
                                            <div className="col-sm-2">
                                                <div className="mb-2"> Priority</div>
                                                <div>{item.priority}</div>
                                            </div>
                                            <div className="row mt-2">
                                                <div className="col-sm-2 ms-lg-auto d-flex justify-content-lg-end align-items-center">



                                                    {accept ? (
                                                        <span className="me-2"><Button variant="success" onClick={() => handleAcceptjob(item.jobnumber)}>Accept job</Button></span>
                                                    ) : (
                                                        <span className="me-2"><Button variant="success" disabled>Accept job</Button></span>
                                                    )}


                                                    {/* {resume ? (
                                                        <span className="me-2"><Button variant="success" onClick={() => handleTime()}>Resume job</Button></span>
                                                    ) : (
                                                        <span className="me-2"><Button variant="success" disabled>Resume job</Button></span>
                                                    )} */}

                                                    {emergencydrop ? (
                                                        <span className="me-2"><Button variant="success" onClick={() => handleCanclerepublish(item.jobnumber)}>Emergency drop</Button></span>
                                                    ) : (
                                                        <span className="me-2"><Button variant="success" disabled>Emergency Drop</Button></span>
                                                    )}

                                                    {holdjob ? (
                                                        <span className="me-2"><Button variant="success" onClick={() => handleJobHold(item.jobnumber)}>Hold job</Button></span>
                                                    ) : (
                                                        <span className="me-2"><Button variant="success" disabled>Hold job</Button></span>
                                                    )}

                                                    {/* {onhold ? (
                                                    <span className="me-2"> <Button variant="success" onClick={() => clearInterval(id.current)}>On hold</Button></span>
                                                    ) : (
                                                    <span className="me-2"> <Button variant="success" disabled>On hold</Button></span>
                                                    )} */}

                                                    {complete ? (
                                                        <Button variant="success"
                                                            onClick={() => handleComplete(item.jobnumber)}
                                                        // onClick={() => {
                                                        //     clearInterval(id.current);
                                                        //     setTime(0);
                                                        // }}>
                                                        >
                                                            Complete
                                                        </Button>
                                                    ) : (
                                                        <Button variant="success" disabled>Complete</Button>
                                                    )}
                                                    <span className="me-2"><EmployeeViewjob handleOrderidsend={item.jobnumber} handleJobform={item.jobform} /></span>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="row">
                                            <div className="col-md-12">
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div>
                                                {aditional ? (
                                                    <span>
                                                        <AddtionalDetails handleAdationaljobno={item.jobnumber} jobform={item.jobform} />
                                                    </span>
                                                ) : (
                                                    <span></span>
                                                )}

                                                {republish ? (
                                                    <span>
                                                        <Republish handleRepublish={item.jobnumber} />
                                                    </span>
                                                ) : (
                                                    <span></span>
                                                )}

                                                {description ? (
                                                    <span className="ms-2 ">
                                                        <DescritopnWriting handleDesjonno={item.jobnumber} />
                                                    </span>
                                                ) : (
                                                    <span></span>
                                                )}

                                                {updatestock ? (
                                                    <span className="ms-2">
                                                        <JobModal jobno={item.jobnumber} id={item.sid} jobform={item.jobform} />
                                                    </span>
                                                ) : (
                                                    <span></span>
                                                )}

                                                {asignejob ? (
                                                    <span className="ms-2">
                                                        <AssginEmployee handleEmployerjobno={item.jobnumber} />
                                                    </span>
                                                ) : (
                                                    <span></span>
                                                )}

                                                {billing ? (
                                                    <span className="ms-2">
                                                        <BillingModal jobno={item.jobnumber} jobform={item.jobform} id={item.sid} />
                                                    </span>
                                                ) : (
                                                    <span></span>
                                                )}

                                                {ViewDetails ? (
                                                    <span className="ms-2">
                                                        <AdditionalDetails handlesendJobNumberView={item.jobnumber} />
                                                    </span>
                                                ) : (
                                                    <span></span>
                                                )}

                                                <span className="ms-2">
                                                    <ViewDetailsStock jobno={item.jobnumber} />
                                                </span>

                                                <div style={{ float: 'right' }}>
                                                    <Button onClick={() => handleImagedisplay(item.jobnumber, item.jobform)}>Download Image</Button>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default Dashboard;   