import { React, useState } from 'react';
import clasess from './common/css/Content.module.css';
import Header from './common/header';
import Sidemenu from './common/sidemenu';
import { BsEmojiHeartEyesFill, BsFillEmojiLaughingFill } from "react-icons/bs";
import { FaStarOfDavid } from "react-icons/fa";
import { VerticalTimeline, VerticalTimelineElement, } from 'react-vertical-timeline-component';
import { Button } from 'react-bootstrap';
import 'react-vertical-timeline-component/style.min.css';
import axios from 'axios';
import lib from './config/lib';
import Loading from './common/Loading';

const StepProgress = () => {
    const [currentStep, setCurrentStep] = useState(1);

    const handleNext = () => {
        setCurrentStep((prevStep) => prevStep + 1);
    };

    const handlePrev = () => {
        setCurrentStep((prevStep) => prevStep - 1);
    };

    const renderStepContent = () => {
        switch (currentStep) {
            case 1:
                return <Step1 />;
            case 2:
                return <Step2 />;
            case 3:
                return <Step3 />;
            // Add more cases for additional steps as needed
            default:
                return null;
        }
    };

    return (
        <div>
            {/* Step progress indicator */}
            <div>
                <button onClick={handlePrev} disabled={currentStep === 1}>
                    Previous
                </button>
                <span>Step {currentStep}</span>
                <button onClick={handleNext} disabled={currentStep === 3 /* or your last step */}>
                    Next
                </button>
            </div>

            {/* Render step content */}
            {renderStepContent()}
        </div>
    );
};

// Define your step components
const Step1 = () => <div>Step 1 Content</div>;
const Step2 = () => <div>Step 2 Content</div>;
const Step3 = () => <div>Step 3 Content</div>;



const Jobreport = () => {
    const [jobno, setJobno] = useState('');

    const [neworder, setNeworder] = useState([]);
    const [keyline, setKeyline] = useState([]);
    const [rulechange, setRulechange] = useState([]);
    const [sample, setSample] = useState([]);
    const [standerdorder, setStanderdorder] = useState([]);
    const [quation, setQuation] = useState([]);
    const [consumable, setConsumable] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleSerach = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', jobno);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.standerdsreport, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            setStanderdorder(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log(error);
            setLoading(false);
        }
    }

    const handleQuationReport = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', jobno);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.quationreport, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            setQuation(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log(error);
            setLoading(false);
        }
    }

    const handleRulechangeReport = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', jobno);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.rulechangereport, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            setRulechange(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log(error);
            setLoading(false);
        }
    }

    const handleOrderReport = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', jobno);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.orderreport, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            setNeworder(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log(error);
            setLoading(false);
        }
    }

    const handleKeyline = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', jobno);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.keylinereport, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            setKeyline(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log(error);
            setLoading(false);
        }
    }

    const handleConsumable = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', jobno);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.consumablereport, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            setConsumable(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log(error);
            setLoading(false);
        }
    }

    const handleSearchbutton = () => {
        handleSerach();
        handleQuationReport();
        handleRulechangeReport();
        handleOrderReport();
        handleKeyline();
        handleConsumable();
    }

    return (
        <div>
            <div className={clasess.container1}>
                <Header />
                <Sidemenu />
                <div className={clasess.container}>
                    <Loading loading={loading} />
                    <div className='row mt-3'>
                        <div className='col-lg-10 col-md-9 ms-2'>
                            <h3>Job track</h3>
                        </div>
                    </div>

                    <hr />
                    <div className='row justify-content-center'>
                        <div className='col-lg-9'>
                            <div className="col-lg-6 col-md-8 d-flex">
                                <input type="text" class="form-control" placeholder="Search job..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setJobno(e.target.value)} />
                                <Button variant="success" style={{ margin: '10px' }} onClick={handleSearchbutton} >Search</Button>
                            </div>
                        </div>
                    </div>
                    <div style={{ maxHeight: '100vh', overflow: 'auto' }}>
                        <VerticalTimeline>
                            {neworder && neworder.map((item) => (
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                    date="2023 - present"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<BsEmojiHeartEyesFill />}
                                >
                                    <h3 className="vertical-timeline-element-title">New Order</h3>
                                    <h4 className="vertical-timeline-element-subtitle">{item.orderid}</h4>
                                    <h4 className="vertical-timeline-element-subtitle">{item.date}</h4>
                                </VerticalTimelineElement>
                            ))}

                            {keyline && keyline.map((item) => (
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                    date="2023 - present"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<BsEmojiHeartEyesFill />}
                                >
                                    <h3 className="vertical-timeline-element-title">Key Line</h3>
                                    <h4 className="vertical-timeline-element-subtitle">{item.orderid}</h4>
                                    <h4 className="vertical-timeline-element-subtitle">{item.date}</h4>
                                </VerticalTimelineElement>
                            ))}

                            {rulechange && rulechange.map((item) => (
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                    date="2023 - present"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<BsEmojiHeartEyesFill />}
                                >
                                    <h3 className="vertical-timeline-element-title">Rule Change</h3>
                                    <h4 className="vertical-timeline-element-subtitle">{item.orderid}</h4>
                                    <h4 className="vertical-timeline-element-subtitle">{item.date}</h4>
                                </VerticalTimelineElement>
                            ))}

                            {sample && sample.map((item) => (
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                    date="2023 - present"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<BsEmojiHeartEyesFill />}
                                >
                                    <h3 className="vertical-timeline-element-title">Sample</h3>
                                    <h4 className="vertical-timeline-element-subtitle">{item.orderid}</h4>
                                    <h4 className="vertical-timeline-element-subtitle">{item.date}</h4>
                                </VerticalTimelineElement>
                            ))}

                            {standerdorder && standerdorder.map((item) => (
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                    date="2023 - present"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<BsEmojiHeartEyesFill />}
                                >
                                    <h3 className="vertical-timeline-element-title">Standerd Order</h3>
                                    <h4 className="vertical-timeline-element-subtitle">{item.orderid}</h4>
                                    <h4 className="vertical-timeline-element-subtitle">{item.date}</h4>
                                </VerticalTimelineElement>
                            ))}

                            {quation && quation.map((item) => (
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                    date="2023 - present"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<BsEmojiHeartEyesFill />}
                                >
                                    <h3 className="vertical-timeline-element-title">Quation</h3>
                                    <h4 className="vertical-timeline-element-subtitle">{item.orderid}</h4>
                                    <h4 className="vertical-timeline-element-subtitle">{item.date}</h4>
                                </VerticalTimelineElement>
                            ))}

                            {consumable && consumable.map((item) => (
                                <VerticalTimelineElement
                                    className="vertical-timeline-element--work"
                                    contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                                    date="2023 - present"
                                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                    icon={<BsEmojiHeartEyesFill />}
                                >
                                    <h3 className="vertical-timeline-element-title">Consumable</h3>
                                    <h4 className="vertical-timeline-element-subtitle">{item.orderid}</h4>
                                    <h4 className="vertical-timeline-element-subtitle">{item.date}</h4>
                                </VerticalTimelineElement>
                            ))}

                        </VerticalTimeline>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default Jobreport;