'useclient'
import React, { Fragment, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assest/css/custom.css';
import './assest/css/gradient.css';
import Header from './common/header';
import Sidemenu from './common/sidemenu';
import clasess from './common/css/Content.module.css';
import './components/stockModal/stockmodal'
import axios from 'axios';
import './components/css/modle.css';
import Table from 'react-bootstrap/Table';
import ReactPaginate from "react-paginate";
import Button from 'react-bootstrap/Button';
import lib from './config/lib';
import Modal from './components/modalsupport/supportModalmessage';
import './components/css/modle.css';
import { useDispatch } from 'react-redux';
import { add, remove } from './store/Cartslice';
import Updatedetail from './components/supportdetailsupdate/Updatedetail';
import Loading from './common/Loading';

function Support() {
    const dispatch = useDispatch();

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleOrder();
    }, []);

    const handleOrder = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.support, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    // Calculate the total number of pages
    const pageCount = Math.ceil(data.length / itemsPerPage);

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data.slice(startIndex, endIndex);
    };

    const currentPageData = getCurrentPageData([]);

    // const [show, setShow] = useState(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (id) => {
        dispatch(add(id));
        setIsModalOpen(true);
    };

    const closeModal = (id) => {
        dispatch(remove(id));
        setIsModalOpen(false);
    };


    const [type, setType] = useState('');

    const handleSearch = async () => {
        const token = localStorage.getItem('token');
        setLoading(true);
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'support');
        formData.append('search', type);


        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.searchonclick, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }


    return (
        <Fragment>
            <div className={clasess.container1}>
                <Header />
                <Sidemenu />
                <div>
                    <div className={clasess.container}>
                        <div style={{ width: '100%', height: 'auto', marginTop: '20px' }}>
                            <Loading loading={loading} />
                            <div className="row justify-content-between">
                                <h3 className="ms-2">Ticket list</h3>
                            </div>
                            <hr />

                            <div className='row  align-items-center'>

                                <div className="col-lg-3 d-flex">
                                    <input type="text" class="form-control" placeholder="Type to search..." aria-describedby="button-addon2" onChange={(e) => setType(e.target.value)} />
                                    <Button variant="success" style={{ margin: '10px' }} onClick={handleSearch} >Search</Button>
                                </div>
                                <div className='col-lg-8 d-flex justify-content-end '>
                                    <Updatedetail />
                                </div>
                            </div>
                            <Table striped bordered hover >
                                <thead>
                                    <tr>
                                        <th>Customer Mail</th>
                                        <th>Message</th>
                                        <th>Reply</th>
                                        <th>Status</th>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentPageData.map((item) => (
                                        <tr>
                                            <td>{item.email}</td>
                                            <td>{item.msg}</td>
                                            <td>{item.reply}</td>
                                            <td>{item.status}</td>
                                            <td><Button variant="success" onClick={() => openModal(item.email)} >Reply</Button>
                                                <Modal isOpen={isModalOpen} closeModal={() => closeModal(item.email)} /></td>
                                        </tr>
                                    ))}
                                </tbody>

                            </Table>
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Support    