import React, { useState } from 'react';
import Modal from './UpdateDetailsView';
import Button from 'react-bootstrap/Button';
import '../css/modle.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function UpdateDetails({jobno, id, jobform, handleOrder}) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="App">
            <Button variant="success" onClick={openModal} >Update Details</Button>
            <Modal isOpen={isModalOpen} closeModal={closeModal} jobno={jobno} id={id} jobform={jobform} handleOrder={handleOrder} />
        </div>
    );
}

export default UpdateDetails;
