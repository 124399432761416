import React, { useState } from 'react';
import '../assest/css/custom.css'

const Loading = ({loading}) => {

  return (
    <div>
      {loading && (
        <div className="loading-spinner-popup"> 
          <div className="spinner"></div>
          <p>Loading...</p>
        </div>
      )}
    </div>
  );
};

export default Loading;
