'useclient'
import React, { Fragment, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './common/header';
import Sidemenu from './common/sidemenu';
import clasess from './common/css/Content.module.css';
import './components/stockModal/stockmodal'
import axios from 'axios';
import Swal from 'sweetalert2';
import './components/css/modle.css';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ConsumableTable from './components/TranckingOrder/ConsumableTable'
import StandardTable from './components/TranckingOrder/StandardTable'
import Keylinetable from './components/TranckingOrder/Keylinetable'
import QuatationTable from './components/TranckingOrder/QuatationTable'
import CustomOrderTable from './components/TranckingOrder/CustomOrderTable'


function OrdeTracking() {

    return (
        <Fragment>
            <div className={clasess.container1}>
                <Header />
                <Sidemenu />
                <div>
                    <div className={clasess.container}>
                        <div style={{ width: '100%', height: 'auto', marginTop: '20px' }}>
                            <div className="row align-items-center">
                                <div className="col-lg-11">
                                    <h3 className="ms-2">My Order</h3>
                                </div>

                            </div>
                            <hr />

                            <Tabs
                                defaultActiveKey="Consumable"
                                transition={false}
                                id="noanim-tab-example"
                                className="mb-3"
                                style={{ fontSize: '20px' }}
                            >
                                <Tab eventKey="Standard" title="Standard Order">
                                    <StandardTable />
                                </Tab>
                                <Tab eventKey="Custom Order" title="Custom Orders">
                                    <CustomOrderTable />
                                </Tab>
                                <Tab eventKey="printmachine" title="Rule Change">
                                    <Keylinetable />
                                </Tab>
                                <Tab eventKey="Consumable" title="Consumable">
                                    <ConsumableTable />
                                </Tab>
                                <Tab eventKey="diemachine" title="Quotation">
                                    <QuatationTable />
                                </Tab>

                            </Tabs>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default OrdeTracking    