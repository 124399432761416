import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '../css/modle.css';
import lib from '../../config/lib';
import axios from 'axios';
import Swal from 'sweetalert2';
import Loading from '../../common/Loading';

const AddstockMessage = ({ isOpen, closeModal }) => {
    const [vender, setVender] = useState([]);
    const [item, setItem] = useState([]);
    const [quality, setQuality] = useState([]);
    const [umo, setUmo] = useState([]);
    const [varient, setVarient] = useState([]);
    const [loading, setLoading] = useState(false);
    const [venderselect, setVenderselect] = useState('');
    const [iteminsert, setIteminsert] = useState('');
    const [qualityinsert, setQualityinsert] = useState('');
    const [umoinsert, setUmoinsert] = useState('');
    const [quantity, setQuantity] = useState('');
    const [varientinsert, setVarientinsert] = useState([]);

    const handleSubmit = async () => {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        formData.append("vender", venderselect);
        formData.append("item", iteminsert);
        formData.append("quality", qualityinsert);
        formData.append("umo", umoinsert);
        formData.append("quantity", quantity);
        formData.append("varient", varientinsert);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.addstock, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Stock Added Successfully!', 'success');
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Something Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        handleVender();
        handleitem();
        handlequality();
        handleUmo();
        handleVarient();
    }, []);

    const handleVender = async () => {

        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.venderdisplay, formData);
            setVender(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleitem = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.fethitem, formData);
            setItem(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handlequality = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.displayquality, formData);
            setQuality(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleUmo = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'All');
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.fethumo, formData);
            setUmo(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleVarient = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'All');
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.displayvarient, formData);
            setVarient(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }


    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Add stock</h4>
                <hr />
                <label className='mb-1'>Vendor</label>
                <Form.Select aria-label="Default select example" onChange={(e) => setVenderselect(e.target.value)}>
                    <option selected disable>Select Vender</option>
                    {vender && vender.map((item) => (
                        <option value={item.name}>{item.name}</option>
                    ))}
                </Form.Select>


                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='mt-3'>
                            <label className='mb-1'>Item</label>
                            <Form.Select aria-label="Default select example" onChange={(e) => setIteminsert(e.target.value)}>
                                <option selected disabled>Select Item</option>
                                {item && item.map((item) => (
                                    <option value={item.item}>{item.item}</option>
                                ))}
                            </Form.Select>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='mt-3'>
                            <label className='mb-1'>Variant</label>
                            <Form.Select aria-label="Default select example" onChange={(e) => setVarientinsert(e.target.value)}>
                                <option selected disabled>Select variant</option>
                                {varient && varient.map((item) => (
                                    <option value={item.varient}>{item.varient}</option>
                                ))}
                            </Form.Select>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='mt-3'>
                            <label className='mb-1'>Quality</label>
                            <Form.Select aria-label="Default select example" onChange={(e) => setQualityinsert(e.target.value)} >
                                <option selected disabled>Select Item</option>
                                {quality && quality.map((item) => (
                                    <option value={item.quality}>{item.quality}</option>
                                ))}
                            </Form.Select>
                        </div>
                    </div>
                </div>



                <div className='row'>
                    <div className='col-lg-6'>
                        <div class="input-group flex-nowrap mt-3">
                            <label> Quantity</label>
                            <input type="text" class="form-control" placeholder="Enter quality name" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setQuantity(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='mt-3'>
                            <label className='mb-1'>Parameter</label>
                            <Form.Select aria-label="Default select example" onChange={(e) => setUmoinsert(e.target.value)} >
                                <option selected disabled>Select Item</option>
                                {umo && umo.map((item) => (
                                    <option value={item.umo}>{item.umo}</option>
                                ))}
                            </Form.Select>
                        </div>
                    </div>
                </div>

                <div className='mt-3'><button type="button" class="btn btn-primary" value='Best' onClick={handleSubmit} >Save</button></div>
            </div>
        </div>
    );
};

export default AddstockMessage;
