import React from 'react';
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

const Bargraph = () => {
    ChartJS.register(
        BarElement,
        CategoryScale,
        LinearScale,
        Tooltip,
        Legend
    )

    const data = {
        labels: ['Monday', 'Tuesday', 'Wednessday', 'Thrusday', 'Friday', 'Saturday', 'Sunday'],
        datasets: [
            {
                label: 'New Orders',
                data: [3, 6, 9, 1, 2, 5, 10],
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                borderColor: 'gray',
                borderWidth: 1,
                borderRadius: 50
            },
            {
                label: 'Pending Orders',
                data: [3, 3, 3, 5, 2, 8, 4],
                backgroundColor: 'rgba(255, 99, 132, 0.6)',
                borderColor: 'gray',
                borderWidth: 1,
                borderRadius: 50
            },
            {
                label: 'Completed Orders',
                data: [6, 9, 3, 1, 2, 5, 9],
                backgroundColor: 'rgba(255, 206, 86, 0.6)',
                borderColor: 'gray',
                borderWidth: 1,
                borderRadius: 50
            },
        ]
    }

    const options = {

    }
    return (
        <div className='graphbar'>
            <Bar className='bar'
                data={data}
                options={options}

            ></Bar>
        </div>
    )
}

export default Bargraph;

