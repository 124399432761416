import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';
import DuplexSelect from './DuplexSelect';
import DuplexSelected from './DuplexSelected';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import lib from '../../config/lib';
import Loading from '../../common/Loading';
import { Button } from 'react-bootstrap';

const JobcardMessage = ({ isOpen, closeModal, handleOrderidsend, handleSendsid, machinetype }) => {
    const cartProducts = useSelector(state => state.cart);
    const [loading, setLoading] = useState(false);
    const [rulechange, setrulechange] = useState('');
    const [quality, setquality] = useState('');
    const [type, settype] = useState('');
    const [crusingselect, setcrusingselect] = useState('');
    const [cuttingselect, setcuttingselect] = useState('');
    const [samediebefore, setsamediebefore] = useState('');
    const [jobno, setjobno] = useState('');
    const [jobname, setJobname] = useState();
    const [olddieflnit, setolddieflint] = useState('');
    const [description, setdescription] = useState('');
    const [sid, setsid] = useState('');
    const [date, setdate] = useState('');
    const [time, setTime] = useState('');
    const [status, setstatus] = useState('');
    const [productimg, setproductimg] = useState('');
    const [oldorderid, setOldorderid] = useState('');

    const [designerimg, setDesignerImg] = useState('');
    const [jobmode, setJobmode] = useState('');

    // useEffect(() => {
    //     handleKeylineFeth();
    // }, []);

    const handleKeylineFeth = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handleOrderidsend);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.rulechangedisplay, formData);
            if (response.data.status === "success") {
                setrulechange(response.data.rulechange);
                setquality(response.data.quality);
                setJobname(response.data.jobname);
                settype(response.data.type);
                setcrusingselect(response.data.crusingselect);
                setcuttingselect(response.data.cuttingselect);
                setsamediebefore(response.data.samediebefore);
                setjobno(response.data.jobno);
                setolddieflint(response.data.olddieflnit);
                setdescription(response.data.description);
                setsid(response.data.sid);
                setdate(response.data.date);
                setTime(response.data.time)
                setstatus(response.data.tracker);
                setproductimg(response.data.productimg);
                setJobmode(response.data.jobmode);
                setOldorderid(response.data.oldorderid);

                handleDesignerimg();
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
        }
    }

    const handleDesignerimg = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handleOrderidsend);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.designerimg, formData);
            if (response.data.status == "success") {
                setDesignerImg(response.data.designerimg);
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-contente">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>View job</h4>
                <hr />

                <div className='tableview newordertable'>
                    <Table striped="columns">
                        <thead>
                            <tr>
                                <td colspan="8" className='text-center invoce-tittle'>View Job Card</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={2}>Customer Image: <span className='ms-3'><a href={lib.imgUrl + '/' + productimg} target="_blank" >View Image</a></span></td>
                                <td colSpan={2}>Designer Image: <span className='ms-3'><a href={lib.imgUrl + '/' + designerimg} target="_blank" >View Image</a></span></td>
                                <td colSpan={2}>Job no.<span className='ms-3'>{jobno}</span></td>
                                <td colSpan={2}>Customer code:<span className='ms-3'>{sid}</span> </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Job name.<span className='ms-3'>{jobname}</span></td>
                                <td colSpan={2}>Job mode: <span className='ms-3'>{jobmode}</span></td>
                                <td colSpan={2}>Rulechange:<span className='ms-3'>{rulechange}</span> </td>
                                <td colSpan={2}>Quality:<span className='ms-3'>{quality}</span></td>
                            </tr>
                            <tr>
                                <td colSpan={2}> Type:<span className='ms-3'>{type}</span> </td>
                                <td colSpan={2}>Crusign Select:<span className='ms-3'>{crusingselect}</span> </td>
                                <td colSpan={2}>Cutting Select:<span className='ms-3'>{cuttingselect}</span></td>
                                <td colSpan={2}>Same Die Before:<span className='ms-3'>{samediebefore}</span> </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>OldDieFlint:<span className='ms-3'>{olddieflnit}</span> </td>
                                <td colSpan={2}>Old Order id:<span className='ms-3'>{oldorderid}</span> </td>
                                <td colSpan={2}> Date:<span className='ms-3'>{date}</span></td>
                                <td colSpan={2}> Time:<span className='ms-3'>{time}</span></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Priting Machine Data:<span className='ms-3'><DuplexSelected handleSendsid={handleSendsid} machinetype={machinetype} /></span></td>
                                <td colSpan={2}>Die Machine Data::<span className='ms-3'><DuplexSelect handleSendsid={handleSendsid} machinetype={machinetype} /></span></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='row border-cus p-2'>

                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Description:
                                    </h3>
                                </span>
                                <span> <h3 className='mt-0'>{description}</h3></span>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-8'>
                    </div>
                </div>

                <Button onClick={handleKeylineFeth}>Feth Data</Button>

            </div>
        </div>
    );
};

export default JobcardMessage;
