import { React, useState } from 'react';
import Table from 'react-bootstrap/Table';
import clasess from './common/css/Content.module.css';
import Header from './common/header';
import Sidemenu from './common/sidemenu';
// import PaginationSection from './PaginationSection';
import AddUser from './components/userModal/addUser'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ReactPaginate from "react-paginate";
import PritingMachineData from './components/userdetail/PritingMachineData'
import Diemachine from './components/userdetail/Diemachine'
import EntersheetData from './components/userdetail/EntersheetData'
import Machinedata from './components/userdetail/Machinedata'
import SheetData from './components/userdetail/SheetData'
import UserList from './components/userdetail/UserList'

const Userdetails = () => {
    return (
        <div>
            <div className={clasess.container1}>
                <Header />
                <Sidemenu />
                <div className={clasess.container}>
                    <div className='row mt-3'>
                        <div className='col-lg-10 col-md-9 ms-2'>
                            <h3 >User Details</h3>
                        </div>
                        <div className='col-lg-1 col-md-2'>
                            <AddUser />
                        </div>
                    </div>

                    <hr />

                    <Tabs
                        defaultActiveKey="myprofile"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3"
                        style={{ fontSize: '20px' }}
                    >
                        <Tab eventKey="myprofile" title="User List">
                            <UserList />
                        </Tab>

                        <Tab eventKey="printmachine" title="Printing Machine data">
                            <PritingMachineData />
                        </Tab>

                        <Tab eventKey="diemachine" title="Die Machine data">
                            <Diemachine />
                        </Tab>

                        <Tab eventKey="EnterSheet" title="Enter Machine data">
                            <Machinedata />
                        </Tab>

                        <Tab eventKey="Sheetdata" title="Sheet Machine data">
                            <SheetData />
                        </Tab>

                        <Tab eventKey="Datasheet" title="Enter Sheet data">
                            <EntersheetData />
                        </Tab>
                    </Tabs>

                </div>
            </div>
        </div>
    )
}

export default Userdetails;