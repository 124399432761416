import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import '../css/modle.css';

const CalulationModal = ({ isOpen, closeModal, handledisplay }) => {

    const handleOpen = () => {
        var type = document.getElementById("type");
        var inch = document.getElementById("inch");
        var mm = document.getElementById("mm");

        if(type.value === "inch"){
            inch.style.display = "block";
            mm.style.display = "none";
        }
        else
        {
            inch.style.display = "none";
            mm.style.display = "block";
        }
    }

    useEffect(() => {
        handledisplay();
    })

    const handleClose = () => {
        handledisplay(true);
        closeModal();
    }

    const [lenth, setLenth] = useState('');
    const [Width, setWidth] = useState('');

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <span className="close" onClick={handleClose}>&times;</span>
                <h4>Add Item</h4>
                <hr />

                <div className='row'>
                    <div className='row'>
                        
                        <div className='col-md-12'>
                            <label className='mb-1'>Select Type</label>
                            <Form.Select aria-label="Default select example" id='type' onChange={handleOpen}>
                                <option selected>Select Type</option>
                                <option>inch</option>
                                <option>mm</option>
                            </Form.Select>
                        </div>

                        <div className='col-md-12 mt-3'>
                            <label className='mb-1'>Lenth</label>
                            <div class="input-group flex-nowrap">
                                <input type="text" className="form-control" placeholder="Enter Lenth" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setLenth(e.target.value)}/>
                            </div>
                        </div>

                        <div className='col-md-12 mt-3'>
                            <label className='mb-1'>Width</label>
                            <div class="input-group flex-nowrap">
                                <input type="text" className="form-control" placeholder="Enter Width" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setWidth(e.target.value)}/>
                            </div>
                        </div>

                        <div className='col-md-12 mt-3' id='inch' style={{display: 'none'}}>
                            <label className='mb-1'>Quantity</label>
                            <div class="input-group flex-nowrap">
                                <input type="text" className="form-control" placeholder="Enter Quantity" aria-label="Username" aria-describedby="addon-wrapping" value={lenth * Width} />
                            </div>
                        </div>

                        <div className='col-md-12 mt-3' id='mm' style={{display: 'none'}}>
                            <label className='mb-1'>Quantity</label>
                            <div class="input-group flex-nowrap">
                                <input type="text" className="form-control" placeholder="Enter Quantity" aria-label="Username" aria-describedby="addon-wrapping" value={lenth * Width / 645.16} />
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
};

export default CalulationModal;
