import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assest/css/custom.css';
import '../assest/css/reset.css';
import '../assest/css/layout.css';
import '../assest/css/themes.css';
import '../assest/css/typography.css';
import '../assest/css/styles.css';
import '../assest/css/shCore.css';
import '../assest/css/bootstrap.css';
import '../assest/css/jquery.jqplot.css';
import '../assest/css/jquery-ui-1.8.18.custom.css';
import '../assest/css/data-table.css';
import '../assest/css/form.css';
import '../assest/css/ui-elements.css';
import '../assest/css/wizard.css';
import '../assest/css/sprite.css';
import '../assest/css/gradient.css';
import logo from '../assest/images/logo.jpg';
import thumb from '../assest/images/user_thumb.png';
import axios from 'axios';
import lib from '../config/lib';
import Swal from 'sweetalert2';

const Header = () => {
   const [name, setName] = useState('');
   const [role, setRole] = useState('');

    useEffect(() => {
        handlesubcatdisplay();
      }, []);
  
    const handlesubcatdisplay = async () => {
  
        const token = localStorage.getItem("token");    
        const formData = new FormData();
        formData.append("apikey", token);
  
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.header, formData);
            if(response.data.status == "success"){
                setName(response.data.name);
                setRole(response.data.role);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
        }
    }
    return (
        <div>
            <div id="container">
                <div id="header" class="blue_lin">
                    <div class="header_left">
                        <div class="logo">
                           <a href='dashboard'> <img src={logo} width={160} height={60} alt="Ekra" /></a>
                        </div>
                    </div>
                    <div class="header_right">
                        <div id="user_nav">
                            <ul>
                                <li class="user_thumb"><a href="employersdetails"><span class="icon"><img src={thumb} width="30" height="30" alt="User"/></span></a></li>
                                <li class="user_info"><span class="user_name">{name}</span><span>{role}</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;