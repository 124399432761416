import React, { useState } from 'react';
import Modal from './ViewJobMessage';
import '../css/modle.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FiEye } from 'react-icons/fi'

function ViewJob() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <span>

            <FiEye size={20} variant="success" onClick={openModal} />
            <Modal isOpen={isModalOpen} closeModal={closeModal} />
        </span>

    );
}

export default ViewJob;
