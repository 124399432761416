import React, { useState } from 'react';
import clasess from './common/css/Content.module.css';
import Header from './common/header';
import Sidemenu from './common/sidemenu';
import axios from 'axios';
import lib from './config/lib';
import Swal from 'sweetalert2';
import Loading from './common/Loading';

const Changepass = () => {
    const [np, setNp] = useState('');
    const [cp, setCp] = useState('');
    const [loading, setLoading] = useState(false);

    const handlePasschange = async () => {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        formData.append("np", np);
        formData.append("cp", cp);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.changepassword, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Password Changed Successfully!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }
    return (
        <div>
            <div className={clasess.container1}>
                <Header />
                <Sidemenu />
                <div className={clasess.container}>
                    <Loading loading={loading} />
                    <h3 style={{ marginLeft: '15px' }}>Admin Change Password</h3>
                    <hr />
                    <div id="content">
                        <div class="fullbody">
                            <div class="grid_container">
                                <div class="grid_12 full_block">
                                    <div class="widget_content">
                                        <div class="form_container">
                                            <ul>
                                                <li class="form_grid_4">
                                                    <div>
                                                        <label class="field_title">New Password</label>
                                                        <div class="form_input">
                                                            <input type="password" required onChange={(e) => setNp(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </li>

                                                <li class="form_grid_4">
                                                    <div>
                                                        <label class="field_title">Conform Password</label>
                                                        <div class="form_input">
                                                            <input type="password" required onChange={(e) => setCp(e.target.value)} />
                                                        </div>
                                                    </div>
                                                </li>

                                                <li class="form_grid_6 sbbt">
                                                    <div>
                                                        <div class="form_input">
                                                            <input type="submit" class="btn_small btn_blue" value="Change Password" onClick={handlePasschange} />
                                                        </div>
                                                    </div>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span class="clear"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Changepass;