import React, { useState } from 'react';
import Modal from './addstockMessage';
import Button from 'react-bootstrap/Button';
import '../css/modle.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function Addstock() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="App">
            <Button variant="success" onClick={openModal} >Add stock</Button>
            <Modal isOpen={isModalOpen} closeModal={closeModal} />
        </div>
    );
}

export default Addstock;
