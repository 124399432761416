import React from 'react';
import Header from './common/header';
import Sidemenu from './common/sidemenu';
import Topmenu from './common/Topmenu';
import clasess from './DeshBoard.module.css';

const Dashboard = () => {
  return (
    <div className={clasess.container}>
      <Header />
      <Sidemenu />
      <Topmenu />
    </div>
  )
}

export default Dashboard;