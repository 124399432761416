import React, { useState } from 'react';
import Modal from './SubrateView';
import Button from 'react-bootstrap/Button';
import '../css/modle.css';
import 'bootstrap/dist/css/bootstrap.min.css';


function Subrate({ companynameinsert, handleRate }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="App">
            <Button variant="success" onClick={openModal} >Create Rate</Button>
            <Modal isOpen={isModalOpen} closeModal={closeModal} companynameinsert={companynameinsert} handleRate={handleRate}/>
        </div>
    );
}

export default Subrate;
