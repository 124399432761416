import React, {useState} from "react";
import Button from 'react-bootstrap/Button';
import Swal from "sweetalert2";
import axios from "axios";
import lib from "../config/lib";
import Loading from "../common/Loading";

const HoldButton = ({handleJobno, handleSubrole}) => {

    const [loading, setLoading] = useState(false);

    const handletesting = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handleJobno);
        formData.append('subrole', handleSubrole);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.adminholdjob, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Job Hold Successfully!', 'success');
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }
    return(
        <div>
            <Loading loading={loading}/>
            <Button variant="success" onClick={handletesting}>Hold Job</Button>
        </div>
    )
};

export default HoldButton;