import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '../css/modle.css';
import axios from 'axios';
import lib from '../../config/lib';
import Swal from 'sweetalert2';
import { useSelector } from 'react-redux';

const AddUserView = ({ isOpen, closeModal }) => {
    const cartProducts = useSelector(state => state.cart);

    const [name, setName] = useState('');
    const [owner, setOwner] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [gst, setGst] = useState('');
    const [address, setAddress] = useState('');
    const [customer, setCustomer] = useState('');
    const [designer, setDesigner] = useState('');
    const [marketnumber, setMarketnumber] = useState('');
    const [whatsappgroup, setWhatsappgroup] = useState('');
    const [hideshow, sethideshow] = useState('');

    useEffect(() => {
        handleFethDetails();
    }, []);

    const handleFethDetails = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', cartProducts);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.userdetailsidfeth, formData);
            if (response.data.status == "success") {
                setName(response.data.name);
                setOwner(response.data.mobile);
                setEmail(response.data.email);
                setCompany(response.data.cname);
                setGst(response.data.gstno);
                setAddress(response.data.address);
                setCustomer(response.data.customercode);
                setDesigner(response.data.designernumber);
                setMarketnumber(response.data.marketpersonnumber);
                setWhatsappgroup(response.data.groupname);
                sethideshow(response.data.hideshow);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
        }
    }

    const handleEdituser = async () => {
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', cartProducts);
        formData.append('name', name);
        formData.append('owner', owner);
        formData.append('email', email);
        formData.append('company', company);
        formData.append('gst', gst);
        formData.append('address', address);
        formData.append('customer', customer);
        formData.append('designer', designer);
        formData.append('marketnumber', marketnumber);
        formData.append('whatsappgroup', whatsappgroup);
        formData.append('hideshow', hideshow);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.edituserdetails, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'User Updated successfully!', 'success');
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-contentes">
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Edit User</h4>
                <hr />

                <div className='row'>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3 ">
                            <label>CUSTOMER NAME</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3">
                            <label>OWNER MOBILE NUMBER</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" value={owner} onChange={(e) => setOwner(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3">
                            <label>EMAIL-ID</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3">
                            <label>COMPANY NAME</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" value={company} onChange={(e) => setCompany(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3">
                            <label>GST NUMBER</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" value={gst} onChange={(e) => setGst(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3">
                            <label>ADDRESS</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" value={address} onChange={(e) => setAddress(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3">
                            <label>CUSTOMER CODE</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" value={customer} onChange={(e) => setCustomer(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3">
                            <label>DESIGNER NUMBER</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" value={designer} onChange={(e) => setDesigner(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3">
                            <label>MARKET PERSON NUMBER</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" value={marketnumber} onChange={(e) => setMarketnumber(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3">
                            <label>WHATS APP GROUP NAME</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" value={whatsappgroup} onChange={(e) => setWhatsappgroup(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3 mt-4" controlId="exampleForm.ControlInput1">
                            <label>Hide Show Breakup</label>
                            <Form.Select aria-label="Default select example" value={hideshow} onChange={(e) => sethideshow(e.target.value)}>
                                <option selected>Select Hide Show Breakup</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>

                <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleEdituser}>Save</button></div>
                <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleFethDetails}>FethDetails</button></div>
            </div>
        </div>
    );
};

export default AddUserView;
