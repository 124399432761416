import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import lib from '../../config/lib';
import Swal from 'sweetalert2';
import Loading from '../../common/Loading';


const UpdatedetailView = ({ isOpen, closeModal }) => {
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [address, setAddress] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSupportupdate = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('email', email);
        formData.append('mobile', mobile);
        formData.append('address', address);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.supportupdate, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Support Updated Successfully!', 'success');
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Update details</h4>
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        <div className='text-justify'><label>Email</label></div>
                        <input type="email" id="input-name" placeholder="email" className='mt-2 mb-2' onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="col-md-12">
                        <div className='text-justify'><label>Mobile Number</label></div>
                        <input type="text" id="input-name" placeholder="Mobile Number" onChange={(e) => setMobile(e.target.value)} />
                    </div>

                    <div className="col-md-12">
                        <div className='text-justify'><label>Address</label></div>
                        <input type="text" id="input-name" placeholder="address" onChange={(e) => setAddress(e.target.value)} />
                    </div>
                    <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleSupportupdate} >Update</button> </div>
                </div>
            </div>
        </div>
    );
};

export default UpdatedetailView;
