import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assest/css/custom.css';
import './assest/css/reset.css';
import './assest/css/layout.css';
import './assest/css/themes.css';
import './assest/css/typography.css';
import './assest/css/styles.css';
import './assest/css/shCore.css';
import './assest/css/bootstrap.css';
import './assest/css/jquery.jqplot.css';
import './assest/css/jquery-ui-1.8.18.custom.css';
import './assest/css/data-table.css';
import './assest/css/form.css';
import './assest/css/ui-elements.css';
import './assest/css/wizard.css';
import './assest/css/sprite.css';
import './assest/css/gradient.css';
import Header from './common/header';
import Topmenu from './common/Topmenu';
import Sidemenu from './common/sidemenu';
import clasess from './common/css/Content.module.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import AddCatmodal from './components/catmodal/addcatmodal';
import Subcatmodal from './components/subcatmodal/addsubcatmodal';
import './components/css/modle.css';
import lib from './config/lib';
import Accordion from 'react-bootstrap/Accordion';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { RiDeleteBin4Fill } from "react-icons/ri"
import Standerdtable from './Standerdtable';
import Loading from './common/Loading';

const Standerd = () => {
    const [show, setShow] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);
    const [productname, setProductName] = useState('');
    const [productdescription, setProductdescription] = useState('');
    const [cardimg, setCardImg] = useState({ preview: '', data: '' });
    const [filename, setFilename] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [subcat, setSubcat] = useState('');


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFilename(e.target.files[0]);
        setCardImg({ preview: URL.createObjectURL(file), data: file });
        if (file) {
            setSelectedFile(file);
        } else {
            setSelectedFile(null);
        }
    };

    useEffect(() => {
        handlesubcatdisplay();
    }, []);

    const handlesubcatdisplay = async () => {
        setLoading(true);
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.subcatdisplay, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const [lenthds, setLenthds] = useState('');

    const [widthds, setWidthds] = useState('');
    const [heightds, setHeightds] = useState('');
    const [tfds, setTfds] = useState('');
    const [gfds, setGfds] = useState('');
    const [dfds, setDfds] = useState('');
    const [marginds, setMarginds] = useState('');
    const [lockds, setLockds] = useState('');
    const [ifds, setIfds] = useState('');
    const [wallds, setWallds] = useState('');
    const [ftgds, setFtgds] = useState('');
    const [inds, setInds] = useState('');
    const [inlds, setInlds] = useState('');
    const [flds, setFlds] = useState('');
    const [lpds, setLpds] = useState('');
    const [dhds, setDhds] = useState('');
    const [mds, setMds] = useState('');
    const [dfhds, setDfhds] = useState('');
    const [dfdds, setDfdds] = useState('');
    const [llds, setLlds] = useState('');
    const [luds, setLuds] = useState('');
    const [lexds, setLexds] = useState('');
    const [traceds, setTracds] = useState('');
    const [lardds, setLardds] = useState('');
    const [arcdds, setArcdds] = useState('');
    const [hdds, setHdds] = useState('');
    const [hcds, setHcds] = useState('');
    const [hfds, setHfds] = useState('');
    const [hclds, setHclds] = useState('');
    const [hftds, setHftds] = useState('');
    const [hads, setHads] = useState('');
    const [hadfds, setHadfds] = useState('');

    const [ftds, setFtds] = useState('');
    const [sltds, setSltds] = useState('');
    const [iflpds, setIflpds] = useState('');
    const [slds, setSlds] = useState('');
    const [shds, setShds] = useState('');
    const [hst, setHst] = useState('');
    const [iflds, setIflds] = useState('');
    const [lksds, setLksds] = useState('');
    const [lkrds, setLkrds] = useState('');
    const [mgnds, setMgnds] = useState('');
    const [tmgnds, setTmgnds] = useState('');
    const [ifldds, setIfldds] = useState('');
    const [sdds, setSdds] = useState('');
    const [ifrds, setIfrds] = useState('');
    const [thsdds, setThsdds] = useState('');
    const [thbhds, setThbhds] = useState('');
    const [kanrds, setKanrds] = useState('');
    const [slth2ds, setSlth2ds] = useState('');
    const [iffds, setIffds] = useState('');
    const [insds, setInsds] = useState('');
    const [ilkds, setIlkds] = useState('');
    const [fifds, setFifds] = useState('');
    const [clds, setClds] = useState('');
    const [gripds, setGripds] = useState('');
    const [gconsds, setGconsds] = useState('');
    const [smds, setSmds] = useState('');
    const [tpds, setTpds] = useState('');

    const handleStanderdcard = async () => {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        formData.append("productname", productname);
        formData.append("description", productdescription);
        formData.append("addfile", filename);
        formData.append('subcat', subcat);

        formData.append('lenth', lenthds);
        formData.append("widthds", widthds);
        formData.append("heightds", heightds);
        formData.append("tfds", tfds);
        formData.append("gfds", gfds);
        formData.append("dfds", dfds);
        formData.append("marginds", marginds);
        formData.append("lockds", lockds);
        formData.append("ifds", ifds);
        formData.append("wallds", wallds);
        formData.append("ftgds", ftgds);
        formData.append("inds", inds);
        formData.append("inlds", inlds);
        formData.append("flds", flds);
        formData.append("lpds", lpds);
        formData.append("dhds", dhds);
        formData.append("mds", mds);
        formData.append("dfhds", dfhds);
        formData.append("dfdds", dfdds);
        formData.append("llds", llds);
        formData.append("luds", luds);
        formData.append("lexds", lexds);
        formData.append("traceds", traceds);
        formData.append("lardds", lardds);
        formData.append("arcdds", arcdds);
        formData.append("hdds", hdds);
        formData.append("hcds", hcds);
        formData.append("hfds", hfds);
        formData.append("hclds", hclds);
        formData.append("hftds", hftds);
        formData.append("hads", hads);
        formData.append("hadfds", hadfds);
        formData.append("ftds", ftds);
        formData.append("sltds", sltds);
        formData.append("iflpds", iflpds);
        formData.append("slds", slds);
        formData.append("shds", shds);
        formData.append("hst", hst);
        formData.append("iflds", iflds);
        formData.append("lksds", lksds);
        formData.append("lkrds", lkrds);
        formData.append("mgnds", mgnds);
        formData.append("tmgnds", tmgnds);
        formData.append("ifldds", ifldds);
        formData.append("sdds", sdds);
        formData.append("ifrds", ifrds);
        formData.append("thsdds", thsdds);
        formData.append("thbhds", thbhds);
        formData.append("kanrds", kanrds);
        formData.append("slth2ds", slth2ds);
        formData.append("iffds", iffds);
        formData.append("insds", insds);
        formData.append("ilkds", ilkds);
        formData.append("fifds", fifds);
        formData.append("clds", clds);
        formData.append("gripds", gripds);
        formData.append("gconsds", gconsds);
        formData.append("smds", smds);
        formData.append("tpds", tpds);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.standerdcardsave, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Stander Card added Successfully!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Somthing Went wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return (
        <div className={clasess.container1}>
            <Header />
            <Sidemenu />
            <div>
                <div className={clasess.container}>
                    <div style={{ width: '100%', height: 'auto', marginTop: '20px' }}>
                        <Loading loading={loading} />
                        <div style={{ float: 'right', marginRight: '30px' }}>
                            <Subcatmodal />
                        </div>
                        <div style={{ float: 'right', marginRight: '30px' }}>
                            <AddCatmodal />
                        </div>
                        <h3 style={{ marginLeft: '15px' }}>Upload Standerds</h3>
                        <hr />

                        <Tabs
                            defaultActiveKey="Standard"
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3"
                            style={{ fontSize: '20px' }}
                        >
                            <Tab eventKey="Standard" title="Standard">
                                <div class="grid_container">
                                    <div class="grid_9">
                                        <center>
                                            <div id="form-file-upload" className="beautiful-form">
                                                <div className="upload-container">
                                                    <label id="label-file-upload" htmlFor="input-file-upload" className="upload-label">
                                                        <div className="drag-drop-box">
                                                            <p>
                                                                {selectedFile
                                                                    ? `File Uploaded: ${selectedFile.name}`
                                                                    : 'Drag and drop your file here or'}
                                                            </p>
                                                        </div>
                                                    </label>

                                                    <div class="form1">
                                                        <label for="category">Select Sub Category:</label>
                                                        <select id="category" onChange={(e) => setSubcat(e.target.value)}>
                                                            <option selected disabled>Select Sub Cat</option>
                                                            {data && data.map((item) => (
                                                                <option value={item.subcatid}>{item.subcat}</option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    <input type="file" id="input-file-upload" multiple={false} onChange={handleFileChange} className="file-input" />

                                                    <input type="text" id="input-name" placeholder="Product Name" onChange={(e) => setProductName(e.target.value)} />
                                                    <textarea id="message-box" placeholder="Product Description" onChange={(e) => setProductdescription(e.target.value)}></textarea>

                                                    {/* Access checkbox */}
                                                    <h5 className='d-flex mt-2 mb-2'>Standerd library Access</h5>

                                                    <Accordion>
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>Main Pannel</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">

                                                                    <input type="checkbox" class="btn-check " id="btncheck1" autocomplete="off" onChange={(e) => setLenthds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary ms-0" for="btncheck1">Length</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck2" autocomplete="off" onChange={(e) => setWidthds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck2">width</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck3" autocomplete="off" onChange={(e) => setHeightds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck3">Height</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck4" autocomplete="off" onChange={(e) => setTfds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck4">Tf</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck5" autocomplete="off" onChange={(e) => setGfds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck5">Gf</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck6" autocomplete="off" onChange={(e) => setDfds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck6">Df</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck7" autocomplete="off" onChange={(e) => setMarginds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck7">Margin</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck8" autocomplete="off" onChange={(e) => setLockds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck8">Lock</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck9" autocomplete="off" onChange={(e) => setIfds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck9">If</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck10" autocomplete="off" onChange={(e) => setWallds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck10">Wall</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck11" autocomplete="off" onChange={(e) => setFtgds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck11">Ftg</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck12" autocomplete="off" onChange={(e) => setInds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck12">In</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck13" autocomplete="off" onChange={(e) => setInlds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck13">Inl</label>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="1">
                                                            <Accordion.Header>2nd Pannel</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">

                                                                    {/* 2ND PANEL  */}

                                                                    <input type="checkbox" class="btn-check" id="btncheck14" autocomplete="off" onChange={(e) => setFtds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck14">Ft</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck15" autocomplete="off" onChange={(e) => setFlds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck15">Fl</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck16" autocomplete="off" onChange={(e) => setLpds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck16">Lp</label>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="2">
                                                            <Accordion.Header>3rd Pannel</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                                    {/* (3RD PANEL OR ANY OTHER NAME LIKE DUST FLAP  ) */}

                                                                    <input type="checkbox" class="btn-check" id="btncheck17" autocomplete="off" onChange={(e) => setDhds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck17">Dh</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck18" autocomplete="off" onChange={(e) => setMds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck18">M</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck19" autocomplete="off" onChange={(e) => setDfhds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck19">Dfh</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck20" autocomplete="off" onChange={(e) => setDfdds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck20">Dfd</label>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="3">
                                                            <Accordion.Header>4rth Pannel</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                                    {/* 4th panel   lock  */}
                                                                    <input type="checkbox" class="btn-check" id="btncheck21" autocomplete="off" onChange={(e) => setLlds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck21">Ll</label>

                                                                    <input type="checkbox" class="btn-check " id="btncheck22" autocomplete="off" onChange={(e) => setLuds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck22">Lu</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck23" autocomplete="off" onChange={(e) => setLexds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck23">Lex</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck24" autocomplete="off" onChange={(e) => setTracds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck24">Tarc</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck25" autocomplete="off" onChange={(e) => setLardds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck25">Lard</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck26" autocomplete="off" onChange={(e) => setArcdds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck26">Arcd</label>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="4">
                                                            <Accordion.Header>Holder Pannel</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                                    {/* HOLDER PANEL  */}

                                                                    <input type="checkbox" class="btn-check" id="btncheck27" autocomplete="off" onChange={(e) => setHdds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck27">Hd</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck28" autocomplete="off" onChange={(e) => setHcds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck28">Hc</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck29" autocomplete="off" onChange={(e) => setHfds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck29">Hf</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck30" autocomplete="off" onChange={(e) => setHclds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck30">Hcl</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck31" autocomplete="off" onChange={(e) => setHftds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck31">Hft</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck32" autocomplete="off" onChange={(e) => setHads(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck32">Ha</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck33" autocomplete="off" onChange={(e) => setHadfds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck33">Hadf</label>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                        <Accordion.Item eventKey="5">
                                                            <Accordion.Header>Other Variable</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                                                    {/* MORE VARIABLES */}

                                                                    <input type="checkbox" class="btn-check" id="btncheck34" autocomplete="off" onChange={(e) => setMds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck34">M</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck35" autocomplete="off" onChange={(e) => setSltds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck35">Slt</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck36" autocomplete="off" onChange={(e) => setIflpds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck36">Iflp</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck37" autocomplete="off" onChange={(e) => setSlds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck37">Sl</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck38" autocomplete="off" onChange={(e) => setShds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck38">Sh</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck39" autocomplete="off" onChange={(e) => setHst(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck39">Hst</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck40" autocomplete="off" onChange={(e) => setIflds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck40">If1</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck41" autocomplete="off" onChange={(e) => setFlds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck41">Fl</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck42" autocomplete="off" onChange={(e) => setLksds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck42">Lks</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck43" autocomplete="off" onChange={(e) => setLkrds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck43">Lkr</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck44" autocomplete="off" onChange={(e) => setMgnds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck44">Mgn</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck45" autocomplete="off" onChange={(e) => setTmgnds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck45">Tmgn</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck46" autocomplete="off" onChange={(e) => setIfldds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck46">Ifld</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck47" autocomplete="off" onChange={(e) => setSdds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck47">Sd</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck48" autocomplete="off" onChange={(e) => setIfds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck48">If</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck49" autocomplete="off" onChange={(e) => setIfrds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck49">Ifr</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck50" autocomplete="off" onChange={(e) => setThsdds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck50">Thsb</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck51" autocomplete="off" onChange={(e) => setThbhds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck51">Thbh</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck52" autocomplete="off" onChange={(e) => setKanrds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck52">Kanr</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck53" autocomplete="off" onChange={(e) => setSlth2ds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck53">Slth2</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck54" autocomplete="off" onChange={(e) => setIffds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck54">Iff</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck55" autocomplete="off" onChange={(e) => setInsds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck55">Ins</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck56" autocomplete="off" onChange={(e) => setIflds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck56">Ifl</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck57" autocomplete="off" onChange={(e) => setIlkds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck57">Ilk</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck58" autocomplete="off" onChange={(e) => setFifds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck58">Fif</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck59" autocomplete="off" onChange={(e) => setClds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck59">Cl</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck60" autocomplete="off" onChange={(e) => setGripds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck60">Grip</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck61" autocomplete="off" onChange={(e) => setGconsds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck61">Gcons</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck62" autocomplete="off" onChange={(e) => setMarginds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck62">Margin</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck63" autocomplete="off" onChange={(e) => setSmds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck63">Sm</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck64" autocomplete="off" onChange={(e) => setSdds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck64">Sd</label>

                                                                    <input type="checkbox" class="btn-check" id="btncheck65" autocomplete="off" onChange={(e) => setTpds(e.target.checked)} />
                                                                    <label class="btn btn-outline-primary" for="btncheck65">Tp</label>
                                                                </div>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>

                                                    <input type="submit" value="Upload Standerds" className="upload-button mt-3" onClick={handleStanderdcard} />
                                                </div>
                                            </div>
                                        </center>
                                    </div>
                                </div>

                                <div style={{ float: 'left', marginTop: '10px' }}>
                                    <center>
                                        <Card style={{ width: '18rem', height: '22rem', border: '1px solid' }}>
                                            <Card.Img variant="top" src={cardimg.preview} style={{ width: '18rem', height: '12rem' }} />
                                            <Card.Body>
                                                <Card.Title>{productname}</Card.Title>
                                                <Card.Text>
                                                    {productdescription}
                                                </Card.Text>
                                                <Button variant="primary">Buy Now</Button>
                                            </Card.Body>
                                        </Card>
                                    </center>
                                </div>
                            </Tab>
                            <Tab eventKey="Standard details" title="Standard details">
                                <Standerdtable />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Standerd;