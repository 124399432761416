import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '../css/modle.css';
import { useSelector } from 'react-redux';
import axios from 'axios';
import lib from '../../config/lib';
import Swal from 'sweetalert2';
import Loading from '../../common/Loading'

const EditAdminMessage = ({ isOpen, closeModal }) => {
    const cartProducts = useSelector(state => state.cart);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');

    const [consumable, setConsumable] = useState('');
    const [standerds, setStanderds] = useState('');
    const [stock, setStock] = useState('');
    const [paymenthistory, setPaymenthistory] = useState('');
    const [jobcard, setJobcard] = useState('');
    const [userdetails, setUserdetails] = useState('');
    const [adminuserdetails, setAdminuserdetails] = useState('');
    const [employersdetails, setEmployersdetails] = useState('');
    const [employerreport, setEmployerreport] = useState('');
    const [jobreport, setJobreport] = useState('');
    const [support, setSupport] = useState('');
    const [setting, setSetting] = useState('');

    const handleSave = async () => {
        const token = localStorage.getItem('token');
        setLoading(true);
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', cartProducts);
        formData.append('name', name);
        formData.append('user', email);

        formData.append("consumable", consumable);
        formData.append("standerds", standerds);
        formData.append("stock", stock);
        formData.append("paymenthistory", paymenthistory);
        formData.append("jobcard", jobcard);
        formData.append("userdetails", userdetails);
        formData.append("adminuser  ", adminuserdetails);
        formData.append("employersdetails", employersdetails);
        formData.append("employerreport", employerreport);
        formData.append("jobreport", jobreport);
        formData.append("support", support);
        formData.append("setting", setting);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.editadmin, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Admin Details Updated Successfully!', 'success');
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    useEffect(() => {
        handleFethdetails();
    }, []);

    const handleFethdetails = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', cartProducts);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.editdisplay, formData);
            if (response.data.status === "success") {
                setName(response.data.name);
                setEmail(response.data.email);
                setConsumable(response.data.consumable);
                setStanderds(response.data.standerds);
                setStock(response.data.stock);
                setPaymenthistory(response.data.paymenthistory);
                setJobcard(response.data.jobcard);
                setUserdetails(response.data.userdetails);
                setAdminuserdetails(response.data.adminuserdetails);
                setEmployersdetails(response.data.employerreport);
                setEmployerreport(response.data.employerreport);
                setJobreport(response.data.jobreport);
                setSupport(response.data.support);
                setSetting(response.data.settings);
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Edit Admin</h4>
                <hr />
                <div class="input-group flex-nowrap">
                    <label> Name</label>
                    <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div class="input-group flex-nowrap mt-3">
                    <label for='email'> Email</label>
                    <input type="text" class="form-control" placeholder="Enter email" aria-label="Username" aria-describedby="addon-wrapping" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className='row mt-3'>
                    <label> All Access</label>
                    <div className='col-md-6'>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setConsumable(e.target.checked)} checked={consumable ? true : false} />
                            {/* </div>onChange={(e) => setAd(e.target.checked)} checked={ad ? true : false} */}
                            Consumable
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setStanderds(e.target.checked)} checked={standerds ? true : false} />
                            {/* onChange={(e) => setRo(e.target.checked)} checked={ro ? true : false} */}
                            Standerds
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setStock(e.target.checked)} checked={stock ? true : false} />
                            {/* onChange={(e) => setDw(e.target.checked)} checked={dw ? true : false}  */}
                            Stock
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setPaymenthistory(e.target.checked)} checked={paymenthistory ? true : false} />
                            {/* onChange={(e) => setMarket(e.target.checked)} checked={market ? true : false} */}
                            Paymenthistory
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setJobcard(e.target.checked)} checked={jobcard ? true : false} />
                            {/* onChange={(e) => setUpdateDetails(e.target.checked)} checked={updatedetails ? true : false}  */}
                            Jobcard
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setUserdetails(e.target.checked)} checked={userdetails ? true : false} />
                            {/* onChange={(e) => setAsignuser(e.target.checked)} checked={asignuser ? true : false}  */}
                            Userdetails
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setAdminuserdetails(e.target.checked)} checked={adminuserdetails ? true : false} />
                            {/* onChange={(e) => setBilling(e.target.checked)} checked={billing ? true : false}  */}
                            Adminuserdetails
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setEmployersdetails(e.target.checked)} checked={employersdetails ? true : false} />
                            {/* onChange={(e) => setViewDetails(e.target.checked)} checked={viewdetails ? true : false}  */}
                            Employersdetails
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setEmployerreport(e.target.checked)} checked={employerreport ? true : false} />
                            {/* onChange={(e) => setUpdateDetails(e.target.checked)} checked={updatedetails ? true : false}  */}
                            Employerreport
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setJobreport(e.target.checked)} checked={jobreport ? true : false} />
                            {/* onChange={(e) => setAsignuser(e.target.checked)} checked={asignuser ? true : false}  */}
                            Jobreport
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setSupport(e.target.checked)} checked={support ? true : false} />
                            {/* onChange={(e) => setBilling(e.target.checked)} checked={billing ? true : false}  */}
                            Support
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setSetting(e.target.checked)} checked={setting ? true : false} />
                            {/* onChange={(e) => setViewDetails(e.target.checked)} checked={viewdetails ? true : false}  */}
                            Settings
                        </label>
                    </div>
                </div>
                <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleSave}>Save</button></div>
                <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleFethdetails}>Fetch Details</button></div>

            </div>
        </div>
    );
};

export default EditAdminMessage;
