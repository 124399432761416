import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Loading from '../../common/Loading';
import axios from 'axios';
import lib from '../../config/lib';
import Swal from 'sweetalert2';

const BillingModalView = ({ isOpen, closeModal, id, rate, handleOrder }) => {
    // const [oldquality, setOldquantity] = useState('');
    const [newquantity, setNewquantity] = useState('');
    const [loading, setLoading] = useState('');
    const [nrate, setnRate] = useState('');

    const handlequantityupdate = async () => {
        setLoading(true);
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        formData.append("id", id);
        formData.append("rate", nrate);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.updaterate, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Rate Updated Successfully!', 'success');
                closeModal();
                handleOrder();
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Something Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Billing Edit Quantity</h4>
                <hr />

                <Loading loading={loading} />

                <div className='col-lg-12 mt-2'>
                    <div>
                        <div>
                            <h4>Old Rate :- {rate}</h4>
                        </div>
                        <br/>
                        <Form.Group className="mb-3">
                            <Form.Label>New Rate</Form.Label>
                            <Form.Control type="text" placeholder="New Rate" onChange={(e) => setnRate(e.target.value)} />
                        </Form.Group>

                        <Button variant="primary" type="submit" onClick={handlequantityupdate} >
                            Submit
                        </Button>

                    </div>
                </div>

            </div>
        </div>
    );
};

export default BillingModalView;
