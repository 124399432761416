import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '../css/modle.css';
import axios from 'axios';
import lib from '../../config/lib';
import Swal from 'sweetalert2';
import Loading from '../../common/Loading';

const AddParameterView = ({ isOpen, closeModal }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [consumable, setConsumable] = useState('');
    const [standerds, setStanderds] = useState('');
    const [stock, setStock] = useState('');
    const [paymenthistory, setPaymenthistory] = useState('');
    const [jobcard, setJobcard] = useState('');
    const [userdetails, setUserdetails] = useState('');
    const [adminuserdetails, setAdminuserdetails] = useState('');
    const [employersdetails, setEmployersdetails] = useState('');
    const [employerreport, setEmployerreport] = useState('');
    const [jobreport, setJobreport] = useState('');
    const [support, setSupport] = useState('');
    const [setting, setSetting] = useState('');

    const handleAddbutton = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append("name", name);
        formData.append("user", email);
        setLoading(true);
        formData.append("consumable", consumable);
        formData.append("standerds", standerds);
        formData.append("stock", stock);
        formData.append("paymenthistory", paymenthistory);
        formData.append("jobcard", jobcard);
        formData.append("userdetails", userdetails);
        formData.append("adminuser", adminuserdetails);
        formData.append("employersdetails", employersdetails);
        formData.append("employerreport", employerreport);
        formData.append("jobreport", jobreport);
        formData.append("support", support);
        formData.append("setting", setting);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.admincreate, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Admin Created Successfully!', 'success');
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }
    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>add Admin</h4>
                <hr />
                <div class="input-group flex-nowrap">
                    <label> Name</label>
                    <input type="text" class="form-control" placeholder="Enter Name" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setName(e.target.value)} />
                </div>
                <div class="input-group flex-nowrap mt-3">
                    <label for='email'> Email</label>
                    <input type="text" class="form-control" placeholder="Enter Email" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className='row mt-3'>
                    <label> All Access</label>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setConsumable(e.target.checked)} />
                            Consumable
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setStanderds(e.target.checked)} />
                            Standerds
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setStock(e.target.checked)} />
                            Stock
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setPaymenthistory(e.target.checked)} />
                            Paymenthistory
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setJobcard(e.target.checked)} />
                            Jobcard
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setUserdetails(e.target.checked)} />
                            Userdetails
                        </label>
                    </div>

                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setAdminuserdetails(e.target.checked)} />
                            Adminuserdetails
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setEmployersdetails(e.target.checked)} />
                            Employersdetails
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setEmployerreport(e.target.checked)} />
                            Employerreport
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setJobreport(e.target.checked)} />
                            Jobreport
                        </label>
                    </div>

                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setSupport(e.target.checked)} />
                            Support
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setSetting(e.target.checked)} />
                            Settings
                        </label>
                    </div>
                </div>
                <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleAddbutton} >Create</button></div>
            </div>
        </div>
    );
};

export default AddParameterView;
