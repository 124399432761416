import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion } from 'react-bootstrap';
import axios from 'axios';
import lib from '../../config/lib';
import Table from 'react-bootstrap/Table';
import HANDLEBOXSNAPLOCKTRIPLELOCK_Coro from '../../assest/images/HANDLEBOXSNAPLOCKTRIPLELOCK_Coro.jpg'
import Loading from '../../common/Loading';
import Button from 'react-bootstrap/Button';


const StandardJobView = ({ isOpen, closeModal, handleOrderidsend }) => {
    const [maincat, setmaincat] = useState('');
    const [lenth, setlenth] = useState('');
    const [width, setwidth] = useState('');
    const [height, setheight] = useState('');
    const [dbtf, setdbtf] = useState('');
    const [dbgf, setdbgf] = useState('');
    const [dbdf, setdbdf] = useState('');
    const [dbmargin, setdbmargin] = useState('');
    const [dblock, setdblock] = useState('');
    const [dbIf, setdbIf] = useState('');
    const [dbwall, setdbwall] = useState('');
    const [dbfg, setdbfg] = useState('');
    const [dbIn, setdbIn] = useState('');
    const [dbinl, setdbinl] = useState('');
    const [dbft, setdbft] = useState('');
    const [dbfl, setdbfl] = useState('');
    const [dblp, setdblp] = useState('');
    const [dbdh, setdbdh] = useState('');
    const [dbM, setdbM] = useState('');
    const [dbdfh, setdbdfh] = useState('');
    const [dbdfd, setdbdfd] = useState('');
    const [dbll, setdbll] = useState('');
    const [dblu, setdblu] = useState('');
    const [dblex, setdblex] = useState('');
    const [dbtarc, setdbtarc] = useState('');
    const [dbarcd, setdbarcd] = useState('');
    const [dbhc, setdbhc] = useState('');
    const [dbhf, setdbhf] = useState('');
    const [dbhcl, setdbhcl] = useState('');
    const [dbhft, setdbhft] = useState('');
    const [dbha, setdbha] = useState('');
    const [dbhadf, setdbhadf] = useState('');
    const [dbslt, setdbslt] = useState('');
    const [dbIflp, setdbIflp] = useState('');
    const [dbsl, setdbsl] = useState('');
    const [dbsh, setdbsh] = useState('');
    const [dbhst, setdbhst] = useState('');
    const [dbifl, setdbifl] = useState('');
    const [dblks, setdblks] = useState('');
    const [dblkr, setdblkr] = useState('');
    const [dbmgn, setdbmgn] = useState('');
    const [dbtmgn, setdbtmgn] = useState('');
    const [dbfmgn, setdbfmgn] = useState('');
    const [dbIfld, setdbIfld] = useState('');
    const [dbsd, setdbsd] = useState('');
    const [dbifr, setdbifr] = useState('');
    const [dbthsb, setdbthsb] = useState('');
    const [dbthbh, setdbthbh] = useState('');
    const [dbkanr, setdbkanr] = useState('');
    const [dbslth2, setdbslth2] = useState('');
    const [dbiff, setdbiff] = useState('');
    const [dbins, setdbins] = useState('');
    const [dbilk, setdbilk] = useState('');
    const [dbfif, setdbfif] = useState('');
    const [dbcl, setdbcl] = useState('');
    const [dbgrip, setdbgrip] = useState('');
    const [dbgcons, setdbgcons] = useState('');
    const [dbsm, setdbsm] = useState('');
    const [dbtp, setdbtp] = useState('');
    const [dblard, setdblard] = useState('');
    const [dbhd, setdbhd] = useState('');
    const [loading, setLoading] = useState(false);
    const [jobno, setJobno] = useState('');
    const [jobname, setJobname] = useState('');
    const [productimg, setproductimg] = useState('');

    // useEffect(() => {
    //     handleStanderddisplayfeth();
    // }, []);

    const handleStanderddisplayfeth = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handleOrderidsend);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.standerddatadisplayfeth, formData);
            setmaincat(response.data.maincat);
            setlenth(response.data.lenth);
            setwidth(response.data.width);
            setheight(response.data.height);
            setdbtf(response.data.dbtf);
            setdbgf(response.data.dbgf);
            setdbdf(response.data.dbdf);
            setdbmargin(response.data.dbmargin);
            setdblock(response.data.dblock);
            setdbIf(response.data.dbIf);
            setdbwall(response.data.dbwall);
            setdbfg(response.data.dbfg);
            setdbIn(response.data.dbIn);
            setdbinl(response.data.dbinl);
            setdbft(response.data.dbft);
            setdbfl(response.data.dbfl);
            setdblp(response.data.dblp);
            setdbdh(response.data.dbdh);
            setdbM(response.data.dbM);
            setdbdfh(response.data.dbdfh);
            setdbdfd(response.data.dbdfd);
            setdbll(response.data.dbll);
            setdblu(response.data.dblu);
            setdblex(response.data.dblex);
            setdbtarc(response.data.dbtarc);
            setdbarcd(response.data.dbarcd);
            setdbhc(response.data.dbhc);
            setdbhf(response.data.dbhf);
            setdbhcl(response.data.dbhcl);
            setdbhft(response.data.dbhft);
            setdbha(response.data.dbha);
            setdbhadf(response.data.dbhadf);
            setdbslt(response.data.dbslt);
            setdbIflp(response.data.dbIflp);
            setdbsl(response.data.dbsl);
            setdbsh(response.data.dbsh);
            setdbhst(response.data.dbhst);
            setdbifl(response.data.dbifl);
            setdblks(response.data.dblks);
            setdblkr(response.data.dblkr);
            setdbmgn(response.data.dbmgn);
            setdbtmgn(response.data.dbtmgn);
            setdbfmgn(response.data.dbfmgn);
            setdbIfld(response.data.dbIfld);
            setdbsd(response.data.dbsd);
            setdbifr(response.data.dbifr);
            setdbthsb(response.data.dbthsb);
            setdbthbh(response.data.dbthbh);
            setdbkanr(response.data.dbkanr);
            setdbslth2(response.data.dbslth2);
            setdbiff(response.data.dbiff);
            setdbins(response.data.dbins);
            setdbilk(response.data.dbilk);
            setdbfif(response.data.dbfif);
            setdbcl(response.data.dbcl);
            setdbgrip(response.data.dbgrip);
            setdbgcons(response.data.dbgcons);
            setdbsm(response.data.dbsm);
            setdbtp(response.data.dbtp);
            setdblard(response.data.dblard);
            setdbhd(response.data.dbhd);
            setJobno(response.data.orderid);
            setJobname(response.data.jobname);
            setproductimg(response.data.productimg);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-contente">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>View job</h4>
                <hr />
                <div className='tableview newordertable'>
                    <Table striped="columns">
                        <thead>
                            <tr>
                                <td colspan="8" className='text-center invoce-tittle'>View Job Card</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={2}>Job no.:<span className='ms-3'>{jobno}</span></td>
                                <td colSpan={2}>Job name.:<span className='ms-3'>{jobname}</span></td>
                                <td colSpan={2}>Material:<span className='ms-3'>{maincat}</span></td>
                                {/* <td colSpan={2}>Quality:<span className='ms-3'>{}</span> </td> */}
                            </tr>
                            {/* <tr>
                                <td colSpan={2}>Sheet thikness:<span className='ms-3'>{''}</span> </td>
                            </tr> */}
                            {/* Main Panne */}
                            <tr>
                                <td colSpan={2}><span>Length</span><span className='ms-3 text-danger'>{lenth}</span></td>
                                <td colSpan={2}><span>width</span><span className='ms-2 text-danger'>{width}</span></td>
                                <td colSpan={2}><span>Height</span><span className='ms-2 text-danger'>{height}</span></td>
                                <td colSpan={2}><span>Tf</span><span className='ms-2 text-danger'>{dbtf}</span></td>
                            </tr>
                            <tr>
                                <td colSpan={2}><span>Gf</span><span className='ms-2 text-danger'>{dbgf}</span></td>
                                <td colSpan={2}><span>Df</span><span className='ms-2 text-danger'>{dbdf}</span></td>
                                <td colSpan={2}><span>Margin</span><span className='ms-2 text-danger'>{dbmargin}</span></td>
                                <td colSpan={2}><span>Lock</span><span className='ms-2 text-danger'>{dblock}</span></td>
                            </tr>
                            <tr>
                                <td colSpan={2}><span>If</span><span className='ms-2 text-danger'>{dbIf}</span></td>
                                <td colSpan={2}><span>Wall</span><span className='ms-2 text-danger'>{dbwall}</span></td>
                                <td colSpan={2}><span>Ftg</span><span className='ms-2 text-danger'>{dbfg}</span></td>
                                <td colSpan={2}><span>In</span><span className='ms-2 text-danger'>{dbIn}</span></td>
                            </tr>
                            <tr>
                                <td colSpan={2}> <span>Inl</span><span className='ms-2 text-danger'>{dbinl}</span></td>
                                {/* 2nd Pannel */}
                                <td colSpan={2}> <span>Ft</span><span className='ms-2 text-danger'>{dbft}</span></td>
                                <td colSpan={2}><span>Fl</span><span className='ms-2 text-danger'>{dbfl}</span></td>
                                <td colSpan={2}><span>Lp</span><span className='ms-2 text-danger'>{dblp}</span></td>
                            </tr>
                            <tr>
                                {/* 3rd Pannel */}
                                <td colSpan={2}><span>Dh</span><span className='ms-2 text-danger'>{dbdh}</span></td>
                                <td colSpan={2}><span>M</span><span className='ms-2 text-danger'>{dbM}</span></td>
                                <td colSpan={2}><span>Dfh</span><span className='ms-2 text-danger'>{dbdfh}</span></td>
                                <td colSpan={2}><span>Dfd</span><span className='ms-2 text-danger'>{dbdfd}</span></td>
                            </tr>
                            <tr>
                                {/* 4rth Pannel */}
                                <td colSpan={2}><span>Ll</span><span className='ms-2 text-danger'>{dbll}</span></td>
                                <td colSpan={2}><span>Lu</span><span className='ms-2 text-danger'>{dblu}</span></td>
                                <td colSpan={2}><span>Lex</span><span className='ms-2 text-danger'>{dblu}</span></td>
                                <td colSpan={2}><span>Tarc</span><span className='ms-2 text-danger'>{dblex}</span></td>
                            </tr>
                            <tr>
                                {/* 4rth Pannel */}
                                <td colSpan={2}><span>Lard</span><span className='ms-2 text-danger'>{dbtarc}</span></td>
                                <td colSpan={2}><span>Arcd</span><span className='ms-2 text-danger'>{dbarcd}</span></td>
                                {/* Holder Pannel */}
                                <td colSpan={2}><span>Hd</span><span className='ms-2 text-danger'>{dbhd}</span></td>
                                <td colSpan={2}><span>Hc</span><span className='ms-2 text-danger'>{dbhc}</span></td>
                            </tr>
                            <tr>
                                <td colSpan={2}><span>Hf</span><span className='ms-2 text-danger'>{dbhf}</span></td>
                                <td colSpan={2}><span>Hcl</span><span className='ms-2 text-danger'>{dbhcl}</span></td>
                                <td colSpan={2}><span>Hft</span><span className='ms-2 text-danger'>{dbhft}</span></td>
                                <td colSpan={2}><span>Ha</span><span className='ms-2 text-danger'>{dbha}</span></td>
                            </tr>
                            <tr>
                                <td colSpan={2}><span>Hadf</span><span className='ms-2 text-danger'>{dbhadf}</span></td>
                                {/* Other Variable */}
                                <td colSpan={2}><span>Ilk</span><span className='ms-2 text-danger'>{dbilk}</span></td>
                                <td colSpan={2}><span>Fif</span><span className='ms-2 text-danger'>{dbfif}</span></td>
                                <td colSpan={2}><span>Cl</span><span className='ms-2 text-danger'>{dbcl}</span></td>
                            </tr>
                            <tr>
                                <td colSpan={2}><span>Grip</span><span className='ms-2 text-danger'>{dbgrip}</span></td>
                                <td colSpan={2}><span>Gcons</span><span className='ms-2 text-danger'>{dbgcons}</span></td>
                                <td colSpan={2}><span>Margin</span><span className='ms-2 text-danger'>{dbmargin}</span></td>
                                <td colSpan={2}><span>Sm</span><span className='ms-2 text-danger'>{dbsm}</span></td>
                            </tr>
                            <tr>
                                <td colSpan={2}><span>Sd</span><span className='ms-2 text-danger'>{dbsd}</span></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>

                        <div className='row border-cus p-2'>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        M:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbM}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Slt:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbslt}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Iflp:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbIflp}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Sl:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbsl}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Sh:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbsh}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Hst:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbhst}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        If1:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbifl}</h3></span>
                            </div>
                        </div>

                        <div className='row border-cus p-2'>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Fl:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbfl}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Lks:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dblks}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Lkr:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dblkr}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Mgn:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbmgn}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Tmgn:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbtmgn}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Ifld:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbIfld}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Sd:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbsd}</h3></span>
                            </div>
                        </div>

                        <div className='row border-cus p-2'>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        If:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbifr}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Ifr:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dblks}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Thsb:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbthsb}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Thbh:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbthbh}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Kanr:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbkanr}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Slth2:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbslth2}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Iff:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbiff}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Ins:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbins}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Ifl:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbifl}</h3></span>
                            </div>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Tp:
                                    </h3>
                                </span>
                                <span><h3 className='mt-0'>{dbtp}</h3></span>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-8'>
                        <img src={lib.imgUrl + '/' + productimg} alt='product images' className='img-fluid' />
                    </div>
                </div>

                <Button onClick={handleStanderddisplayfeth}>Feth Data</Button>

            </div>
        </div>
    );
};

export default StandardJobView;
