import React, { useState } from 'react';
import Modal from './addmodlemsg';
import '../css/modle.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

function Addcatmodal() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="App">
      <Button variant="success" onClick={openModal} >Add Category</Button>
      <Modal isOpen={isModalOpen} closeModal={closeModal} />
    </div>
  );
}

export default Addcatmodal;
