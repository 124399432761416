import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';
import Dies from '../../assest/images/dies.jpg'
import DuplexSelect from './DuplexSelect';
import DuplexSelected from './DuplexSelected';
import { useDispatch, useSelector } from 'react-redux';
import { add, remove } from '../../store/Cartslice';
import axios from 'axios';
import lib from '../../config/lib';
import Button from 'react-bootstrap/Button';
import HANDLEBOXSNAPLOCKTRIPLELOCK_Coro from '../../assest/images/HANDLEBOXSNAPLOCKTRIPLELOCK_Coro.jpg'
import Loading from '../../common/Loading';


const ConsumableJobView = ({ isOpen, closeModal, handleOrderidsend }) => {
    const cartProducts = useSelector(state => state.cart);
    const [loading, setLoading] = useState(false);
    const [productname, setproductname] = useState('');
    const [productdescription, setproductdescription] = useState('');
    const [productimage, setproductImage] = useState('');
    const [customeraddress, setcustomeraddress] = useState('');
    const [catmain, setcatmain] = useState('');
    const [quality, setquality] = useState('');
    const [matrixsize, setmatrixsize] = useState('');
    const [type, settype] = useState('');
    const [quantity, setquantity] = useState('');
    const [rubbersize, setrubbersize] = useState('');
    const [date, setdate] = useState('');
    const [jobmode, setjobmode] = useState('');
    const [time, settime] = useState('');
    const [jobno, setjobno] = useState('');

    // useEffect(() => {
    //     handleKeylineFeth();
    // }, []);

    const handleKeylineFeth = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handleOrderidsend);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.consumablejobdisplay, formData);
            setproductname(response.data.productname);
            setproductdescription(response.data.productdescription);
            setproductImage(response.data.productImage);
            setcustomeraddress(response.data.customeraddress);

            setcatmain(response.data.catmain);
            setquality(response.data.quality);
            setmatrixsize(response.data.matrixsize);
            settype(response.data.type);
            setquantity(response.data.quantity);
            setrubbersize(response.data.rubbersize);
            setdate(response.data.date);
            setjobmode(response.data.jobmode);
            settime(response.data.time);
            setjobno(response.data.orderid);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-contente">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>View job</h4>
                <hr />
                <div className='tableview newordertable'>
                    <Table striped="columns">
                        <thead>
                            <tr>
                                <td colspan="8" className='text-center invoce-tittle'>View Job Card</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={2}>Job no.:<span className='ms-3'>{jobno}</span></td>
                                <td colSpan={2}>Job name.:<span className='ms-3'>{productname}</span></td>
                                <td colSpan={2}>Category:<span className='ms-3'>{catmain}</span> </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Job mode: <span className='ms-3'>{jobmode}</span></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Quality:<span className='ms-3'>{quality}</span> </td>
                                <td colSpan={2}>Matrix size:<span className='ms-3'>{matrixsize}</span></td>
                                <td colSpan={2}>Type:<span className='ms-3'>{type}</span> </td>
                                <td colSpan={2}>Rubber size:<span className='ms-3'>{rubbersize}</span> </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Quantity:<span className='ms-3'>{quantity}</span></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>

                    </div>
                    <div className='col-lg-8'>
                        <img src={lib.imgUrl + '/' + productimage} alt='product images' className='img-fluid' />
                
                    </div>
                </div>

                <Button onClick={handleKeylineFeth}>Feth Data</Button>

            </div>
        </div>
    );
};

export default ConsumableJobView;
