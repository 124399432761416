import React, { useState } from 'react';
import Modal from './InvoiceView';
import '../css/modle.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FiEye } from "react-icons/fi";



function InvoiveModal({ jobno, jobform }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <span className="App">
            <FiEye size={22} onClick={openModal} />
            <Modal isOpen={isModalOpen} closeModal={closeModal} jobno={jobno} jobform={jobform} />
        </span>
    );
}

export default InvoiveModal;
