import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import lib from '../../config/lib';
import Swal from 'sweetalert2';
import Loading from '../../common/Loading';

const ContactPersonView = ({ isOpen, closeModal }) => {
    const [company, setCompany] = useState('');
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [personname, setPersonName] = useState('');
    const [loading, setLoading] = useState(false);

    const handleRaseticket = async () => {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        formData.append("company", company);
        formData.append("name", name);
        formData.append("address", address);
        formData.append("personname", personname);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.markitingaddjob, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Marketing Details Updated!', 'success');
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Contact person details</h4>
                <hr />
                <div className="row">
                    <div className="col-md-12">
                        <div className='text-justify'><label>Company name</label></div>
                        <input type="text" id="input-name" placeholder="company name" onChange={(e) => setCompany(e.target.value)} />
                    </div>
                    <div className="col-md-12">
                        <div className='text-justify'><label>Customer name</label></div>
                        <input type="text" id="input-name" placeholder="customer name" onChange={(e) => setName(e.target.value)} />
                    </div>

                    <div className="col-md-12">
                        <div className='text-justify'><label>Customer address</label></div>
                        <input type="text" id="input-name" placeholder="customer address" onChange={(e) => setAddress(e.target.value)} />
                    </div>

                    <div className="col-md-12">
                        <div className='text-justify'><label>Contact person name</label></div>
                        <input type="text" id="input-name" placeholder="contact person name" onChange={(e) => setPersonName(e.target.value)} />
                    </div>
                    <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleRaseticket} >Marketing Job</button> </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPersonView;
