import { ConfigureStore, configureStore } from "@reduxjs/toolkit";
import React from "react";
import Cartslice from "./Cartslice";

const store = configureStore({
    reducer :{
        cart: Cartslice,
    }
});

export default store;