import React, { Fragment, useState, useEffect } from "react";
import Table from 'react-bootstrap/Table';
import ReactPaginate from "react-paginate";
import Button from 'react-bootstrap/Button';
import FreshOrderModal from '../components/startJobModal/FreshOrderModal'
import CustomOrderJob from './jobcardDisplay/CustomOrderJob'
import AdditionalDetails from '../components/jobcardDisplay/AdditionalDetails'
import DescriptionJob from './employerModal/DescriptionJob'
import { Form } from "react-bootstrap";
import axios from 'axios';
import lib from "../config/lib";
import Workby from '../components/employerModal/Workby';
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { RiDeleteBin4Fill } from "react-icons/ri"
import Loading from "../common/Loading";
import Adminholdjob from './Adminholdjob';
import DeleteJob from './DeleteJob';

function QuatationTable() {

    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleQuatation();
    }, []);

    const handleQuatation = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'All');

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.quationtable, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }


    // Calculate the total number of pages
    // const pageCount = Math.ceil(data.length / itemsPerPage);
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {

        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData([]);

    const handleFilter = async (e) => {
        var filter = e.target.value;
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', filter);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.quationtable, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const [type, setType] = useState('');

    const handleSearch = async () => {
        const token = localStorage.getItem('token');
        setLoading(true);
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'quotation');
        formData.append('search', type);


        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.searchonclick, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false)
        }
    }

    const handleConvertOrder = async (jobid) => {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        formData.append("jobno", jobid);
        setLoading(true)
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.convertjob, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Order Converted Successfully!', 'success');
                setLoading(false)
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Allready Converted',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false)
        }
    }

    return (
        <Fragment>
            <div className="row align-items-center">
                <Loading loading={loading} />
                <div className="col-lg-3 col-md-10 d-flex">
                    <input type="text" class="form-control" placeholder="Type to search..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setType(e.target.value)} />
                    <Button variant="success" style={{ margin: '10px' }} onClick={handleSearch} >Search</Button>
                </div>
                <div className="col-lg-2 ms-lg-auto col-md-2">
                    <div className="me-2">
                        <Form.Select aria-label="Default select example" onChange={handleFilter}>
                            <option disabled>Select filter</option>
                            <option value="All">All</option>
                            <option value="Pending">Pending</option>
                            <option value="Completed">Complete</option>
                            <option value="pendingfrom">Pending From 2 Days</option>
                        </Form.Select>
                    </div>
                </div>
                <div className="col-lg-1 ms-lg-auto col-md-2">
                    <div className="me-2">
                        <Link to="/QuataionJob" state={{ handleOrderidsend: '', handleProptype: 'Quation' }}><Button variant="success" >Add job</Button></Link>
                    </div>
                </div>
            </div>
            <Table striped bordered hover >
                <thead>
                    <tr>
                        <th>Job No</th>
                        <th>Job Name</th>
                        <th>Order type</th>
                        <th>Product image</th>
                        <th>Customer code</th>
                        <th>Customer name</th>
                        <th>Additional Details</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Internal Status</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPageData && currentPageData.map((item) => (
                        <tr>
                            <td>{item.orderid}</td>
                            <td>{item.jobname}</td>
                            <td>{item.ordertype}</td>
                            <td><a href={lib.imgUrl + '/' + item.productimg} target="_blank" >View Image</a></td>
                            <td>{item.sid}</td>
                            <td>{item.custname}</td>
                            <td><AdditionalDetails handlesendJobNumberView={item.orderid} /></td>
                            <td>{item.date}</td>
                            <td>{item.time}</td>
                            <td>{item.internalstatus}</td>
                            <td>{item.status}</td>
                            <td className="d-flex"><FreshOrderModal handleStartjobid={item.orderid} handleJobfrom={"order"} />
                            <span className="ms-2"><Adminholdjob handleJobno={item.orderid} handleSubrole={item.internalstatus}/></span>
                                <span className="ms-2"><CustomOrderJob handleOrderidsend={item.orderid} handleSendsid={item.sid} /></span>
                                <span className="ms-2"><Button variant="success" onClick={() => handleConvertOrder(item.orderid)}>Convert to job</Button></span>
                                <span className="ms-2"><DescriptionJob handleDescriptionorder={item.orderid} /></span>
                                <span className="ms-2"><Workby handlejobnumber={item.orderid} /></span>
                                <span className="ms-2"><DeleteJob handleJobnumber={item.orderid} handleJobfrom={"order"}/></span>
                            </td>
                        </tr>
                    ))}
                </tbody>

            </Table>
            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
            />
        </Fragment>
    )
}
export default QuatationTable;
