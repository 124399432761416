import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';
import Dies from '../../assest/images/dies.jpg'
import DuplexSelect from './DuplexSelect';
import DuplexSelected from './DuplexSelected';
import axios from 'axios';
import lib from '../../config/lib';
import { useDispatch, useSelector } from 'react-redux';
import { add, remove } from '../../store/Cartslice';
import Button from 'react-bootstrap/Button';
import Loading from '../../common/Loading';

const JobcardMessage = ({ isOpen, closeModal, handleOrderidsend, handleSendsid }) => {
    const cartProducts = useSelector(state => state.cart);
    const [loading, setLoading] = useState(false);
    const [jobhumber, setJobnumber] = useState('');
    const [cat, setCat] = useState('');
    const [boxstyle, setBoxstyle] = useState('');
    const [orderid, setOrderid] = useState('');
    const [productimage, setProductImage] = useState('');
    const [date, setDate] = useState('');
    const [status, setStatus] = useState('');

    const [designerimg, setDesignerImg] = useState('');

    useEffect(() => {
        handleSampleOrderFeth();
        handleDesignerimg();
    }, []);

    const handleSampleOrderFeth = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handleOrderidsend);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.samplejobdisplay, formData);
            if (response.data.status == "success") {
                setJobnumber(response.data.jobno);
                setCat(response.data.cat);
                setBoxstyle(response.data.boxstyle);
                setOrderid(response.data.orderid);
                setProductImage(response.data.productimage);
                setDate(response.data.date);
                setStatus(response.data.tracker);
                handleDesignerimg();
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleDesignerimg = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handleOrderidsend);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.designerimg, formData);
            if (response.data.status == "success") {
                setDesignerImg(response.data.designerimg);
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-contentses">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>View job</h4>
                <hr />

                <div className='col-md-12 d-flex justify-content-center mb-3' >
                    <span className=' invoce-tittle'>View Job Card</span>
                </div>
                <div className='col-md-4'>
                    <span style={{ fontSize: 15 }}>Customer Image</span><span className='ms-2'><a href={lib.imgUrl + '/' + productimage} target="_blank" >View Image</a></span>
                </div>

                <div className='col-md-4'>
                    <span style={{ fontSize: 15 }}>Designer Image</span><span className='ms-2'><a href={lib.imgUrl + '/' + designerimg} target="_blank" >View Image</a></span>
                </div>

                <div className='col-md-6 mt-2'>
                    <label>Job no</label>
                    <input className='form-control' placeholder='Job no' value={jobhumber} disabled />
                </div>

                <div className='col-md-6 mt-2'>
                    <label>Customer code</label>
                    <input className='form-control' placeholder='customer code' value='8465454' disabled />
                </div>

                <div className='col-md-6 mt-2'>
                    <label>Category</label>
                    <input className='form-control' placeholder='Category' value={cat} disabled />
                </div>

                <div className='col-md-6 mt-2'>
                    <label>Box style</label>
                    <input className='form-control' placeholder='Box style' value={boxstyle} disabled />
                </div>

                <div className='col-md-6 mt-2'>
                    <label>Date</label>
                    <input className='form-control' placeholder='Date' value={date} disabled />
                </div>

                <div className='col-md-6 mt-2'>
                    <label>Status</label>
                    <input className='form-control' placeholder='Status' value={status} disabled />
                </div>

                <div className='col-md-6 mt-2 d-flex align-items-center'>
                    <span style={{ fontSize: 15 }}>Priting Machine Data</span><span className='ms-2'><DuplexSelected handleSendsid={handleSendsid} /></span>
                </div>
                <div className='col-md-6 mt-2 d-flex align-items-center'>
                    <span style={{ fontSize: 15 }}>Die Machine Data</span><span className='ms-2'><DuplexSelect handleSendsid={handleSendsid} /></span>
                </div>

                {/* <div className='tableview'>
                    <Table striped="columns">
                        <thead>
                            <tr>
                                <td colspan="4" className='text-center invoce-tittle'>View Job Card</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={2}>Customer Image</td>
                                <td colSpan={2}><a href={lib.imgUrl + '/' + productimage} target="_blank" >View Image</a></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Designer Image </td>
                                <td colSpan={2}><a href={lib.imgUrl + '/' + designerimg} target="_blank" >View Image</a></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Other Image <div style={{ width: 200, height: 200 }}><img src='#' alt='Product' /></div> </td>
                                <td colSpan={2}></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Job no.</td>
                                <td colSpan={2}>{jobhumber}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Customer code </td>
                                <td colSpan={2}>8465454</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Category </td>
                                <td colSpan={2}>{cat}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Box style </td>
                                <td colSpan={2}>{boxstyle}</td>
                            </tr>

                            <tr>
                                <td colSpan={2}> Date</td>
                                <td colSpan={2}>{date}</td>
                            </tr>

                            <tr>
                                <td colSpan={2}>Status </td>
                                <td colSpan={2} className='table-color-cu'>{status}</td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Priting Machine Data</td>
                                <td colSpan={2} ><DuplexSelected handleSendsid={handleSendsid} /></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Die Machine Data</td>
                                <td colSpan={2} ><DuplexSelect handleSendsid={handleSendsid} /></td>
                            </tr>
                            
                            <tr>
                                <td colSpan={2} style={{ color: 'white' }}>------------------------------------------------------------</td>
                                <td colSpan={2} style={{ color: '#F2F2F2' }}>------------------------------------------------------------</td>
                            </tr>

                        </tbody>
                    </Table>
                </div> */}
            </div>
        </div>
    );
};

export default JobcardMessage;
