import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '../css/modle.css';
import { useSelector } from 'react-redux';
import axios from 'axios';
import lib from '../../config/lib';
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { remove } from '../../store/Cartslice';
import Loading from '../../common/Loading';

const SupportModalView = ({ isOpen, closeModal }) => {
    const cartProducts = useSelector(state => state.cart);
    const [reply, setReply] = useState('');
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const handleSendmessage = async () => {
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('reply', reply);
        formData.append('email', cartProducts);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.ticketreply, formData);
            if (response.data.status === "success") {
                dispatch(remove(cartProducts));
                Swal.fire('Success', 'Ticket Replyed successfully!', 'success');
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }


    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Support Ticket</h4>
                <hr />
                <div class="input-group flex-nowrap">
                    <label> Message</label>
                    <textarea id="message-box" placeholder="Enter Reply......" onChange={(e) => setReply(e.target.value)}></textarea>
                </div>
                <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleSendmessage}>Send </button> </div>
            </div>
        </div>
    );
};

export default SupportModalView;
