import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Table from 'react-bootstrap/Table';
import ReactPaginate from "react-paginate";
import axios from 'axios';
import lib from '../../config/lib';
import { RiDeleteBin4Fill } from "react-icons/ri"
import Loading from '../../common/Loading';


const ViewStockView = ({ isOpen, closeModal, jobno }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleOrder();
    }, []);

    const handleOrder = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', jobno);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.stockdisplayemploy, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    // Calculate the total number of pages
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData([]);

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>View Stock</h4>
                <hr />
                <Table striped bordered hover >
                    <thead>
                        <tr>
                            <th>Item Name</th>
                            <th>Quantity</th>
                            <th>Varient</th>
                            <th>Uom</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPageData && currentPageData.map((item) => (
                            <tr>
                                <td>{item.item}</td>
                                <td>{item.quantity}</td>
                                <td>{item.varient}</td>
                                <td>{item.umo}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                />
            </div>
        </div>
    );
};

export default ViewStockView;
