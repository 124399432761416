import React, { useState, useEffect } from 'react';
import './css/Invoice.css';
import axios from 'axios';
import lib from '../config/lib';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import Loading from '../common/Loading';
import BillingEditquantity from './jobcardDisplay/BillingEditquantity';
import BillingEditRate from './jobcardDisplay/BillingEditRate';

const Invoice = ({ jobno, jobform }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [extraamount, setExtraamount] = useState('0');
    const [description, setDescription] = useState('');

    useEffect(() => {
        handleOrder();
    }, []);

    const handleOrder = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', jobno);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.stockdisplayemploy, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }



    // const calculateTotal = () => {
    //   if (!data) {
    //     return 0;
    //   }

    //   return data.reduce((total, item) => total + item.quantity * item.rate, 0);
    //   // const subtotal = data.reduce((total, item) => total + item.quantity * item.rate, 0);
    //   // const gst = subtotal * 18 / 100;
    //   // const grandTotal = subtotal + gst + (extraamount ? parseFloat(extraamount) : 0);

    //   // return grandTotal;
    // }

    const GrandTotal = () => {
        if (!data) {
            return 0;
        }

        // return data.reduce((total, item) => total + item.quantity * item.rate, 0);
        const subtotal = data.reduce((total, item) => total + item.quantity * item.rate, 0);
        const gst = subtotal * 18 / 100;
        const grandTotal = subtotal + (extraamount ? parseFloat(extraamount) : 0);

        return grandTotal;
    }

    const handleSubmitdata = async () => {

        const amount = GrandTotal();
        setLoading(true);
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        formData.append('ordertype', jobform);
        formData.append('orderid', jobno);
        formData.append('amount', amount);
        formData.append('description', description);
        formData.append('extraamount', extraamount);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.paymentrecordsubmit, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Payment Updated Successfully!', 'success');
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Something Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return (
        <div className="invoice-container">
            <Loading loading={loading} />
            <div className="content">
                <table>
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data && data.map((item, index) => (
                            <tr key={index}>
                                <td>{item.item}</td>
                                <td>
                                    {item.quantity}
                                    <br />
                                    <BillingEditquantity id={item.id} quantity={item.quantity} item={item.item} handleOrder={handleOrder} />
                                </td>
                                <td>
                                    ₹{item.rate}
                                    <br />
                                    <BillingEditRate id={item.id} rate={item.rate} handleOrder={handleOrder} />
                                </td>
                                <td>₹{item.quantity * item.rate}</td>
                            </tr>
                        ))}
                        <tr>
                            <td><label>Extra Amount Description</label><input type='text' onChange={(e) => setDescription(e.target.value)} /></td>
                            <td><label>Extra Amount Description</label><input type='text' onChange={(e) => setExtraamount(e.target.value)} /></td>
                            <td></td>
                            <td><p className="total" style={{ fontSiz: '20px' }}>Extra Amount ₹{extraamount}</p></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="footer">
                <p className="total">Total: ₹{GrandTotal()}</p>
                <p className="total">GST: ₹{GrandTotal() * 18 / 100}</p>
                <p className="total">Grand Total: ₹{GrandTotal() * 18 / 100 + GrandTotal()}</p>
            </div>
            <div className='p-2'>
                <Button variant='success' onClick={handleSubmitdata}>Submit Data</Button>
            </div>
        </div>
    );
};

export default Invoice;

