import React, { useState } from 'react';
import Modal from './BillingEditrateMsg';
import '../css/modle.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';


function BillingModal({ id, rate, handleOrder }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <span className="App">
            <a style={{fontSize: '8.5px', color: 'blue', cursor: 'pointer'}} onClick={openModal}>Edit Rate</a>
            <Modal isOpen={isModalOpen} closeModal={closeModal} id={id} rate={rate} handleOrder={handleOrder} />
        </span>
    );
}

export default BillingModal;
