import React, { useState } from 'react';
import Modal from './DespatchMessage';
import '../css/modle.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';


function Marketing({ handleMarket }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <span className="App">
            <Button variant="success" className="card-button" onClick={openModal} >Despatch</Button>
            <Modal isOpen={isModalOpen} closeModal={closeModal} handleMarket={handleMarket} />
        </span>
    );
}

export default Marketing;
