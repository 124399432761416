import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Autocomplete } from "@fdefelici/react-bootstrap-combobox";


class Comboboxes extends React.Component {
    state = {
      
        disabled: false,
        isLoading: false,
        trigResetAutocomplete: undefined,
        newValueAutocomplete: "default",
        
        dataExample: [
            "Apple",
            "Banana",
            "Citrus",
            "Grapefruit",
            "Lime",
            "Mandarin",
            "Mango",
            "Melon",
            "Watermelon"
        ]
    };

    render() {
        return (
            <React.Fragment>
                <span>
                    <Autocomplete
                        id="1234978"
                        labels={{
                            "cap.placeholder": "Search...",
                            "lst.empty": "Empty"
                        }}
                        maxDropdownItems={5}
                        dropdownWidth={"350px"}
                        dropdownAlign={"right"}
                        searchFun={(text, callback) => {
                            setTimeout(() => {
                                callback(
                                    [
                                        "Grapefruit",
                                        "Mandarin",
                                        "Melon",
                                        "Watermelon"
                                    ].filter(each => {
                                        return each.toLowerCase().includes(text.toLowerCase());
                                    })
                                );
                            }, 1000);
                        }}
                        onChangeAfterCharNum={3}
                        onSelection={selected => {
                            this.setState({ selectedCombobox7: selected });
                        }}
                        delay={1}
                    />
                    
                </span>
            </React.Fragment>
        );
    }
}

export default Comboboxes;

// import React, { useState, useEffect } from 'react';
// import Select from 'react-select';
// import axios from 'axios';

// const Comboboxes = () => {
//   // State to manage the selected value and options
//   const [selectedOption, setSelectedOption] = useState(null);
//   const [options, setOptions] = useState([]);

//   // useEffect to fetch options when the component mounts
//   useEffect(() => {
//     const fetchOptions = async () => {
//       try {
//         const response = await axios.get('your-api-endpoint'); // Replace 'your-api-endpoint' with your actual API endpoint
//         setOptions(response.data); // Assuming your API response contains an array of objects with 'value' and 'label' properties
//       } catch (error) {
//         console.error('Error fetching options:', error);
//       }
//     };

//     fetchOptions();
//   }, []); // Empty dependency array ensures that this effect runs only once when the component mounts

//   // Handler function to update the selected value
//   const handleSelectChange = (selectedOption) => {
//     setSelectedOption(selectedOption);
//   };

//   return (
//     <div>
//       <label>Select a value:</label>
//       <Select
//         value={selectedOption}
//         onChange={handleSelectChange}
//         options={options}
//         placeholder="Search or select an option"
//       />

//       <p>
//         Selected Value: {selectedOption ? selectedOption.label : 'None selected'}
//       </p>
//     </div>
//   );
// };

// export default Comboboxes;