import React, { useState } from 'react';
import Modal from './EmplyeeChangepassView';
import '../css/modle.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GoPasskeyFill } from "react-icons/go";


function EmployeeChangepass({ empid }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <span className="App">
            {/* <Button variant="success" className="card-button" onClick={openModal} >Change password</Button> */}
            <GoPasskeyFill size={22} onClick={openModal} color='black'/>
            <Modal isOpen={isModalOpen} closeModal={closeModal} empid={empid} />
        </span>
    );
}

export default EmployeeChangepass;
