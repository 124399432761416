'useclient'
import React, { Fragment, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assest/css/custom.css';
import './assest/css/gradient.css';
import Header from './common/header';
import Topmenu from './common/Topmenu';
import Sidemenu from './common/sidemenu';
import clasess from './common/css/Content.module.css';
import './components/stockModal/stockmodal'
import axios from 'axios';
import Swal from 'sweetalert2';
import './components/css/modle.css';
import Table from 'react-bootstrap/Table';
import ReactPaginate from "react-paginate";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { RiDeleteBin4Fill } from "react-icons/ri"
import { FcCancel, FcCheckmark } from 'react-icons/fc'
import lib from './config/lib';
import Modal from './components/addPaymentModal/addPaymentMessage';
import { useDispatch } from 'react-redux';
import { add, remove } from './store/Cartslice';
import { FiEye } from "react-icons/fi";
import InvoiveModal from './components/jobcardDisplay/InvoiveModal'
import Loading from './common/Loading';

function PaymentHistory() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState([]);
    const itemsPerPage = 5;
    const [data, setData] = useState([]);

    useEffect(() => {
        handleOrder();
    }, []);

    const handleOrder = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.paymentdetails, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    // Calculate the total number of pages
    // const pageCount = Math.ceil(data.length / itemsPerPage);
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData([]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [id, setId] = useState('');
    const [wallet, setWallet] = useState('');

    const handleWalletFeth = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', id);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.walletbalance, formData);
            setWallet(response.data.wallet);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [type, setType] = useState('');

    const handleSearch = async () => {
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'paymenthistory');
        formData.append('search', type);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.searchonclick, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <div className={clasess.container1}>
                <Header />
                <Sidemenu />
                <div>
                    <div className={clasess.container}>
                        <div style={{ width: '100%', height: 'auto', marginTop: '20px' }}>
                            <Loading loading={loading} />
                            <div className="row justify-content-between">
                                <h3 className="ms-2">Payments</h3>
                            </div>
                            <hr />
                            <Tabs
                                defaultActiveKey="Payment"
                                transition={false}
                                id="noanim-tab-example"
                                className="mb-3"
                                style={{ fontSize: '20px' }}
                            >
                                <Tab eventKey="Payment" title="Payment">
                                    <div className='col-lg-3 d-flex'>
                                        <input type="text" class="form-control" placeholder="Type to search..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setType(e.target.value)} />
                                        <Button variant="success" style={{ margin: '10px' }} onClick={handleSearch} >Search</Button>
                                    </div>
                                    <Table striped bordered hover >
                                        <thead>
                                            <tr>
                                                <th>Payment Id</th>
                                                <th>Order Type</th>
                                                <th>Order Id</th>
                                                <th>Amount</th>
                                                <th>Date</th>
                                                <th>Status</th>
                                                {/* <th>Action</th> */}
                                                <th>View Invoice</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentPageData && currentPageData.map((item) => (
                                                <tr>
                                                    <td>{item.id}</td>
                                                    <td>{item.ordertype}</td>
                                                    <td>{item.orderid}</td>
                                                    <td>{item.amount}</td>
                                                    <td>{item.date}</td>
                                                    <td>{item.status}</td>
                                                    <td><InvoiveModal jobno={item.orderid} /></td>
                                                    {/* <td className="center d-flex">
                                                        <FcCheckmark size={20} onClick={() => handleapprove(item.id)} />
                                                        <span className='ms-2'> <FcCancel size={20} onClick={() => handlereject(item.id)} /></span>
                                                    </td> */}
                                                </tr>
                                            ))}
                                        </tbody>

                                    </Table>
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageChange}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                    />
                                </Tab>
                                <Tab eventKey="History" title="History">
                                    <div className='row justify-content-center mb-lg-4'>
                                        <div className='col-lg-3 '>
                                            <input type="text" class="form-control" placeholder="Type to search..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setId(e.target.value)} />
                                            <Button variant="success" onClick={handleWalletFeth}>Search</Button>
                                            <div style={{ float: 'Right', }}>
                                                <Button variant="success" onClick={openModal} >Add Payment</Button>
                                                <Modal isOpen={isModalOpen} closeModal={closeModal} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row justify-content-center mb-3'>
                                        <div className='col-lg-3 p-3 bg-custom round'>
                                            <div className='border p-3 round'>
                                                <div className='mb-3 '><label>Amount</label> <span id=''>{wallet}</span></div>
                                                {/* <div className='mb-3 '><label>Credit Amount</label> <span id='' style={{ color: 'red', }}>5000</span></div> */}

                                            </div>
                                        </div>
                                    </div>
                                    {/* <Table striped bordered hover >
                                        <thead>
                                            <tr>
                                                <th>Order Type</th>
                                                <th>Order Id</th>
                                                <th>Amount</th>
                                                <th>Date</th>
                                                <th>Trans Type</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {currentPageData.map((item) => (
                                            <tr>
                                                <td>{item.ordertype}</td>
                                                <td>{item.orderid}</td>
                                                <td>{item.amount}</td>
                                                <td>{item.date}</td>
                                                <td>{item.transtype}</td>
                                                <td>{item.status}</td>
                                                <td><AddPayment /></td>
                                            </tr>
                                        ))}
                                        </tbody>

                                    </Table>
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageChange}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                    /> */}
                                </Tab>

                            </Tabs>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default PaymentHistory    