import React, { useEffect, useState } from 'react';
import clasess from './css/Content.module.css';
import Bargraph from './bargraph';
import Graph from './graph';
import lib from '../config/lib';
import axios from 'axios';
import Loading from './Loading';

const Topmenu = () => {
	const [sample, setSample] = useState('');
	const [totalorder, setTotalOrder] = useState('');
	const [keyline, setKeyline] = useState('');
	const [consumable, setConsumable] = useState('');
	const [rulechange, setRulechange] = useState('');
	const [quation, setQuation] = useState('');
	const [loading, setLoading] = useState(false);
	const [totaluser, setTotaluser] = useState('');
	const [totalemploy, setTotalemploy] = useState('');
	const [supportticket, setSupportticket] = useState('');

	useEffect(() => {
		handleSample();
		handleOrder();
		handleKeyline();
		handleConsumable();
		handleRulechange();
		handleQuation();
		handleTotaluser();
		handleTotalemploy();
		handleTotalsupport();
	}, []);

	const handleSample = async () => {

		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('apikey', token);
		setLoading(true);
		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.totalsample, formData);
			setSample(response.data.order);
			setLoading(false);
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			setLoading(false);
		}
	}

	const handleOrder = async () => {

		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('apikey', token);
		setLoading(true);
		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.totalorders, formData);
			setTotalOrder(response.data.order);
			setLoading(false);
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			setLoading(false);
		}
	}

	const handleKeyline = async () => {
		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('apikey', token);
		setLoading(true);
		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.totalkeylines, formData);
			setKeyline(response.data.order);
			setLoading(false);
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			setLoading(false);
		}
	}

	const handleConsumable = async () => {

		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('apikey', token);
		setLoading(true);
		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.totalconsumable, formData);
			setConsumable(response.data.order);
			setLoading(false);
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			setLoading(false);
		}
	}

	const handleRulechange = async () => {

		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('apikey', token);
		setLoading(true);
		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.totalrulechange, formData);
			setRulechange(response.data.order);
			setLoading(false);
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			setLoading(false);
		}
	}

	const handleQuation = async () => {

		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('apikey', token);
		setLoading(true);
		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.totalquation, formData);
			setQuation(response.data.order);
			setLoading(false);
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			setLoading(false);
		}
	}

	const handleTotaluser = async () => {

		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('apikey', token);
		setLoading(true);
		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.totaluser, formData);
			setTotaluser(response.data.order);
			setLoading(false);
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			setLoading(false);
		}
	}

	const handleTotalemploy = async () => {

		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('apikey', token);
		setLoading(true);
		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.totalemploy, formData);
			setTotalemploy(response.data.order);
			setLoading(false);
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			setLoading(false);
		}
	}

	const handleTotalsupport = async () => {

		const token = localStorage.getItem('token');
		const formData = new FormData();
		formData.append('apikey', token);
		setLoading(true);
		try {
			const response = await axios.post(lib.apiURL + '/' + lib.apis.totalsupport, formData);
			setSupportticket(response.data.order);
			setLoading(false);
		} catch (error) {
			// Handle error if the request failed
			console.log("Some error has occoured kindly check the parameters and try again");
			setLoading(false);
		}
	}


	return (

		<div className={clasess.container}>
			<Loading loading={loading} />
			<div className='row'>
				<div className='col-md-12'>
					<div className={clasess.grid_container}>
						<div className={clasess.item_widget}>
							<div className='d-flex justify-content-center flex-wrap'>
								<div className={clasess.item_block}>
									<div className={`${clasess.icon_block} ${clasess.green_block}`}>
										<span className={clasess.item_icon} >
											<span className={clasess.contact_sl}>
												<a href='myorder'>Messages</a>
											</span>
										</span>
									</div>
									<h3>{totalorder}</h3>
									<p>
										Total Orders
									</p>
								</div>
								<div className={clasess.item_block}>
									<div className={`${clasess.blue_block} ${clasess.icon_block}`}>
										<span className={clasess.item_icon}>
											<span className={clasess.cost_sl}><a href="myorder">Credits</a></span>
										</span>
									</div>
									<h3>{rulechange}</h3>
									<p>
										Rule Change
									</p>
								</div>
								<div className={clasess.item_block}>
									<div className={`${clasess.orange_block} ${clasess.icon_block}`}>
										<span className={clasess.item_icon}>
											<span className={clasess.basket_sl}><a href="myorder">New Order</a></span>
										</span>
									</div>
									<h3>{consumable}</h3>
									<p>
										Consumable
									</p>
								</div>
								<div className={clasess.item_block}>
									<div className={`${clasess.gray_block} ${clasess.icon_block}`}>
										<span className={clasess.item_icon}>
											<span className={clasess.lightbulb_sl}><a href="myorder">Support Ticket</a></span>
										</span>
									</div>
									<h3>{quation}</h3>
									<p>
										Quation
									</p>
								</div>
								<div className={clasess.item_block}>
									<div className={`${clasess.violet_block} ${clasess.icon_block}`}>
										<span className={clasess.item_icon}>
											<span className={clasess.finished_work_sl}><a href="myorder">Articles</a></span>
										</span>
									</div>
									<h3>{keyline}</h3>
									<p>
										Keyline
									</p>
								</div>
								<div className={clasess.item_block}>
									<div className={`${clasess.tur_block} ${clasess.icon_block}`}>
										<span className={clasess.item_icon}>
											<span className={clasess.administrative_docs_sl}><a href="myorder">Pages</a></span>
										</span>
									</div>
									<h3>{sample}</h3>
									<p>
										Sample
									</p>
								</div>
								<div className={clasess.item_block}>
									<div className={`${clasess.brown_block} ${clasess.icon_block}`}>
										<div className={clasess.item_icon}>
											<span className={clasess.communication_sl}><a href="#">Unique Vistors</a></span>
										</div>
									</div>
									<h3>{totaluser}</h3>
									<p>
										Total User
									</p>
								</div>
								<div className={clasess.item_block}>
									<div className={`${clasess.brown_block} ${clasess.icon_block}`}>
										<div className={clasess.item_icon}>
											<span className={clasess.communication_sl}><a href="employersdetails">Unique Vistors</a></span>
										</div>
									</div>
									<h3>{totalemploy}</h3>
									<p>
										Total Employ
									</p>
								</div>
								<div className={clasess.item_block}>
									<div className={`${clasess.brown_block} ${clasess.icon_block}`}>
										<div className={clasess.item_icon}>
											<span className={clasess.communication_sl}><a href="support">Unique Vistors</a></span>
										</div>
									</div>
									<h3>{supportticket}</h3>
									<p>
										Support Tickets
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='row mt-4' style={{ borderRadius: 5, background: 'white' }}>
				<div className='col-lg-6 col-md-12  '>
					<span><Bargraph /></span>
				</div>
				<div className='col-lg-6 col-md-12 d-flex justify-content-center  ' style={{ borderRadius: 5, background: 'white' }}>
					<span>	<span><Graph /></span></span>
				</div>
			</div>
		</div>
	)
}

export default Topmenu;