import React, { Fragment, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Swal from 'sweetalert2';
import Table from 'react-bootstrap/Table';
import ReactPaginate from "react-paginate";
import Button from 'react-bootstrap/Button';
import lib from '../config/lib';
import Rate from './customerrate/Rate';
import { RiDeleteBin4Fill } from "react-icons/ri";
import Form from 'react-bootstrap/Form';
import { BiSolidEdit } from 'react-icons/bi'
import EditRate from './customerrate/EditRate'
import Loading from '../common/Loading';


function RateTable() {
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [companyname, setCompanyName] = useState([]);

    const [companynameinsert, setCompanyNameinsert] = useState('');

    // Calculate the total number of pages
    // const pageCount = Math.ceil(data.length / itemsPerPage);
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };
    const currentPageData = getCurrentPageData();

    useEffect(() => {
        // handleRate();
        handleCompanyName();
    }, []);

    const handleRate = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('companyname', companynameinsert);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.fethratedetails, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleCompanyName = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.userfethdata, formData);
            setCompanyName(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleDelete = async (id) => {
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', id);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.deleteRate, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Deleted Successfully!', 'success');
                handleRate();
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Something Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return (
        <Fragment>

            <div className="row align-items-center">
                <Loading loading={loading} />
                <label className='mb-1 ms-2'>Company</label>
                <div className="col-lg-3 col-md-9 d-flex ms-2">
                    <Form.Select aria-label="Default select company" onChange={(e) => setCompanyNameinsert(e.target.value)} className='form-control'>
                        <option selected>Select Company Name</option>
                        {companyname && companyname.map((item) => (
                            <option value={item.cname}>{item.cname}</option>
                        ))}
                    </Form.Select>
                    <Button onClick={handleRate} variant="success" style={{ margin: '10px' }} className='mt-1'>Search</Button>
                </div>
                <div className="col-lg-1 mb-2 ms-lg-auto col-md-2">
                    <div className="me-2">
                        <Rate />
                    </div>
                </div>
            </div>
            <Table striped bordered hover >
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Quality</th>
                        <th>Uom</th>
                        <th>Rate</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentPageData && currentPageData.map((item) => (
                        <tr>
                            <td>{item.item}</td>
                            <td>{item.quality}</td>
                            <td>{item.umo}</td>
                            <td>{item.rate}</td>
                            <td className="d-flex">
                                <RiDeleteBin4Fill size={20} onClick={() => handleDelete(item.id)} />
                                <span className='ms-2'> <EditRate id={item.id} handleRate={handleRate} /></span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
            />
            {/* <div className='mt-3'><button type="button" class="btn btn-primary" value='Best' >Save</button></div> */}

        </Fragment>
    )
}

export default RateTable    