import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '../css/modle.css';
import axios from 'axios';
import lib from '../../config/lib';
import Swal from 'sweetalert2';
import Loading from '../../common/Loading';

const AddStepView = ({ isOpen, closeModal }) => {
    const [role, setRole] = useState('');
    const [step, setStep] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleEmployeradd = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('step', step);
        formData.append('role', role);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.addsteps, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Step Updated Successfully!', 'success');
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    useEffect(() => {
        handlesubcatdisplay();
    }, []);

    const handlesubcatdisplay = async () => {

        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.fetchrole, formData);
            setData(response.data)
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Add Step</h4>
                <hr />

                <div class=" mt-3">
                    <label>Select Step</label>
                    <select class="form-select" aria-label="Default select example" onChange={(e) => setStep(e.target.value)} >
                        <option selected>Select Step</option>
                        {data && data.map((item, index) => (
                            <option value={index + 1}>{index + 1}</option>
                        ))}
                    </select>
                </div>
                <div class=" mt-3">
                    <label>Select Role</label>
                    <select class="form-select" aria-label="Default select example" onChange={(e) => setRole(e.target.value)} >
                        <option selected>Select role</option>
                        {data && data.map((item) => (
                            <option value={item.rolename}>{item.rolename}</option>
                        ))}
                    </select>
                </div>
                <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleEmployeradd} >Create</button></div>
            </div>

        </div>
    );
};

export default AddStepView;
