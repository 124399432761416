import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/modle.css';
import axios from 'axios';
import lib from '../../config/lib';
import Swal from 'sweetalert2';
import Loading from '../../common/Loading';

const AddStepView = ({ isOpen, closeModal, handlejobnumber }) => {
    const [role, setRole] = useState('');
    const [data, setData] = useState([]);
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);

    const handleWorkbyfeth = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handlejobnumber);
        formData.append('subrole', role);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.workdoneby, formData);
            setName(response.data.name);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    useEffect(() => {
        handlesubcatdisplay();
    }, []);

    const handlesubcatdisplay = async () => {
        setLoading(true);
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.fetchrole, formData);
            setData(response.data)
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Work Roll</h4>
                <hr />

                <div className=" mt-3">
                    <label>Select Role</label>
                    <select class="form-select" aria-label="Default select example" onChange={(e) => setRole(e.target.value)}>
                        <option selected>Select role</option>
                        {data && data.map((item) => (
                            <option value={item.rolename}>{item.rolename}</option>
                        ))}
                    </select>
                </div>
                <div className='mt-3'>
                    <label for="text">Work By</label>
                    <input type='text' className='form-control' value={name} disabled />
                </div>
                <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleWorkbyfeth}>Check details</button></div>
            </div>
        </div>
    );
};

export default AddStepView;
