import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/modle.css';
import axios from 'axios';
import lib from '../../config/lib';
import Swal from 'sweetalert2';
import Loading from '../../common/Loading';

const VariantView = ({ isOpen, closeModal }) => {
    const [item, setItem] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        formData.append("varient", item);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.addvarient, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Added Successfully!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Something Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }
    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Add Variant</h4>
                <hr />
                <div class="input-group flex-nowrap">
                    <input type="text" className="form-control" placeholder="Enter variant name" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setItem(e.target.value)} />
                </div>
                <div className='mt-3'><button type="button" className="btn btn-primary" onClick={handleSubmit} >Create</button></div>
            </div>
        </div>
    )
}

export default VariantView;
