import React, { useState } from 'react';
import Modal from './WorkbyView';
import '../css/modle.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';


function AddStep({handlejobnumber}) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="App">
            <Button variant="success" onClick={openModal} >Work By</Button>
            <Modal isOpen={isModalOpen} closeModal={closeModal} handlejobnumber={handlejobnumber} />
        </div>
    );
}

export default AddStep;
