import React, { useState } from 'react';
import Modal from './DescritopnWritingMessage';
import '../css/modle.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';


function DescritopnWriting({ handleDesjonno }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <span className="App">
            <Button variant="success" className="card-button" onClick={openModal} >Description</Button>
            <Modal isOpen={isModalOpen} closeModal={closeModal} handleDesjonno={handleDesjonno} />
        </span>
    );
}

export default DescritopnWriting;
