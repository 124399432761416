import React, { useState } from 'react';
import CustomModal from './CustomOrderJobView';
import RuleChangeModal from './RulechangeJobView';
import SampleModal from './SampleJobView';
import KeylineModal from './KeylineJobView';
import ConsumableModal from './ConsumableJobView';
import StandardJobView from './StandardViewJob';
import '../css/modle.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { add, remove } from '../../store/Cartslice';


function EmployeeViewjob({ handleOrderidsend, handleJobform }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();

    const openModal = () => {
        dispatch(add(handleOrderidsend));
        setIsModalOpen(true);
    };

    const closeModal = () => {
        dispatch(remove(handleOrderidsend));
        setIsModalOpen(false);
    };

    if(handleJobform == "sample")
    {
        return <span className="App">
                <Button variant="success" className="card-button" onClick={openModal} >View job</Button>
                <SampleModal isOpen={isModalOpen} closeModal={closeModal} handleOrderidsend={handleOrderidsend} />
                </span>;
    }
    else
    {
        if(handleJobform == "consumable")
        {
            return <span className="App">
                    <Button variant="success" className="card-button" onClick={openModal} >View job</Button>
                    <ConsumableModal isOpen={isModalOpen} closeModal={closeModal} handleOrderidsend={handleOrderidsend} />
                    </span>;
        }
        else
        {
            if(handleJobform == "order")
            {
                return <span className="App">
                        <Button variant="success" className="card-button" onClick={openModal} >View job</Button>
                        <CustomModal isOpen={isModalOpen} closeModal={closeModal} handleOrderidsend={handleOrderidsend} />
                        </span>;
            }
            else
            {
                if(handleJobform == "rulechange")
                {
                    return <span className="App">
                            <Button variant="success" className="card-button" onClick={openModal} >View job</Button>
                            <RuleChangeModal isOpen={isModalOpen} closeModal={closeModal} handleOrderidsend={handleOrderidsend} />
                            </span>;
                }
                else
                {
                    if(handleJobform == "keyline")
                    {
                        return <span className="App">
                                <Button variant="success" className="card-button" onClick={openModal} >View job</Button>
                                <KeylineModal isOpen={isModalOpen} closeModal={closeModal} handleOrderidsend={handleOrderidsend} />
                                </span>;
                    }
                    else
                    {
                        if(handleJobform == "standerdorder")
                        {
                            // <FreshOrderModal handleStartjobid={item.orderid} handleJobfrom={"standerdorder"} />
                            return <span className="App">
                                <Button variant="success" className="card-button" onClick={openModal} >View job</Button>
                                <StandardJobView isOpen={isModalOpen} closeModal={closeModal} handleOrderidsend={handleOrderidsend} />
                                </span>;
                        }
                    }
                }
            }
        }
    }
}

export default EmployeeViewjob;
