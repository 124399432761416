import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/modle.css';
import lib from '../../config/lib';
import Subrate from './Subrate'
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { RiDeleteBin4Fill } from "react-icons/ri";
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2';
import Loading from '../../common/Loading';

const RateView = ({ isOpen, closeModal }) => {
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const [data, setData] = useState([]);

    const [companyname, setCompanyName] = useState([]);

    const [companynameinsert, setCompanyNameinsert] = useState('');

    // Calculate the total number of pages
    // const pageCount = Math.ceil(data.length / itemsPerPage);
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };
    const currentPageData = getCurrentPageData();

    useEffect(() => {
        // handleRate();
        handleCompanyName();
    }, []);

    const handleRate = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('companyname', companynameinsert);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.fethratedetails, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleCompanyName = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.userfethdata, formData);
            setCompanyName(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleCloseModle = () => {
        Swal.fire('Success', 'All Details Saved Successfully!', 'success');
        closeModal();
    }

    const handleDelete = async (id) => {
        const token = localStorage.getItem('token');
        setLoading(true);
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', id);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.deleteRate, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Deleted Successfully!', 'success');
                handleRate();
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Something Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-contentes">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Add Rate</h4>
                <hr />
                <div className='row'>
                    <div className='col-md-10 mb-2'>
                        <label className='mb-1'>Company (Note:- please select company while creating rate or search) </label>
                        <Form.Select aria-label="Default select company" onChange={(e) => setCompanyNameinsert(e.target.value)}>
                            <option selected>Select Company Name</option>
                            {companyname && companyname.map((item) => (
                                <option value={item.cname}>{item.cname}</option>
                            ))}
                        </Form.Select>
                    </div>
                    <div className='col-md-2 d-flex align-items-center mt-2 ps-0'> <Button onClick={handleRate} className='mt-1'>Search</Button></div>
                    <br />
                    <Table striped bordered hover >
                        <thead>
                            <tr>
                                <th>Item</th>
                                <th>Quality</th>
                                <th>Uom</th>
                                <th>Rate</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPageData && currentPageData.map((item) => (
                                <tr>
                                    <td>{item.item}</td>
                                    <td>{item.quality}</td>
                                    <td>{item.umo}</td>
                                    <td>{item.rate}</td>
                                    <td className="d-flex">
                                        <RiDeleteBin4Fill size={20} onClick={() => handleDelete(item.id)} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                />
                <Subrate companynameinsert={companynameinsert} handleRate={handleRate} />
                <div className='mt-3'><button type="button" class="btn btn-primary" value='Best' onClick={handleCloseModle} >Save</button></div>
            </div>
        </div>
    );
};

export default RateView;
