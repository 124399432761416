import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '../css/modle.css';
import lib from '../../config/lib';
import axios from 'axios';
import Swal from 'sweetalert2';
import Loading from '../../common/Loading';

const AddUserView = ({ isOpen, closeModal }) => {
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [cname, setCname] = useState('');
    const [gstnumber, setGstnumber] = useState('');
    const [address, setAddress] = useState('');
    const [custcoad, setCustcoad] = useState('');
    const [desnumber, setDesnumber] = useState('');
    const [marketnumber, setMarketnumber] = useState('');
    const [groupname, setGroupname] = useState('');
    const [loading, setLoading] = useState(false);
    const [hideshow, sethideshow] = useState('');

    const handleUsersubmit = async () => {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        formData.append("name", name);  
        formData.append("mobile", number);
        formData.append("email", email);
        formData.append("cname", cname);
        formData.append("gstnumber", gstnumber);
        formData.append("address", address);
        formData.append("custcoad", custcoad);
        formData.append("desnumber", desnumber);
        formData.append("marketnumber", marketnumber);
        formData.append("groupname", groupname);
        formData.append("role", "user");
        formData.append('hideshow', hideshow);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.adduserbyadmin, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'User Added Successfully!', 'success');
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }
    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-contentes">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Add User</h4>

                <hr />

                <div className='row'>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3 ">
                            <label>CUSTOMER NAME</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setName(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3">
                            <label>OWNER MOBILE NUMBER</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setNumber(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3">
                            <label>EMAIL-ID</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3">
                            <label>COMPANY NAME</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setCname(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3">
                            <label>GST NUMBER</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setGstnumber(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3">
                            <label>ADDRESS</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setAddress(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3">
                            <label>CUSTOMER CODE</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setCustcoad(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3">
                            <label>DESIGNER NUMBER</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setDesnumber(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3">
                            <label>MARKET PERSON NUMBER</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setMarketnumber(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div class="input-group flex-nowrap mt-3">
                            <label>WHATS APP GROUP NAME</label>
                            <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setGroupname(e.target.value)} />
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <Form.Group className="mb-3 mt-4" controlId="exampleForm.ControlInput1">
                            <label>Hide Show Breakup</label>
                            <Form.Select aria-label="Default select example" value={hideshow} onChange={(e) => sethideshow(e.target.value)}>
                                <option selected>Select Hide Show Breakup</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </Form.Select>
                        </Form.Group>
                    </div>
                </div>

                <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleUsersubmit}>Create</button></div>
            </div>
        </div>
    );
};

export default AddUserView;
