import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '../css/modle.css';
import axios from 'axios';
import lib from '../../config/lib';
import Swal from 'sweetalert2';
import Loading from '../../common/Loading';

const AddEmployerMessage = ({ isOpen, closeModal }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [role, setRole] = useState('');
    const [aadhar, setAadhar] = useState('');
    const [pan, setPan] = useState('');
    const [address, setAddress] = useState('');
    const [loading, setLoading] = useState(false);
    const [adcheck, setAdcheck] = useState('');
    const [ro, setRo] = useState('');
    const [dw, setDw] = useState('');
    const [ma, setMa] = useState('');

    const [updatedetails, setUpdateDetails] = useState('');
    const [asignuser, setAsignuser] = useState('');
    const [billing, setBilling] = useState('');
    const [viewdetails, setViewDetails] = useState('');

    const [data, setData] = useState([]);

    const handleEmployeradd = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('mobilenumber', mobile);
        formData.append('role', role);
        formData.append('aadharnumber', aadhar);
        formData.append('pannumber', pan);
        formData.append('address', address);
        formData.append('additionaldetails', adcheck);
        formData.append('republishorder', ro);
        formData.append('descriptionwriting', dw);
        formData.append('marketingaccess', ma);
        formData.append('billing', billing);
        formData.append('viewdetails', viewdetails);
        formData.append('updatestock', updatedetails);
        formData.append('asignejob', asignuser);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.createemployer, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Employer Registered successfully!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        handlesubcatdisplay();
    }, []);

    const handlesubcatdisplay = async () => {

        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.fetchrole, formData);
            setData(response.data)
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Add Employer</h4>
                <hr />
                <div class="input-group flex-nowrap">
                    <label>Name</label>
                    <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setName(e.target.value)} />
                </div>
                <div class="input-group flex-nowrap mt-3">
                    <label>User Name</label>
                    <input type="text" class="form-control" placeholder="Enter email" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div class="input-group flex-nowrap mt-3">
                    <label>Mobile number </label>
                    <input type="text" class="form-control" placeholder="Enter mobile number" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setMobile(e.target.value)} />
                </div>
                <div class=" mt-3">
                    <label>Role</label>
                    <select class="form-select" aria-label="Default select example" onChange={(e) => setRole(e.target.value)} >
                        <option selected>Select role</option>
                        {data && data.map((item) => (
                            <option value={item.rolename}>{item.rolename}</option>
                        ))}
                    </select>
                </div>
                <div class="input-group flex-nowrap mt-3">
                    <label>Aadhar number</label>
                    <input type="text" class="form-control" placeholder="Enter aadhar number" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setAadhar(e.target.value)} />
                </div>
                <div class="input-group flex-nowrap mt-3">
                    <label>Pan number</label>
                    <input type="text" class="form-control" placeholder="Enter pan number" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setPan(e.target.value)} />
                </div>
                <div class="input-group flex-nowrap mt-3">
                    <label> Address</label>
                    <input type="text" class="form-control" placeholder="Enter adrress" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setAddress(e.target.value)} />
                </div>
                <div className='row mt-3'>
                    <label> All Access</label>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setAdcheck(e.target.checked)} />
                            Additional Details
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setRo(e.target.checked)} />
                            Republish order
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setDw(e.target.checked)} />
                            Description Writing
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setMa(e.target.checked)} />
                            Marketing Access
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setUpdateDetails(e.target.checked)} />
                            Update Details
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setAsignuser(e.target.checked)} />
                            Asign Job
                        </label>
                    </div>

                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setBilling(e.target.checked)} />
                            Billing Access
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setViewDetails(e.target.checked)} />
                            View Aditional Details
                        </label>
                    </div>

                </div>

                <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleEmployeradd} >Create</button></div>
            </div>

        </div>
    );
};

export default AddEmployerMessage;
