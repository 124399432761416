import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Addquality from './Addquality'
import axios from 'axios';
import lib from '../../config/lib';
import ReactPaginate from "react-paginate";
import { RiDeleteBin4Fill } from "react-icons/ri"
import UpdateDetails from '../customerrate/UpdateDetails'
import Swal from 'sweetalert2';
import Loading from '../../common/Loading';

const JobModalView = ({ isOpen, closeModal, jobno, id, jobform }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleOrder();
    }, []);

    const handleOrder = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', jobno);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.stockdisplayemploy, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    // Calculate the total number of pages
    // const pageCount = Math.ceil(data.length / itemsPerPage);
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData([]);

    const handleSubmit = () => {
        Swal.fire('Success', 'Stock Updated Successfully!', 'success');
        closeModal();
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-contentes">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>View Details</h4>
                <hr className='mb-0' />
                <Table striped bordered hover >
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Varient</th>
                            <th>UOM</th>
                            {/* <th>Action</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {currentPageData && currentPageData.map((item, index) => (
                            <tr>
                                <td>{item.item}</td>
                                <td>{item.quantity}</td>
                                <td>{item.varient}</td>
                                <td>{item.umo}</td>
                                {/* <td><RiDeleteBin4Fill size={22} /></td> */}
                            </tr>
                        ))}
                    </tbody>

                    {/* <button onClick={handleOrder}>Check Details</button> */}

                </Table>

                <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                />
                <UpdateDetails jobno={jobno} id={id} jobform={jobform} handleOrder={handleOrder} />
                <div className='col-md-2'>
                    <input type="submit" style={{ marginTop: '15px', clear: 'both' }} value="Submit" className="upload-button" onClick={handleSubmit} />
                </div>
            </div>
        </div>
    );
};

export default JobModalView;
