'useclient'
import React, { Fragment, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assest/css/custom.css';
import './assest/css/gradient.css';
import Header from './common/header';
import Sidemenu from './common/sidemenu';
import clasess from './common/css/Content.module.css';
// import Stockmodal from './components/stockModal/stockmodal'
import './components/stockModal/stockmodal'
import './components/css/modle.css';
// import Addproduct from './components/stockModal/addproduct'
// import Addparameter from './components/stockModal/addparameter'
// import Addquality from './components/stockModal/addquality'
import Table from 'react-bootstrap/Table';
import ReactPaginate from "react-paginate";
import { RiDeleteBin4Fill } from "react-icons/ri"
// import { BiSolidEdit } from "react-icons/bi"
// import EditModal from './components/stockModal/editModal'
// import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import AddstockModal from './components/stockModal/addstockModal';
import axios from 'axios';
import lib from './config/lib';
import Swal from 'sweetalert2';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import VendorTable from './components/stocktable/VendorTable'
import ProductTable from './components/stocktable/ProductTable'
import ParameterTable from './components/stocktable/ParameterTable'
import QualitiesTable from './components/stocktable/QualityTable'
import QualityTable from './components/QualityTable'
import UomTable from './components/UomTable'
import ItemTable from './components/ItemTable'
import RateTable from './components/RateTable'
import Addquantity from './components/stockModal/addquantity'
import VariantTable from './components/VariantTable';
import StockPurchaseTable from './components/stocktable/StockPurchaseTable';
import Loading from './common/Loading';

function Stock() {
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('');

    useEffect(() => {
        handleOrder();
    }, []);

    const handleOrder = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.stockdisplay, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    // Calculate the total number of pages
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData([]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDelete = async (id) => {
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', id);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.deletstock, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Deleted Successfully!', 'success');
                handleOrder();
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Something Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleSearch = async () => {
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'stock');
        formData.append('search', type);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.searchonclick, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <Fragment>
            <div className={clasess.container1}>
                <Header />
                <Sidemenu />
                <div>
                    <div className={clasess.container}>
                        <Loading loading={loading} />
                        <div style={{ width: '100%', height: 'auto', marginTop: '20px' }}>
                            <div className="row">
                                <div className="col-lg-7 col-md-3 align-items-center">
                                    <h3 className="ms-2">Stock</h3>
                                </div>
                            </div>
                            <hr />
                            <Tabs
                                defaultActiveKey="Add Stock"
                                transition={false}
                                id="noanim-tab-example"
                                className="mb-3"
                                style={{ fontSize: '20px' }}
                            >
                                <Tab eventKey="Add Stock" title="Add Stock">
                                    <div className='row justify-content-between align-items-center'>
                                        <div className='col-lg-3 col-md-10 d-flex'>
                                            <input type="text" class="form-control" placeholder="Type to search..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setType(e.target.value)} />
                                            <Button variant="success" style={{ margin: '10px' }} onClick={handleSearch} >Search</Button>
                                        </div>
                                        <div className='col-lg-1 ms-lg-auto col-md-2'>
                                            <AddstockModal />
                                        </div>
                                    </div>

                                    <Table striped bordered hover >
                                        <thead>
                                            <tr>
                                                <th>Vender</th>
                                                <th>Item</th>
                                                <th>Variant</th>
                                                <th>Quantity</th>
                                                <th>Parameter</th>
                                                <th>Qaulity</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentPageData && currentPageData.map((item, index) => (
                                                <tr>
                                                    <td>{item.vender}</td>
                                                    <td>{item.item}</td>
                                                    <td>{item.varient}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.umo}</td>
                                                    <td>{item.quality}</td>
                                                    <td className="d-flex">
                                                        <span> <RiDeleteBin4Fill size={20} onClick={() => handleDelete(item.id)} /></span>
                                                        <span className='ms-2'><Addquantity id={item.id} handleOrder={handleOrder} /></span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </Table>
                                    <ReactPaginate
                                        previousLabel={"Previous"}
                                        nextLabel={"Next"}
                                        breakLabel={"..."}
                                        pageCount={pageCount}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageChange}
                                        containerClassName={"pagination"}
                                        subContainerClassName={"pages pagination"}
                                        activeClassName={"active"}
                                    />
                                </Tab>
                                <Tab eventKey="Stock Purchase" title="Stock Purchase">
                                    <StockPurchaseTable />
                                </Tab>
                                <Tab eventKey="Add Vendor" title="Add Vendor">
                                    <VendorTable />
                                </Tab>

                                <Tab eventKey="Item" title="Item">
                                    <ItemTable />
                                </Tab>
                                <Tab eventKey="Variant" title="Variant">
                                    <VariantTable />
                                </Tab>
                                <Tab eventKey="Quality" title="Quality">
                                    <QualitiesTable />
                                </Tab>
                                <Tab eventKey="Uom" title="Uom">
                                    <UomTable />
                                </Tab>
                                <Tab eventKey="Rate" title="Rate">
                                    <RateTable />
                                </Tab>
                            </Tabs>

                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default Stock;