import React, { useState, useEffect } from "react";
import axios from 'axios';
import lib from "../../config/lib";
import Swal from "sweetalert2";
import Loading from "../../common/Loading";

const AssigneticketMessage = ({ isOpen, closeModal, handleJobfrom, handleJobno }) => {
    const [user, setUser] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleStartjob = async () => {
        const token = localStorage.getItem('token');
        setLoading(true);
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', user);
        formData.append('jobno', handleJobno);
        formData.append('jobform', handleJobfrom);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.assignemarket, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Job Started Successfully!', 'success');
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    useEffect(() => {
        handleEmployerfeth();
    }, []);

    const handleEmployerfeth = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.alluserfeth, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Assigne Job</h4>
                <hr />

                <label className='mt-3'>User</label>
                <select class="form-select" aria-label="Default select example" onChange={(e) => setUser(e.target.value)} >
                    <option selected>Select user</option>
                    {data.map((item) => (
                        <option value={item.id}>{item.name}</option>
                    ))}
                </select>

                <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleStartjob}>Start Job</button></div>
            </div>
        </div>
    )
}
export default AssigneticketMessage;