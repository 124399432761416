import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Swal from 'sweetalert2'
import axios from 'axios';
import lib from '../../config/lib';
import { Form } from 'react-bootstrap';
import Loading from '../../common/Loading';

const AddJobConsumableView = ({ isOpen, closeModal, id }) => {
    const [catmain, setCatmain] = useState('');
    const [quality, setQuality] = useState('');
    const [matrixsize, setMatrixSize] = useState('');
    const [type, setType] = useState('');
    const [quantity, setQuantity] = useState('');
    const [rubbersize, setRubberSize] = useState('');
    const [jobmode, setJobMode] = useState('');
    const [loading, setLoading] = useState(false);

    const handleConsumableadmin = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('productid', id);
        formData.append('jobmode', jobmode);
        formData.append('catmain', catmain);
        formData.append('quality', quality);
        formData.append('matrixsize', matrixsize);
        formData.append('type', type);
        formData.append('quantity', quantity);
        formData.append('rubbersize', rubbersize);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.consumableaddjob, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.data.status === "success") {
                Swal.fire('Success', 'Order Created!', 'success');
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    useEffect(() => {
        handleCompanyName();
    }, []);

    const [data, setData] = useState([]);
    const [companyname, setCompanyName] = useState('N/A');

    const handleCompanyName = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.userfethdata, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    function startform(s) {
        setCatmain(s.target.value);
        var Selectfirst = document.getElementById("Selectfirst");
        var Creasing = document.getElementById("Creasing");
        var Rubber = document.getElementById("Rubber");

        console.log(Selectfirst.value);

        if (Selectfirst.value === "Creasing matrix") {
            Creasing.style.display = 'block';
            Rubber.style.display = "none";

        }
        else {
            if (Selectfirst.value === "Rubber") {
                Creasing.style.display = "none";
                Rubber.style.display = 'block';
            }
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Add job</h4>
                <hr className='mb-0' />
                <div className="row">
                    <div className='col-md-12 mt-2'>
                        <div className='d-flex'><label>Job mode</label></div>
                        <Form.Select aria-label="Select job mode" className='mt-2' onChange={(e) => setJobMode(e.target.value)}>
                            <option selected disabled>Select mode</option>
                            <option value="Whattsapp">Whattsapp</option>
                            <option value="Email">Email</option>
                            <option value="By visite">By Visit</option>
                            <option value="By Phone Call">By Phone Call</option>
                        </Form.Select>
                    </div>
                    <div className='col-md-12 mt-2'>
                        <label>Company name</label>
                        <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setCompanyName(e.target.value)}>
                            <option selected disabled>Select Company</option>
                            {data && data.map((item) => (
                                <option value={item.cname}>{item.cname}</option>
                            ))}
                        </Form.Select>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-12 mt-2 ps-3 pe-3">
                        <label className='d-flex'>Category</label>
                        <Form.Select id="Selectfirst" style={{ marginTop: '10px' }} onChange={(e) => startform(e)}>
                            <option selected disabled>Select Category type</option>
                            <option value="Creasing matrix">Creasing matrix</option>
                            <option value="Rubber">Rubber</option>
                        </Form.Select>
                    </div>
                    <div id="Creasing" style={{ display: 'none' }} className="row p-0">
                        <div className="col-md-12 mt-2">
                            <label className='d-flex'>Quality</label>
                            <Form.Select className="form-select" aria-label="Default select example" onChange={(e) => setQuality(e.target.value)} >
                                <option selected disabled>Select Quality</option>
                                <option value="Long run">Long run</option>
                                <option value="Medium run">Medium run</option>
                                <option value="Short run">Short run</option>
                            </Form.Select>
                        </div>
                        <div className='col-md-12 mt-2'>
                            <div className='d-flex'><label>Matrix size</label></div>
                            <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter matrix size" onChange={(e) => setMatrixSize(e.target.value)} />
                        </div>
                        <div className="col-md-12 mt-2">
                            <label className='d-flex'>Type</label>
                            <Form.Select className="form-select" aria-label="Default select example" onChange={(e) => setType(e.target.value)} >
                                <option selected disabled>Select type</option>
                                <option value="Coil">Coil</option>
                                <option value="Packet">Packet</option>
                            </Form.Select>
                        </div>
                        <div className='col-md-12 mt-2'>
                            <div className='d-flex'><label>Quantity</label></div>
                            <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter quantity" onChange={(e) => setQuantity(e.target.value)} />
                        </div>
                    </div>

                    {/* Rubber */}

                    <div id="Rubber" style={{ display: 'none' }} className="row p-0">
                        <div className="col-md-12 mt-2">
                            <label className='d-flex'>Quality</label>
                            <Form.Select class="form-select" aria-label="Default select example" onChange={(e) => setQuality(e.target.value)} >
                                <option selected disabled>Select Quality</option>
                                <option value="Green Rubber 35 Shore">Green Rubber 35 Shore</option>
                                <option value="Vulkolen Rubber">Vulkolen Rubber</option>
                                <option value="Grey Rubber">Grey Rubber</option>
                            </Form.Select>
                        </div>
                        <div className='col-md-12 mt-2'>
                            <div className='d-flex'><label>Rubber size</label></div>
                            <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter rubber size" onChange={(e) => setRubberSize(e.target.value)} />
                        </div>
                        <div className='col-md-12 mt-2'>
                            <div className='d-flex'><label>Quantity</label></div>
                            <input type="text" id="input-name" style={{ display: 'block' }} placeholder="Enter quantity" onChange={(e) => setQuantity(e.target.value)} />
                        </div>
                    </div>
                </div>
                <input type="submit" style={{ marginTop: '15px', clear: 'both' }} value="Complete Your Order" className="upload-button" onClick={handleConsumableadmin} />
            </div>
        </div>

    );
};

export default AddJobConsumableView;
