import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assest/images/logo.jpg';
import thumb from '../assest/images/user_thumb.png';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import lib from "../config/lib";
import Loading from "../common/Loading";


const EmployerHeader = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handlesubcatdisplay();
    }, []);

    const handlesubcatdisplay = async () => {
        setLoading(true);
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.employerheader, formData);
            setName(response.data.name);
            setRole(response.data.subrole);
            setLoading(false)
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div>
            <div id="container">
                <Loading loading={loading} />
                <div className="row">
                    <div className="col">
                        <div id="header" className="blue_lin">
                            <div className="header_left">
                                <div className="logo">
                                    <img src={logo} width={140} height={61} alt="Ekra" />
                                </div>
                            </div>
                            <div className="mainicon">
                                <Link to="/employdashbord" className="dashicon">Dashbord</Link>
                                <span className="dashicon"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        localStorage.removeItem('token');
                                        localStorage.removeItem('role');
                                        navigate('/login');
                                    }}>Logout</span>

                            </div>

                            <div class="header_right">
                                <div id="user_nav">
                                    <ul>
                                        <li class="user_thumb"><a href="#"><span class="icon"><img src={thumb} width={30} height={30} alt="User" /></span></a></li>
                                        <li class="user_info"><span class="user_name">{name}</span><span>{role}</span></li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default EmployerHeader;