import React, { useState } from 'react';
import Modal from './CalulationModal';
import '../css/modle.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';


function Calculate({handledisplay}) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
        handledisplay(false)
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <span className="App">
            <Button variant="success" className="card-button" onClick={openModal}>Calculater</Button>
            <Modal isOpen={isModalOpen} closeModal={closeModal} handledisplay={handledisplay}/>
        </span>
    );
}

export default Calculate;
