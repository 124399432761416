import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import '../css/modle.css';
import lib from '../../config/lib';
import axios from 'axios';
import Swal from 'sweetalert2';
import Calculate from '../EmployerJobModal/Calculation';
import Loading from '../../common/Loading';

const UpdateDetailsView = ({ isOpen, closeModal, jobno, id, jobform, handleOrder }) => {
    const [loading, setLoading] = useState(false);
    const [item, setItem] = useState([]);
    const [umo, setUmo] = useState([]);
    const [varient, setVarient] = useState([]);
    const [iteminsert, setIteminsert] = useState('');
    const [quality, setQuantity] = useState('');
    const [umoinsert, setUmoinsert] = useState('');
    const [varientinsert, setVarientinsert] = useState('');


    const handleSubmit = async () => {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        formData.append("item", iteminsert);
        formData.append("quantity", quality);
        formData.append("jobno", jobno);
        formData.append("jobform", jobform);
        formData.append("umo", umoinsert);
        formData.append('varient', varientinsert);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.updatestockrate, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Stock Details Updated!', 'success');
                handleOrder();
                closeModal();
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Something Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        handleitem();
        handleUmo();
        handleVarient();
        handledisplay(true);
    }, []);

    const handleitem = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.fethitem, formData);
            setItem(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleUmo = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'All');
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.fethumo, formData);
            setUmo(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleVarient = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'All');

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.displayvarient, formData);
            setVarient(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handledisplay = (displayprop) => {
        var quantity = document.getElementById("quantity");

        if (displayprop === true) {
            quantity.style.display = "block";
        }
        else {
            if (displayprop === false) {
                quantity.style.display = "none";
            }
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Add Item</h4>
                <hr />
                <div className='row'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <label className='mb-1'>Item</label>
                            <Form.Select aria-label="Default select example" onChange={(e) => setIteminsert(e.target.value)}>
                                <option selected disabled>Select Item</option>
                                {item && item.map((item) => (
                                    <option value={item.item}>{item.item}</option>
                                ))}
                            </Form.Select>
                        </div>

                        <div className='col-md-12'>
                            <label className='mb-1'>Varient</label>
                            <Form.Select aria-label="Default select example" onChange={(e) => setVarientinsert(e.target.value)}>
                                <option selected disabled>Select Item</option>
                                {varient && varient.map((item) => (
                                    <option value={item.varient}>{item.varient}</option>
                                ))}
                            </Form.Select>
                        </div>

                        <div className='col-md-12'>
                            <label className='mb-1'>UMO</label>
                            <Form.Select aria-label="Default select example" onChange={(e) => setUmoinsert(e.target.value)}>
                                <option selected disabled>Select Item</option>
                                {umo && umo.map((item) => (
                                    <option value={item.umo}>{item.umo}</option>
                                ))}
                            </Form.Select>
                        </div>

                        {/* <div className='col-md-12'>
                            <label className='mb-1'>Select Type</label>
                            <Form.Select aria-label="Default select example">
                                <option selected>Select Type</option>
                                <option>inch</option>
                                <option>mm</option>
                            </Form.Select>
                        </div>

                        <div className='col-md-12 mt-3'>
                            <label className='mb-1'>Lenth</label>
                            <div class="input-group flex-nowrap">
                                <input type="text" className="form-control" placeholder="Enter Lenth" aria-label="Username" aria-describedby="addon-wrapping"/>
                            </div>
                        </div>

                        <div className='col-md-12 mt-3'>
                            <label className='mb-1'>Width</label>
                            <div class="input-group flex-nowrap">
                                <input type="text" className="form-control" placeholder="Enter Width" aria-label="Username" aria-describedby="addon-wrapping"/>
                            </div>
                        </div> */}

                        <div className='col-md-12 mt-3' id='quantity' style={{ display: 'none' }}>
                            <label className='mb-1'>Quantity</label>
                            <div class="input-group flex-nowrap">
                                <input type="text" className="form-control" placeholder="Enter Quantity" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setQuantity(e.target.value)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-3'><button type="button" class="btn btn-primary" value='Best' onClick={handleSubmit} >Save</button></div>
                <br />
                <Calculate handledisplay={handledisplay} />
            </div>
        </div>
    );
};

export default UpdateDetailsView;
