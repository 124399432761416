import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assest/css/custom.css';
import '../assest/css/reset.css';
import '../assest/css/layout.css';
import '../assest/css/themes.css';
import '../assest/css/typography.css';
import '../assest/css/styles.css';
import '../assest/css/shCore.css';
import '../assest/css/bootstrap.css';
import '../assest/css/jquery.jqplot.css';
import '../assest/css/jquery-ui-1.8.18.custom.css';
import '../assest/css/data-table.css';
import '../assest/css/form.css';
import '../assest/css/ui-elements.css';
import '../assest/css/wizard.css';
import '../assest/css/sprite.css';
import '../assest/css/gradient.css';

import axios from 'axios';
import lib from '../config/lib';

const Graph = () => {
  const [totalorders, setTotalOrders] = useState(0);
  const [pending, setPending] = useState(0);
  const [quat, setQuat] = useState(0);
  const [keyline, setKeyLine] = useState(0);

  const data = [
    { name: 'Total Number Of Dies', students: parseInt(totalorders) },
    { name: 'Total Rule Change', students: parseInt(pending) },
    { name: 'Total Quotation', students: parseInt(quat) },
    { name: 'Total Key Line', students: parseInt(keyline) },
  ];

  useEffect(() => {
    handleTotalorder();
    handleRuleChange();
    handleQuatioin();
    handleKeyline();
  }, []);

  const handleTotalorder = async () => {

    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("apikey", token);

    try {
      const response = await axios.post(lib.apiURL + '/' + lib.apis.totalorders, formData);
      setTotalOrders(response.data.order);
    } catch (error) {
      // Handle error if the request failed
      console.log("Some error has occoured kindly check the parameters and try again");
    }
  }

  const handleRuleChange = async () => {

    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("apikey", token);

    try {
      const response = await axios.post(lib.apiURL + '/' + lib.apis.totalrulechange, formData);
      setPending(response.data.order);
    } catch (error) {
      // Handle error if the request failed
      console.log("Some error has occoured kindly check the parameters and try again");
    }
  }

  const handleQuatioin = async () => {

    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("apikey", token);

    try {
      const response = await axios.post(lib.apiURL + '/' + lib.apis.totalquation, formData);
      setQuat(response.data.order);
    } catch (error) {
      // Handle error if the request failed
      console.log("Some error has occoured kindly check the parameters and try again");
    }
  }

  const handleKeyline = async () => {

    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("apikey", token);

    try {
      const response = await axios.post(lib.apiURL + '/' + lib.apis.totalkeylines, formData);
      setKeyLine(response.data.order);
    } catch (error) {
      // Handle error if the request failed
      console.log("Some error has occoured kindly check the parameters and try again");
    }
  }




  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <div style={{ width: 'auto', float: 'left' }}>
      <center>
        <div className="pie-chart-container">
          <PieChart width={590} height={350}>
            <Pie
              data={data}
              dataKey="students"
              outerRadius={150}
              fill="#8884d8"
              label={({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
                const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
                const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
                const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));
                return (
                  <text
                    x={x}
                    y={y}
                    fill="white"
                    textAnchor={x > cx ? 'start' : 'end'}
                    dominantBaseline="central"
                  >
                    {`${(percent * 100).toFixed(0)}%`}
                  </text>
                );
              }}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Legend align="left" verticalAlign="middle" layout="vertical" iconSize={10} />
            <Tooltip />
          </PieChart>
        </div>
      </center>
    </div>
  );
};

export default Graph;

