import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import DuplexSelect from './DuplexSelect';
import DuplexSelected from './DuplexSelected';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import lib from '../../config/lib';
import { useDispatch, useSelector } from 'react-redux';
import { BiX } from 'react-icons/bi';
import Loading from '../../common/Loading';
import Button from 'react-bootstrap/Button';

const JobcardMessage = ({ isOpen, closeModal, handleOrderidsend, handleSendsid, machinetype }) => {
    const cartProducts = useSelector(state => state.cart);
    const [loading, setLoading] = useState(false);
    const [jobno, setJobnumber] = useState('');
    const [cat, setCat] = useState('');
    const [boxstyle, setBoxstyle] = useState('');
    const [sheetsize, setSheetsize] = useState('');
    const [productimage, setProductImage] = useState('');
    const [jobname, setJobname] = useState();
    const [lenth, setLenth] = useState('');
    const [width, setWidth] = useState('');
    const [height, setheight] = useState('');
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [status, setStatus] = useState('');
    const [sid, setSid] = useState('');
    const [designerimg, setDesignerImg] = useState('');
    const [jobmode, setJobmode] = useState('');

    // useEffect(() => {
    //     handleKeylineFeth();
    // }, []);

    const handleKeylineFeth = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handleOrderidsend);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.keylinejobdisplay, formData);
            setJobnumber(response.data.jobno);
            setCat(response.data.cat);
            setBoxstyle(response.data.boxstyle);
            setSheetsize(response.data.sheetsize);
            setJobname(response.data.jobname);
            setProductImage(response.data.productimg);
            setLenth(response.data.lenth);
            setWidth(response.data.width);
            setheight(response.data.height);
            setDate(response.data.date);
            setTime(response.data.time)
            setStatus(response.data.tracker);
            setSid(response.data.sid);
            setJobmode(response.data.jobmode);
            handleDesignerimg();
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleDesignerimg = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('jobno', handleOrderidsend);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.designerimg, formData);
            if (response.data.status == "success") {
                setDesignerImg(response.data.designerimg);
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-contente">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>View job</h4>
                <hr />
                <div className='tableview newordertable'>
                    <Table striped="columns">
                        <thead>
                            <tr>
                                <td colspan="8" className='text-center invoce-tittle'>View Job Card</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td colSpan={2}>Customer Image:<span className='ms-3'><a href={lib.imgUrl + '/' + productimage} target="_blank" >View Image</a></span></td>
                                <td colSpan={2}>Designer Image:<span className='ms-3'><a href={lib.imgUrl + '/' + designerimg} target="_blank" >View Image</a></span> </td>
                                <td colSpan={2}>Job no.:<span className='ms-3'>{jobno}</span></td>
                                <td colSpan={2}>Customer code:<span className='ms-3'>{sid}</span> </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Job mode: <span className='ms-3'>{jobmode}</span></td>
                                <td colSpan={2}>Job name: <span className='ms-3'>{jobname}</span></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Category:<span className='ms-3'>{cat}</span> </td>
                                <td colSpan={2}>Box style:<span className='ms-3'>{boxstyle}</span></td>
                                <td colSpan={2}> Sheet size:<span className='ms-3'>{sheetsize}</span> </td>
                                <td colSpan={2}>Length:<span className='ms-3'>{lenth}</span></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Width:<span className='ms-3'>{width}</span></td>
                                <td colSpan={2}>Height:<span className='ms-3'>{height}</span></td>
                                <td colSpan={2}>Date:<span className='ms-3'>{date}</span></td>
                                <td colSpan={2}>Time:<span className='ms-3'>{time}</span></td>
                            </tr>
                            <tr>
                                <td colSpan={2}>Priting Machine Data:<span className='ms-3'><DuplexSelected handleSendsid={handleSendsid} machinetype={machinetype} /></span></td>
                                <td colSpan={2}>Die Machine Data:<span className='ms-3'><DuplexSelect handleSendsid={handleSendsid} machinetype={machinetype} /></span></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <div className='row'>
                    <div className='col-lg-4'>
                        <div className='row border-cus p-2'>
                            <div className='col-lg-12 d-flex justify-content-between'>
                                <span>
                                    <h3 className='mt-0'>
                                        Status:
                                    </h3>
                                </span>
                                <span> <h3 className='mt-0'>{status}</h3></span>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-8'>
                    </div>
                </div>
                <Button onClick={handleKeylineFeth}>Feth Data</Button>
            </div>
        </div>
    );
};

export default JobcardMessage;
