import { Component, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import React, { useEffect } from "react";
import ReactPaginate from "react-paginate";
import Swal from 'sweetalert2'
import axios from 'axios';
import lib from "../../config/lib";
import Loading from "../../common/Loading";

const MachineData = ({ isOpen, closeModal, handleSendsid, machinetype }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // handleOrder();
    }, []);

    const handleOrder = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('sid', handleSendsid);
        formData.append('machinetype', machinetype)

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.diemachinedata, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    // Calculate the total number of pages
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        if (!data) {
            return [];
        }

        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data.slice(startIndex, endIndex);
    };

    const currentPageData = getCurrentPageData([]);

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-contentes">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Machine data displyed here</h4>
                <hr />
                <h3>Die Machine data</h3>
                <div class="widget_wrap">

                    <div class="widget_content">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Die Machine Type</th>
                                    <th>Select Measerments</th>
                                    <th>Name of Die Machine</th>
                                    <th>Inner Chase</th>
                                    <th>Outter Chase</th>
                                    <th>Wood Size</th>
                                    <th>Grip Size</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPageData && currentPageData.map((item) => (
                                    <tr key={item.id}>
                                        <td>{item.mtd}</td>
                                        <td>{item.messerment}</td>
                                        <td>{item.nom}</td>
                                        <td>{item.innerchase}</td>
                                        <td>{item.outterchase}</td>
                                        <td>{item.woodsize}</td>
                                        <td>{item.gripsize}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                        <Button onClick={handleOrder} >Feth Data</Button>
                        <hr />

                        {data && data.length > 0 && (
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                        />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MachineData;

