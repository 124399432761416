import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '../css/modle.css';
import Swal from 'sweetalert2';
import lib from '../../config/lib';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Loading from '../../common/Loading';

const ViewEmployerMessage = ({ isOpen, closeModal }) => {
    const cartProducts = useSelector(state => state.cart);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [aadhar, setAadhar] = useState('');
    const [pan, setPan] = useState('');
    const [address, setAddress] = useState('');
    const [ad, setAd] = useState('');
    const [ro, setRo] = useState('');
    const [dw, setDw] = useState('');
    const [market, setMarket] = useState('');
    const [loading, setLoading] = useState(false);
    const [updatedetails, setUpdateDetails] = useState('');
    const [asignuser, setAsignuser] = useState('');
    const [billing, setBilling] = useState('');
    const [viewdetails, setViewDetails] = useState('');

    const handleEditemployer = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', cartProducts);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('mobilenumber', number);
        formData.append('aadharnumber', aadhar);
        formData.append('pannumber', pan);
        formData.append('address', address);
        formData.append('additionaldetails', ad);
        formData.append("republishorder", ro);
        formData.append("descriptionwriting", dw);
        formData.append('marketingaccess', market);

        formData.append('billing', billing);
        formData.append('viewdetails', viewdetails);
        formData.append('updatestock', updatedetails);
        formData.append('asignejob', asignuser);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.editemployer, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Employers Edited Successfuly!', 'success');
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleFetchData = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', cartProducts);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.fetchemployerdata, formData);
            if (response.data.status == "success") {
                setName(response.data.name);
                setEmail(response.data.email);
                setNumber(response.data.mobilenumber);
                setAadhar(response.data.aadharnumber);
                setPan(response.data.pannumber);
                setAddress(response.data.address);
                setAd(response.data.additionaldetails);
                setRo(response.data.republishorder);
                setDw(response.data.descriptionwriting);
                setMarket(response.data.marketingaccess);

                setViewDetails(response.data.viewdetails);
                setBilling(response.data.billing);
                setAsignuser(response.data.asignejob);
                setUpdateDetails(response.data.updatestock)
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>

            <div className="modal-content ">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Edit Employer</h4>
                <hr />
                <div class="input-group flex-nowrap">
                    <label>Name</label>
                    <input type="text" class="form-control" placeholder="Enter name" aria-label="Username" aria-describedby="addon-wrapping" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div class="input-group flex-nowrap mt-2">
                    <label>User</label>
                    <input type="text" class="form-control" placeholder="Enter email" aria-label="Username" aria-describedby="addon-wrapping" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div class="input-group flex-nowrap mt-2">
                    <label>Mobile number </label>
                    <input type="text" class="form-control" placeholder="Enter mobile number" aria-label="Username" aria-describedby="addon-wrapping" value={number} onChange={(e) => setNumber(e.target.value)} />
                </div>
                {/* <div class="input-group flex-nowrap mt-2">
                    <label>Role</label>
                    <input type="text" class="form-control" placeholder="Enter role" aria-label="Username" aria-describedby="addon-wrapping" value={role} onChange={(e) => setRole(e.target.value)} />
                </div> */}
                <div class="input-group flex-nowrap mt-2">
                    <label>Aadhar number</label>
                    <input type="text" class="form-control" placeholder="Enter aadhar number" aria-label="Username" aria-describedby="addon-wrapping" value={aadhar} onChange={(e) => setAadhar(e.target.value)} />
                </div>
                <div class="input-group flex-nowrap mt-2">
                    <label>Pan number</label>
                    <input type="text" class="form-control" placeholder="Enter pan number" aria-label="Username" aria-describedby="addon-wrapping" value={pan} onChange={(e) => setPan(e.target.value)} />
                </div>
                <div class="input-group flex-nowrap mt-2">
                    <label> Address</label>
                    <input type="text" class="form-control" placeholder="Enter adrress" aria-label="Username" aria-describedby="addon-wrapping" value={address} onChange={(e) => setAddress(e.target.value)} />
                </div>

                <div className='row mt-3'>
                    <label> All Access</label>
                    <div className='col-md-6'>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setAd(e.target.checked)} checked={ad ? true : false} />
                            Additional Details
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setRo(e.target.checked)} checked={ro ? true : false} />
                            Republish order
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setDw(e.target.checked)} checked={dw ? true : false} />
                            Description Writing
                        </label>
                    </div>
                    <div className='col-md-6'>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setMarket(e.target.checked)} checked={market ? true : false} />
                            Marketing Access
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setUpdateDetails(e.target.checked)} checked={updatedetails ? true : false} />
                            Update Details
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setAsignuser(e.target.checked)} checked={asignuser ? true : false} />
                            Asign User
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setBilling(e.target.checked)} checked={billing ? true : false} />
                            Billing Access
                        </label>
                    </div>
                    <div className='col-md-6 '>
                        <label class="list-group-item p-1 mt-2 d-flex align-items-center">
                            <input class="form-check-input me-1 mt-0" type="checkbox" onChange={(e) => setViewDetails(e.target.checked)} checked={viewdetails ? true : false} />
                            View Aditional Details
                        </label>
                    </div>
                </div>
                <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleEditemployer} >Save</button></div>
                <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleFetchData}>Fetch Data</button></div>
            </div>
        </div>
    );
};

export default ViewEmployerMessage;
