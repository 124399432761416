import React, { useState } from 'react';
import Modal from './AdditionalDetailsView';
import '../css/modle.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';


function AdditionalDetails({ handlesendJobNumberView }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <span className="App">
            <Link onClick={openModal}>View Details</Link>
            <Modal isOpen={isModalOpen} closeModal={closeModal} handlesendJobNumberView={handlesendJobNumberView} />
        </span>
    );
}

export default AdditionalDetails;
