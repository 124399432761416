import React, { useState } from 'react';
import Modal from './FreshOrderModalMessage';
import '../css/modle.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { remove, add } from '../../store/Cartslice';

function StartJob({ handleStartjobid, handleJobfrom }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();

    const openModal = () => {
        dispatch(add(handleStartjobid));
        setIsModalOpen(true);
    };

    const closeModal = () => {
        dispatch(remove(handleStartjobid));
        setIsModalOpen(false);
    };

    return (
        <div className="App">
            <Button variant="success" onClick={openModal} >Start Job</Button>
            <Modal isOpen={isModalOpen} closeModal={closeModal} handleJobfrom={handleJobfrom} />
        </div>
    );
}

export default StartJob;