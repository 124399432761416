import React, { useState } from 'react';
import Modal from './StandardViewJob';
import '../css/modle.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { add, remove } from '../../store/Cartslice';

function StandardJob({handleOrderidsend}) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();

    const openModal = () => {
        dispatch(add(handleOrderidsend))
        setIsModalOpen(true);
    };

    const closeModal = () => {
        dispatch(remove(handleOrderidsend));
        setIsModalOpen(false);
    };

    return (
        <div className="App">
            <Button variant="success" onClick={openModal} >View job</Button>
            <Modal isOpen={isModalOpen} closeModal={closeModal} handleOrderidsend={handleOrderidsend} />
        </div>
    );
}

export default StandardJob;
