import React, { useEffect } from 'react';
import Dashboard from './Dashboard';
import Login from './auth/login';
import { Route, Routes } from 'react-router-dom';
import Consumable from './consumable';
import Standerd from './standerd';
import Userdetails from './userdetails';
import Adminuser from './adminuserdetails';
import Employersdetails from './employersdetails';
import Changepass from './changepass';
import Addcatmodal from './components/catmodal/addcatmodal';
import Stock from './stock'
import Paymenthistory from './payment-history'
import Myorder from './myorder'
import Support from './support';
import EmployerDashboard from './EmployerLogin/EmployerDashboard'
import OrderTracking from './OrderTracking'
import { useNavigate } from "react-router-dom";
import Employeereport from './employeereport';
import Jobreport from './jobreport';
import QuataionJob from './QuataionJob';
import Consumablebunow from './consumablebunow';
import CustomerOrder from './CustomerOrder';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/')
    }
  }, []);
  return (
    <Routes>
      <Route path='/' element={<Login />} />
      <Route path='/login' element={<Login />} />
      <Route path='/Dashboard' element={<Dashboard />} />
      <Route path='/consumable' element={<Consumable />} />
      <Route path='/standerd' element={<Standerd />} />
      <Route path='/userdetails' element={<Userdetails />} />
      <Route path='/adminuserdetails' element={<Adminuser />} />
      <Route path='/employersdetails' element={<Employersdetails />} />
      <Route path='/jobreport' element={<Jobreport />} />
      <Route path='/employeereport' element={<Employeereport />} />
      <Route path='/changepass' element={<Changepass />} />
      <Route path='/addcat' element={<Addcatmodal />} />
      <Route path='/Stock' element={<Stock />} />
      <Route path='/payment-history' element={<Paymenthistory />} />
      <Route path='/myorder' element={<Myorder />} />
      <Route path='/support' element={<Support />} />
      <Route path='/employdashbord' element={<EmployerDashboard />} />
      {/* <Route path='/OrderTracking' element={<OrderTracking />} /> */}
      <Route path='/QuataionJob' element={<QuataionJob />} />
      <Route path='/Consumablebunow' element={<Consumablebunow />} />
      <Route path='/CustomerOrder' element={<CustomerOrder />} />
      
    </Routes>
  );
}

export default App;
