import React, { useState, useEffect } from 'react';
import './css/Invoice.css';
import axios from 'axios';
import lib from '../config/lib';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import Loading from '../common/Loading';


const InvoicePayment = ({ jobno, jobform }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleOrder();
  }, []);

  const handleOrder = async () => {

    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append('apikey', token);
    formData.append('jobno', jobno);
    setLoading(true);

    try {
      const response = await axios.post(lib.apiURL + '/' + lib.apis.stockdisplayemploy, formData);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      // Handle error if the request failed
      console.log("Some error has occoured kindly check the parameters and try again");
      setLoading(false);
    }
  }



  const calculateTotal = () => {
    if (!data) {
      return 0;
    }

    return data.reduce((total, item) => total + item.quantity * item.rate, 0);
  }

  return (
    <div className="invoice-container">
      <Loading loading={loading} />
      <div className="content">
        <table>
          <thead>
            <tr>
              <th>Item</th>
              <th>Quantity</th>
              <th>Price</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {data && data.map((item, index) => (
              <tr key={index}>
                <td>{item.item}</td>
                <td>{item.quantity}</td>
                <td>₹{item.rate}</td>
                <td>₹{item.quantity * item.rate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="footer">
        <p className="total">Total: ₹{calculateTotal()}</p>
      </div>
      <div className='p-2'>
        {/* <Button variant='success' onClick={handleSubmitdata}>Submit Data</Button> */}
      </div>
    </div>
  );
};

export default InvoicePayment;

