import { React, useState, useEffect } from 'react';
import clasess from './common/css/Content.module.css';
import Header from './common/header';
import Table from 'react-bootstrap/Table';
import Sidemenu from './common/sidemenu';
import Button from 'react-bootstrap/Button';
import ReactPaginate from 'react-paginate';
import axios from 'axios';
import lib from './config/lib';
import Swal from 'sweetalert2';
import Loading from './common/Loading';

const Employeereport = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10;
    const [data, setData] = useState([]);
    const [filter, setFilter] = useState('');
    const [loading, setLoading] = useState(false);
    const [empid, setEmpid] = useState('');

    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [job, setJob] = useState('');
    const [timetaken, setTimetaken] = useState('');

    // Calculate the total number of pages
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData([]);

    const handleSearchClick = () => {
        handleDataFeth();
        handleTableFeth();
    }

    const handleDataFeth = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('employid', empid);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.employreport, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            setName(response.data.employname);
            setRole(response.data.role);
            setJob(response.data.job);
            setTimetaken(response.data.timetake);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleTableFeth = async () => {
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('employid', empid);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.employreporttable, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    useEffect(() => {
        handleOrder();
    }, []);

    const [empdetails, setempdetails] = useState([]);

    const handleOrder = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.employeruser, formData);
            setempdetails(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div>
            <div className={clasess.container1}>
                <Header />
                <Sidemenu />
                <div className={clasess.container}>
                    <Loading loading={loading} />
                    <div className='row mt-3'>
                        <div className='col-lg-10 col-md-9 ms-2'>
                            <h3>Employee Report</h3>
                        </div>
                    </div>

                    <hr />
                    <div className='row justify-content-center'>
                        <div className='col-lg-6'>
                            <div className="col-lg-6 col-md-10 d-flex">
                                {/* <input type="text" class="form-control" placeholder="Search by customer id..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setEmpid(e.target.value)} /> */}
                                <select class="form-select" aria-label="Default select example" onChange={(e) => setEmpid(e.target.value)} >
                                    <option selected>Select Role</option>
                                    {empdetails && empdetails.map((item) => (
                                        <option value={item.id}>{item.name}</option>
                                    ))}
                                </select>
                                <Button variant="success" style={{ margin: '10px' }} onClick={handleSearchClick} >Search</Button>
                            </div>
                            <div className='col-lg-12 bg-custom rounded'>
                                <div className='d-flex align-items-center'><h6>Employ Name</h6> <span className='mt-4 ms-3'>{name}</span></div>
                                <div className='d-flex align-items-center'><h6>Employ Role</h6> <span className='mt-4 ms-3'>{role}</span></div>
                                <div className='d-flex align-items-center'><h6>Total Job</h6> <span className='mt-4 ms-3'><a href='#' target='blank'>{job}</a></span></div>
                                <div className='d-flex align-items-center mb-3'><h6>Total Time</h6> <span className='mt-4 ms-3'>{timetaken}</span></div>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-12 mt-4'>
                        <Table striped bordered hover >
                            <thead>
                                <tr>
                                    <th>Job No</th>
                                    <th>Date</th>
                                    <th>Time Taken</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentPageData && currentPageData.map((item) => (
                                    <tr>
                                        <td>{item.jobnumber}</td>
                                        <td>{item.postdate}</td>
                                        <td>{item.timetaken}</td>
                                        <td>{item.status}</td>
                                    </tr>
                                ))}
                            </tbody>

                        </Table>
                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                        />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Employeereport;