import React from 'react';
import { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/modle.css';
import axios from 'axios';
import lib from '../../config/lib';
import Swal from 'sweetalert2';
import Loading from '../../common/Loading';

const AddtionalDetailMessage = ({ isOpen, closeModal, handleAdationaljobno, jobform }) => {
    const [selectedFile, setSelectedFile] = useState('');
    const [description, setDescription] = useState('');
    const [jobname, setJobname] = useState('');
    const [loading, setLoading] = useState(false);
    const [jpgimg, setJpgImg] = useState('');

    const handleSavedata = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('addfile', selectedFile);
        formData.append('jobno', handleAdationaljobno);
        formData.append('discription', description);
        formData.append('jobform', jobform);
        formData.append('jobname', jobname);
        formData.append('jpgimg', jpgimg);
        
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.uploaddesigner, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Designe Uploaded!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Something Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>

            <div className="modal-content ">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4> Final design</h4>
                <hr />

                <div id="form-file-upload" className="beautiful-form" style={{ height: 50 }}>
                    <div className="upload-container border">
                        <div className='d-flex'><label>Designer Image</label></div>
                        <input type="file" id="" multiple={false} onChange={(e) => setSelectedFile(e.target.files[0])} />
                    </div>
                </div>

                <div id="form-file-upload" className="beautiful-form" style={{ height: 50 }}>
                    <div className="upload-container border">
                        <div className='d-flex'><label>JPG | PNG Image for Customer</label></div>
                        <input type="file" id="" multiple={false} onChange={(e) => setJpgImg(e.target.files[0])} />
                    </div>
                </div>

                <div className="row">
                    <div className='col-md-12 mt-2'>
                        <div className='d-flex'><label>Job name</label></div>
                        <input type="text" id="input-name" placeholder="Enter job name" className='mt-2' onChange={(e) => setJobname(e.target.value)} />
                    </div>
                </div>
                <label>Description</label>
                <textarea placeholder="Type message..." rows="10" onChange={(e) => setDescription(e.target.value)}></textarea>
                {/* <div class="">
                    <label>Content</label>
                    <input type="text" class="form-control" placeholder="Add placeholder" aria-label="Username" aria-describedby="addon-wrapping" onChange={(e) => setContent(e.target.value)} />
                </div> */}
                <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleSavedata} >Save</button></div>
            </div>
        </div>
    );
};

export default AddtionalDetailMessage;
