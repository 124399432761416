import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import '../css/modle.css';
import lib from '../../config/lib';
import axios from 'axios';
import Swal from 'sweetalert2';
import { FaPlus, FaMinus } from "react-icons/fa6";
import Loading from '../../common/Loading'

const RateView = ({ isOpen, closeModal, companynameinsert, handleRate }) => {
    const [item, setItem] = useState([]);
    const [quality, setQuality] = useState([]);
    const [umo, setUmo] = useState([]);
    const [companyname, setCompanyName] = useState([]);
    const [loading, setLoading] = useState(false);
    // const [companynameinsert, setCompanynameinsert] = useState('');
    const [iteminsert, setIteminsert] = useState('');
    const [qualityinsert, setQualityinsert] = useState('');
    const [umoinsert, setUmoinsert] = useState('');
    const [rate, setRate] = useState('');

    const handleSubmit = async () => {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        formData.append("companyname", companynameinsert);
        formData.append("item", iteminsert);
        formData.append("quality", qualityinsert);
        formData.append("umo", umoinsert);
        formData.append("rate", rate);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.createrate, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Stock Added Successfully!', 'success');
                handleRate();
                closeModal();
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Something Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    useEffect(() => {
        handleitem();
        handlequality();
        handleUmo();
        handleCompanyName();
    }, []);

    const handleitem = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.fethitem, formData);
            setItem(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handlequality = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.displayquality, formData);
            setQuality(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleUmo = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'All');

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.fethumo, formData);
            setUmo(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleCompanyName = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.userfethdata, formData);
            setCompanyName(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Add Rate</h4>
                <hr />

                <div className='row'>
                    {/* {inputFields.map((field, index) => ( */}
                    {/* key={index} */}
                    {/* <div className='col-md-2'>
                            <label className='mb-1'>Company</label>
                            <Form.Select aria-label="Default select company" onChange={(e) => setCompanynameinsert(e.target.value)}>
                                {companyname && companyname.map((item) => (
                                    <option value={item.name}>{item.name}</option>
                                ))}
                            </Form.Select>
                        </div> */}
                    {/* <div className='col-md-2'>
                            <label className='mb-1'>Vendor</label>
                            <Form.Select aria-label="Default select example" onChange={(e) => setVenderselect(e.target.value)}>
                                {vender && vender.map((item) => (
                                    <option value={item.name}>{item.name}</option>
                                ))}
                            </Form.Select>
                        </div> */}
                    <div className='col-md-12'>
                        <label>Item</label>
                        <Form.Select aria-label="Default select example" onChange={(e) => setIteminsert(e.target.value)}>
                            <option selected disabled>Select Item</option>
                            {item && item.map((item) => (
                                <option value={item.item}>{item.item}</option>
                            ))}
                        </Form.Select>
                    </div>

                    <div className='col-md-12 mt-3'>
                        <label >Quality</label>
                        <Form.Select aria-label="Default select example" onChange={(e) => setQualityinsert(e.target.value)} >
                            <option selected disabled>Select Quality</option>
                            {quality && quality.map((item) => (
                                <option value={item.quality}>{item.quality}</option>
                            ))}
                        </Form.Select>
                    </div>

                    <div className='col-md-12 mt-3'>
                        <label>Uom</label>
                        <Form.Select aria-label="Default select example" onChange={(e) => setUmoinsert(e.target.value)}>
                            <option selected disabled>Select Umo</option>
                            {umo && umo.map((item) => (
                                <option value={item.umo}>{item.umo}</option>
                            ))}
                        </Form.Select>
                    </div>
                    <div className='col-md-12 mt-3'>
                        <label>Rate</label>
                        <input type="text" className="form-control" placeholder="Enter rate" onChange={(e) => setRate(e.target.value)} />
                    </div>
                </div>
                <div className='mt-3'><button type="button" class="btn btn-primary" value='Best' onClick={handleSubmit} >Save</button></div>
            </div>
        </div>
    );
};

export default RateView;
