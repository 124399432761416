import React, { useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Component, useState } from "react";
import ReactPaginate from "react-paginate";
import Swal from 'sweetalert2'
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { RiDeleteBin4Fill } from "react-icons/ri"
import Button from 'react-bootstrap/Button';
import { FcCancel, FcCheckmark } from 'react-icons/fc'
import lib from "../../config/lib";
import { BiSolidEdit } from "react-icons/bi";
import Modal from '../userModal/viewUserMessage';
import { useDispatch } from 'react-redux';
import { add, remove } from '../../store/Cartslice';
import { IoKeyOutline } from 'react-icons/io5';
import Loading from "../../common/Loading";

const UserListing = () => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 5;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleOrder();
    }, []);

    const handleOrder = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.userdetails, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    // Calculate the total number of pages
    // const pageCount = Math.ceil(data.length / itemsPerPage);
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData();

    const handleApprove = async (id) => {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        formData.append("id", id);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.userapprove, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'User Approved Successfully!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const handleReject = async (id) => {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        formData.append("id", id);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.userreject, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'User Rejected Successfully!', 'success');
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleDelete = async (id) => {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        formData.append("id", id);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.userdelete, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'User Rejected Successfully!', 'success');
                setLoading(false);
            }
            else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (id) => {
        dispatch(add(id));
        setIsModalOpen(true);
    };

    const closeModal = (id) => {
        dispatch(remove(id));
        setIsModalOpen(false);
    };


    const [type, setType] = useState('');

    const handleSearch = async () => {
        const token = localStorage.getItem('token');

        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('type', 'userlist');
        formData.append('search', type);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.searchonclick, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div>
            <div id="theme-default" className="full_block">
                <Loading loading={loading} />
                <div className="col-lg-3 d-flex">
                    <input type="text" class="form-control" placeholder="Type to search..." aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setType(e.target.value)} />
                    <Button variant="success" style={{ margin: '10px' }} onClick={handleSearch} >Search</Button>
                </div>
                <div class="widget_wrap">

                    <div class="widget_content">
                        <OverlayScrollbarsComponent>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Customer Id</th>
                                        <th>Customer Name</th>
                                        <th>Owner Mobile Number</th>
                                        <th>Email Id</th>
                                        <th>Company Name</th>
                                        <th>Role</th>
                                        <th>GST Number</th>
                                        <th>Address</th>
                                        <th>Customer Code</th>
                                        <th>Designer Number</th>
                                        <th>Market Person Number</th>
                                        <th>WHattsApp Group Name</th>
                                        <th>Breakup Hide</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentPageData && currentPageData.map((data) => (
                                        <tr key={data.id}>
                                            <td>{data.id}</td>
                                            <td>{data.name}</td>
                                            <td>{data.mobile}</td>
                                            <td>{data.email}</td>
                                            <td>{data.cname}</td>
                                            <td>{data.role}</td>
                                            <td>{data.gstno}</td>
                                            <td>{data.address}</td>
                                            <td>{data.customercode}</td>
                                            <td>{data.designernumber}</td>
                                            <td>{data.marketpersonnumber}</td>
                                            <td>{data.groupname}</td>
                                            <th>{data.hideshow}</th>
                                            <td className="center d-flex">
                                                <FcCheckmark size={20} style={{ cursor: 'pointer' }} onClick={() => handleApprove(data.id)} />
                                                <span className='ms-2'> <FcCancel size={20} style={{ cursor: 'pointer' }} onClick={() => handleReject(data.id)} /></span>
                                                <span className="ms-2">
                                                    <BiSolidEdit size={20} variant="success" onClick={() => openModal(data.id)} style={{ cursor: 'pointer' }} />
                                                    <Modal isOpen={isModalOpen} closeModal={() => closeModal(data.id)} />
                                                </span>
                                                <span className='ms-2'><RiDeleteBin4Fill size={20} style={{ cursor: 'pointer' }} onClick={() => handleDelete(data.id)} /></span>
                                                {/* <span className='ms-2'>
                                                <IoKeyOutline size={20} variant="success" onClick={() => openModal(data.id)} />
                                                <Modal isOpen={isModalOpen} closeModal={closeModal} />
                                            </span> */}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </OverlayScrollbarsComponent>
                        <hr />

                        <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                        />

                    </div>
                </div>
            </div>
        </div>
    )
}
export default UserListing;