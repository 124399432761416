import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import '../css/modle.css';
import lib from '../../config/lib';
import axios from 'axios';
import Swal from 'sweetalert2';
import Loading from '../../common/Loading';

const EditStockView = ({ isOpen, closeModal, handleid }) => {
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [gstno, setGstno] = useState('');
    const [address, setAddress] = useState('');
    const [loading, setLoading] = useState(false);

    const handleVenderDetails = async () => {
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        formData.append("name", name);
        formData.append("number", number);
        formData.append("email", email);
        formData.append("gstno", gstno);
        formData.append("address", address);
        formData.append("id", handleid);
        setLoading(true);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.editvender, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Vendor Updated Successfully!', 'success');
                setLoading(false);
            } else {
                Swal.fire({
                    title: 'opps......',
                    text: response.data.msg,
                    icon: 'warning',
                    showCancelButton: false,
                    confirmButtonText: 'Confirm',
                });
                setLoading(false);
            }

        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    useEffect(() => {
        handleVenderDetailsEdit();
    }, []);

    const handleVenderDetailsEdit = async () => {
        setLoading(true);
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("apikey", token);
        formData.append("id", handleid);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.fethvendordata, formData);
            
                setName(response.data.name);
                setNumber(response.data.number);
                setEmail(response.data.email);
                setGstno(response.data.gstno);
                setAddress(response.data.address);
                setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>
            <div className="modal-content">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Edit Vendor</h4>
                <hr />
                <div class="input-group flex-nowrap">
                    <label>Name</label>
                    <input type="text" class="form-control" placeholder="Enter vendor name" aria-label="Username" aria-describedby="addon-wrapping" value={name} onChange={(e) => setName(e.target.value)} />
                </div>
                <div class="input-group flex-nowrap mt-3">
                    <label>Number</label>
                    <input type="text" class="form-control" placeholder="Enter vendor number" aria-label="Username" aria-describedby="addon-wrapping" value={number} onChange={(e) => setNumber(e.target.value)} />
                </div>
                <div class="input-group flex-nowrap mt-3">
                    <label>Email</label>
                    <input type="text" class="form-control" placeholder="Enter vendor email" aria-label="Username" aria-describedby="addon-wrapping" value={email} onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div class="input-group flex-nowrap mt-3">
                    <label>GST No.</label>
                    <input type="text" class="form-control" placeholder="Enter gst number" aria-label="Username" aria-describedby="addon-wrapping" value={gstno} onChange={(e) => setGstno(e.target.value)} />
                </div>
                <div class="input-group flex-nowrap mt-3">
                    <label> Address</label>
                    <input type="text" class="form-control" placeholder="Enter adrress" aria-label="Username" aria-describedby="addon-wrapping" value={address} onChange={(e) => setAddress(e.target.value)} />
                </div>
                <div className='mt-3'><button type="button" class="btn btn-primary" onClick={handleVenderDetails}>Create </button> </div>
                <button onClick={handleVenderDetailsEdit}>Start</button>
            </div>
        </div>
    );
};

export default EditStockView;
