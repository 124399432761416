import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assest/css/custom.css';
import './assest/css/gradient.css';
import Header from './common/header';
import Sidemenu from './common/sidemenu';
import clasess from './common/css/Content.module.css';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import lib from './config/lib';
import Swal from 'sweetalert2'
import axios from 'axios';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import ReactPaginate from 'react-paginate';
import { RiDeleteBin4Fill } from "react-icons/ri"
import Loading from './common/Loading';

const Consumable = () => {

    const [selectedFile, setSelectedFile] = useState(null);
    const [productname, setProductName] = useState('');
    const [productdescription, setProductdescription] = useState('');
    const [productprise, setProductPrise] = useState('');
    const [cardimg, setCardImg] = useState({ preview: '', data: '' });
    const [filename, setFilename] = useState('');
    const [loading, setLoading] = useState(false);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFilename(e.target.files[0]);
        setCardImg({ preview: URL.createObjectURL(file), data: file });
        if (file) {
            setSelectedFile(file);
        } else {
            setSelectedFile(null);
        }
    };

    const handleCardSave = async (event) => {
        event.preventDefault();
        setLoading(true);
        const token = localStorage.getItem("token");
        const formData = new FormData();
        formData.append("productname", productname);
        formData.append("productprise", productprise);
        formData.append("productdescription", productdescription);
        formData.append("addfile", filename);
        formData.append("apikey", token);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.consumable, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'User Registered successfully successfully!', 'success');
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Somthing Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    const [currentPage, setCurrentPage] = useState([]);
    const itemsPerPage = 10;
    const [data, setData] = useState([]);
    // Calculate the total number of pages
    // const pageCount = Math.ceil(data.length / itemsPerPage);
    const pageCount = data ? Math.ceil(data.length / itemsPerPage) : 0;

    // Function to handle page change
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };

    // Function to get the current page's data
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return data ? data.slice(startIndex, endIndex) : [];
    };

    const currentPageData = getCurrentPageData([]);

    useEffect(() => {
        handleSample();
    }, []);

    const handleSample = async () => {

        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        setLoading(true);
        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.fethconsumbaletable, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }


    const handleDelete = async (id) => {
        const token = localStorage.getItem('token');
        setLoading(true);
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('id', id);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.deleteconsumabledetails, formData);
            if (response.data.status === "success") {
                Swal.fire('Success', 'Deleted Successfully!', 'success');
                handleSample();
                setLoading(false);
            }
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            Swal.fire({
                title: 'opps......',
                text: 'Something Went Wrong',
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Confirm',
            });
            setLoading(false);
        }
    }

    return (
        <div className={clasess.container1}>
            <Header />
            <Sidemenu />
            <div>
                <div className={clasess.container}>
                    <div style={{ width: '100%', height: 'auto', marginTop: '20px' }}>
                        <Loading loading={loading} />
                        <h3 style={{ marginLeft: '15px' }}>Upload Consumable</h3>
                        <hr />
                        <Tabs
                            defaultActiveKey="Consumable"
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3"
                            style={{ fontSize: '20px' }}
                        >
                            <Tab eventKey="Consumable" title="Consumable">
                                <div class="grid_container">
                                    <div class="grid_9">
                                        <center>
                                            <form id="form-file-upload" className="beautiful-form">
                                                <div className="upload-container">
                                                    <label id="label-file-upload" htmlFor="input-file-upload" className="upload-label">
                                                        <div className="drag-drop-box">
                                                            <p>
                                                                {selectedFile
                                                                    ? `File Uploaded: ${selectedFile.name}`
                                                                    : 'Drag and drop your file here or'}
                                                            </p>
                                                        </div>
                                                    </label>
                                                    <input type="file" id="input-file-upload" multiple={false} onChange={handleFileChange} className="file-input" />
                                                    <input type="text" id="input-name" placeholder="Product Name" onChange={(e) => setProductName(e.target.value)} />
                                                    <input type="text" id="input-email" placeholder="Product Prise" onChange={(e) => setProductPrise(e.target.value)} />
                                                    <textarea id="message-box" placeholder="Product Description" onChange={(e) => setProductdescription(e.target.value)}></textarea>
                                                    <input type="submit" value="Upload Product" className="upload-button" onClick={handleCardSave} />
                                                </div>
                                            </form>
                                        </center>
                                    </div>
                                </div>
                                <div style={{ float: 'left' }}>
                                    <center>
                                        <Card style={{ width: '18rem', height: '22rem', border: '1px solid' }}>
                                            <Card.Img variant="top" src={cardimg.preview} style={{ width: '18rem', height: '12rem' }} />
                                            <Card.Body>
                                                <Card.Title>{productname}</Card.Title>
                                                <Card.Text>
                                                    {productdescription}
                                                </Card.Text>
                                                <Card.Text>
                                                    Rs {productprise} /-
                                                </Card.Text>
                                                <Button variant="primary">Buy Now</Button>
                                            </Card.Body>
                                        </Card>
                                    </center>
                                </div>
                            </Tab>
                            <Tab eventKey="Consumable details" title="Consumable details">
                                <Table striped bordered hover >
                                    <thead>
                                        <tr>
                                            <th>Product Name</th>
                                            <th>Product Prise</th>
                                            <th>Product Description</th>
                                            <th>View Image</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentPageData && currentPageData.map((item) => (
                                            <tr>
                                                <td>{item.productname}</td>
                                                <td>{item.productprise}</td>
                                                <td>{item.productdescription}</td>
                                                <td><a href={lib.imgUrl + '/' + item.productImg} target="_blank" >View Image</a></td>
                                                <td><RiDeleteBin4Fill size={24} onClick={() => handleDelete(item.id)} /></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <ReactPaginate
                                    previousLabel={"Previous"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    pageCount={pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={handlePageChange}
                                    containerClassName={"pagination"}
                                    subContainerClassName={"pages pagination"}
                                    activeClassName={"active"}
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Consumable;