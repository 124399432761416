import React, { useState } from 'react';
import Modal from './Editvender';
import '../css/modle.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BiSolidEdit } from 'react-icons/bi'


function Addquantity({ handleid }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="App">
            <BiSolidEdit size={22} onClick={openModal} />
            <Modal isOpen={isModalOpen} closeModal={closeModal} handleid={handleid} />
        </div>
    );
}

export default Addquantity;
