import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/modle.css';
import axios from 'axios';
import lib from '../../config/lib';
import Swal from 'sweetalert2';
import Loading from '../../common/Loading';
import Card from 'react-bootstrap/Card';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const DespatchMessage = ({ isOpen, closeModal, handleMarket }) => {
    const [selectedFilemarket, setSelectedFilemarket] = useState(null);
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);

    const [companyname, setCompanyName] = useState('');
    const [data, setData] = useState([]);

    const [order, setorder] = useState('');
    const [quation, setquation] = useState('');
    const [keyline, setkeyline] = useState('');
    const [sample, setsample] = useState('');
    const [consumable, setconsumable] = useState('');
    const [rulechange, setrulechange] = useState('');
    const [standerd, setstanderd] = useState('');

    const [type, setType] = useState('');

    useEffect(() => {
        handleCompanyName();
    }, []);

    const handleCompanyName = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.userfethdata, formData);
            setData(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const [addressdata, setAddressdata] = useState([]);

    const handleUseraddress = async (e) => {
        setLoading(true);
        var cname = e.target.value;
        setCompanyName(e.target.value)
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('cname', cname);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.caddress, formData);
            setAddressdata(response.data);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }

    const handleOrderfeth = async () => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('apikey', token);
        formData.append('cname', companyname);
        formData.append('type', type);

        try {
            const response = await axios.post(lib.apiURL + '/' + lib.apis.orderstatusdispach, formData);
            setorder(response.data.order);
            setquation(response.data.quation);
            setkeyline(response.data.keyline);
            setsample(response.data.sample);
            setconsumable(response.data.consumable);
            setrulechange(response.data.rulechange);
            setstanderd(response.data.standerd);
            setLoading(false);
        } catch (error) {
            // Handle error if the request failed
            console.log("Some error has occoured kindly check the parameters and try again");
            setLoading(false);
        }
    }
    return (
        <div className={`modal ${isOpen ? 'open' : ''}`}>

            <div className="modal-contente ">
                <Loading loading={loading} />
                <span className="close" onClick={closeModal}>&times;</span>
                <h4>Despatch Details</h4>
                <hr />

                <div className="row align-items-center mb-3 ms-1">

                    <div className="col-lg-2 col-md-2">
                        <div className="me-2">
                            <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={handleUseraddress}>
                                <option selected disabled>Select User</option>
                                {data && data.map((item) => (
                                    <option value={item.cname}>{item.cname}</option>
                                ))}
                            </Form.Select>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2">
                        <div className="me-2">
                            {addressdata && addressdata.map((item, index) => (
                                <span>
                                    {item.address}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-2">
                        <div className="me-2">
                            <Form.Select aria-label="Default select example" style={{ marginTop: '10px' }} onChange={(e) => setType(e.target.value)}>
                                <option selected disabled>Select Type</option>
                                <option value="pending">Pending</option>
                                <option value="inprocess">In Process</option>
                            </Form.Select>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-8 d-flex">
                        <Button variant="success" style={{ margin: '10px' }} onClick={handleOrderfeth} >Search</Button>
                    </div>
                </div>
                <div className='col-lg-2 col-md-6 col-sm-6'>
                    <Card border="primary">
                        <Card.Header><h3>Standerd</h3></Card.Header>
                        <Card.Body>
                            <Card.Title>Total</Card.Title>
                            <Card.Text>
                                {standerd}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-lg-2 col-md-6 col-sm-6'>
                    <Card border="danger">
                        <Card.Header><h3>New Order</h3></Card.Header>
                        <Card.Body>
                            <Card.Title>Total</Card.Title>
                            <Card.Text>
                                {order}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-lg-2 col-md-6 col-sm-6'>
                    <Card border="success">
                        <Card.Header><h3>Quation</h3></Card.Header>
                        <Card.Body>
                            <Card.Title>Total</Card.Title>
                            <Card.Text>
                                {quation}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-lg-2 col-md-6 col-sm-6'>
                    <Card border="info">
                        <Card.Header><h3>Rule Change</h3></Card.Header>
                        <Card.Body>
                            <Card.Title>Total</Card.Title>
                            <Card.Text>
                                {rulechange}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-lg-2 col-md-6 col-sm-6'>
                    <Card border="success">
                        <Card.Header><h3>Key Line</h3></Card.Header>
                        <Card.Body>
                            <Card.Title>Total</Card.Title>
                            <Card.Text>
                                {keyline}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
                <div className='col-lg-2 col-md-6 col-sm-6'>
                    <Card border="primary">
                        <Card.Header><h3>Sample</h3></Card.Header>
                        <Card.Body>
                            <Card.Title>Total</Card.Title>
                            <Card.Text>
                                {sample}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            </div >
        </div >
    );
};

export default DespatchMessage;    
